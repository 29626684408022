import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Translate } from '../../utils/Translate';
import { classNames } from '../../utils/classNames';
import { ErrorDisplay } from './ErrorDisplay';

export type TabsDefinition = {
    [key: string]: {
        title: string | JSX.Element;
        content: JSX.Element;
    };
};

interface ITabsProps {
    tabs: TabsDefinition;
    default: string;
}

export function Tabs(props: ITabsProps) {
    const location = window.location.pathname;
    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.substring(0, url.length - 1) : url;
    const history = useHistory();
    const tabName = location.replace(url, '').replace('/', '');
    if (tabName === '') {
        history.replace(url + '/' + props.default);
    }

    return (
        <div className="tabs">
            <ul className="nav nav-tabs">
                {Object.entries(props.tabs).map(([key, tab], i) => (
                    <li className="nav-item" key={i}>
                        <Link className={classNames('nav-link', key === tabName && 'active')} to={url + '/' + key}>
                            {tab.title}
                        </Link>
                    </li>
                ))}
            </ul>
            <div className="block">
                {props.tabs[tabName] ? (
                    <>
                        <h2 className="mb-3">{props.tabs[tabName].title}</h2>
                        {props.tabs[tabName].content}
                    </>
                ) : (
                    <ErrorDisplay error={Translate.message('error.tabDoesNotExist', 'Uvedená záložka neexistuje')} />
                )}
            </div>
        </div>
    );
}
