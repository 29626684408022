import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../api/Api';
import { Member } from '../../api/entities/Member';
import { AppState } from '../../AppState';
import { classNames } from '../../utils/classNames';
import { gqlErrorToString } from '../../utils/gqlErrorToString';
import { MemberHead } from './MemberHead';

interface IProfilePreviewProps {
    appState: AppState;
    id: number;
    className?: string;
    onClick?: () => void;
}

export function ProfilePreview(props: IProfilePreviewProps) {
    const { loading, error, data } = useQuery(Api.queries.member.basic, {
        variables: {
            asId: props.appState.activeMember.id,
            id: props.id,
        },
    });

    const onClick = () => {
        if (props.onClick) props.onClick();
    };

    if (loading)
        return (
            <div className={classNames('profile-preview', props.className)} onClick={onClick}>
                {props.id}
            </div>
        );
    if (error)
        return (
            <div className={classNames('profile-preview', props.className)} onClick={onClick}>
                {props.id} - {gqlErrorToString(error)}
            </div>
        );

    const member = data.auth.as.memberById as Member;

    return (
        <div className={classNames('profile-preview', props.className)} onClick={onClick}>
            <MemberHead appState={props.appState} member={member} className="my-2 me-3" noLink size={40} />
            {member.firstname} "{member.nickname}" {member.lastname}
        </div>
    );
}
