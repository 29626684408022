import { TwixFormatOptions } from 'twix';

export const TWIX_FORMAT: TwixFormatOptions = { monthFormat: 'MMMM', showDayOfWeek: true, dayFormat: 'D.' };

export const TWIX_FORMAT_SHORT: TwixFormatOptions = {
    hideDate: true,
};

export const TWIX_FORMAT_DAYTIME: TwixFormatOptions = {
    weekdayFormat: 'dddd',
    monthFormat: '',
    dayFormat: '',
    showDate: false,
    showDayOfWeek: true,
};
