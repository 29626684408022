import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { IStepProps } from '../Apply';

export function StepLector(props: IStepProps) {
    return (
        <Form
            defaultValues={{
                lector: false,
                motd: '',
                lectorNote: '',
            }}
            onSubmit={(values) => props.next({ lectorNote: '', motd: '', lector: false, ...values })}
            validators={(data) => ({
                lector: [],
                motd: [],
                lectorNote: [],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">Budeš na bráně lektorovat?</h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            Lektorování je skvělá činnost a navíc díky tomu získáš{' '}
                            <strong>slevu z účastnického poplatku</strong>. Tak neváhej a pojď do toho! Pokud ještě
                            nevíš, můžeš se vždycky přihlásit k lektorování ještě po registraci.
                        </p>
                        <FormInput {...inputProps('lector')} type="checkbox" label={'Chci lektorovat'} />

                        {state['lector']?.value && (
                            <>
                                <div className="mt-5">
                                    <div style={{ marginBottom: -12 }}>
                                        Napiš pár řádků o sobě (co děláš, čím se zabýváš, jak dlouho se oboru věnuješ,
                                        jakou máš praxi, atd.).{' '}
                                        <strong>Anotace lektora se objeví u všech tvých programů.</strong> Nepiš o
                                        programu, ale obecně o sobě, ať účastnící ví s kým budou mít tu čest.
                                    </div>
                                    <FormInput {...inputProps('motd')} type="textarea" label={'Medailonek o mně'} />
                                </div>

                                <div className="mt-5">
                                    <div style={{ marginBottom: -12 }}>
                                        Napiš nám prosím pár údajů o sobě jako o lektorovi. Na kterých akcích jsi již
                                        lektoroval, jaké programy. Máš nějaké doporučení či reference?{' '}
                                        <strong>Tyto informace jsou jen pro potřebu organizátorů</strong>, nebudou se
                                        nikde zobrazovat.
                                    </div>
                                    <FormInput
                                        {...inputProps('lectorNote')}
                                        type="textarea"
                                        label={'Informace pro organizátory'}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
