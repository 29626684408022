import * as React from 'react';
import { classNames } from '../../utils/classNames';

export type AccordionDefinition = {
    [key: string]: {
        title: string;
        content: JSX.Element;
    };
};

interface IAccordionProps {
    tabs: AccordionDefinition;
    default?: string;
}

export function Accordion(props: IAccordionProps) {
    const [active, setActive] = React.useState<string | null>(props.default || null);

    return (
        <div className="accordion" id="accordionExample">
            {Object.entries(props.tabs).map(([key, tab], i) => (
                <div className="accordion-item" key={i}>
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={classNames('accordion-button', active !== key && 'collapsed')}
                            type="button"
                            onClick={() => (active === key ? setActive(null) : setActive(key))}
                        >
                            {tab.title}
                        </button>
                    </h2>
                    <div id="collapseOne" className={classNames('accordion-collapse', active !== key && 'collapse')}>
                        <div className="accordion-body">{tab.content}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}
