import moment from 'moment';
import { AppState } from '../../../AppState';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { ArticlesListView } from '../../utils/dataDisplayComponents/articles/ArticlesListView';
import { EventsListView } from '../../utils/dataDisplayComponents/events/EventsListView';

interface IHomePageProps {
    appState: AppState;
}

export function HomePage(props: IHomePageProps) {
    return (
        <>
            <div className="col-12 col-lg-7">
                <h1>
                    {Translate.message('home.title', 'Ahoj {{nickname}}!', {
                        nickname: props.appState.activeMember.nickname,
                    })}
                </h1>
                <h6>
                    {Translate.message('home.subtitle', 'Koukni se, co je tu nového a co tě čeká v příštích dnech.', {
                        gender: props.appState.activeMember.gender,
                    })}
                </h6>

                <h2 className="section-heading">{Translate.message('home.eventsThisWeek', 'Akce tento týden')}</h2>
                <EventsListView
                    query={Api.queries.event.list(props.appState.activeMember.id)}
                    dataResolver={(data) => data.auth.as.events}
                    appState={props.appState}
                    filter={{
                        attendeeId: props.appState.activeMember.id,
                    }}
                    error={Translate.message(
                        'home.noEventsThisWeek',
                        'Tento týden nás nečekají žádné akce. Koukněte se ale na další akce, které se plánují!',
                    )}
                />
                <h2 className="section-heading">{Translate.message('home.moreUpcoming', 'Další plánované akce')}</h2>
                <EventsListView
                    query={Api.queries.event.list(props.appState.activeMember.id)}
                    dataResolver={(data) => data.auth.as.events}
                    appState={props.appState}
                    filter={{
                        public: true,
                    }}
                    sortByNew
                    limit={3}
                    details
                    error={Translate.message(
                        'home.noEvents',
                        'Zatím nemáme naplánované žádné další akce, ale usilovně na tom pracujeme!',
                    )}
                />
            </div>
            <div className="col-12 col-lg-5 ps-lg-5">
                <h2 className="section-heading">{Translate.message('home.articles', 'Nejčerstvější lístečky')}</h2>
                <ArticlesListView
                    query={Api.queries.article.list(props.appState.activeMember.id)}
                    dataResolver={(data) => data.auth.as.articles}
                    appState={props.appState}
                    filter={{
                        from: moment(new Date()).toISOString(),
                        to: moment(new Date()).toISOString(),
                    }}
                    error={Translate.message(
                        'home.noArticles',
                        'Aktuálně nemáme žádné důležité lístečky, ale nějaké se tady určitě brzy objeví',
                    )}
                />
            </div>
        </>
    );
}
