import { IconUser } from '@tabler/icons';
import * as React from 'react';
import { Member } from '../../api/entities/Member';
import { Translate } from '../../utils/Translate';
import { RoleIcon } from './RoleIcon';

interface IRoleIconsProps {
    member: Member;
}

export function RoleIcons(props: IRoleIconsProps) {
    return (
        <>
            {props.member.validMembership && (
                <span title={Translate.message('roleIcons.member', 'Člen')}>
                    <IconUser />
                </span>
            )}
            {props.member.organizationRoles.map((role, i) => (
                <RoleIcon role={role} key={i} />
            ))}
        </>
    );
}
