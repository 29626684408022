import {
    Icon2fa,
    Icon3dCubeSphere,
    IconAB,
    IconAccessible,
    IconAccessPoint,
    IconAccessPointOff,
    IconActivity,
    IconAd,
    IconAd2,
    IconAdjustments,
    IconAdjustmentsAlt,
    IconAdjustmentsHorizontal,
    IconAerialLift,
    IconAffiliate,
    IconAlarm,
    IconAlertCircle,
    IconAlertOctagon,
    IconAlertTriangle,
    IconAlien,
    IconAlignCenter,
    IconAlignJustified,
    IconAlignLeft,
    IconAlignRight,
    IconAmbulance,
    IconAnchor,
    IconAngle,
    IconAntennaBars1,
    IconAntennaBars2,
    IconAntennaBars3,
    IconAntennaBars4,
    IconAntennaBars5,
    IconAperture,
    IconApple,
    IconApps,
    IconArchive,
    IconArrowBack,
    IconArrowBackUp,
    IconArrowBarDown,
    IconArrowBarLeft,
    IconArrowBarRight,
    IconArrowBarToDown,
    IconArrowBarToLeft,
    IconArrowBarToRight,
    IconArrowBarToUp,
    IconArrowBarUp,
    IconArrowBigDown,
    IconArrowBigLeft,
    IconArrowBigRight,
    IconArrowBigTop,
    IconArrowBottomBar,
    IconArrowBottomCircle,
    IconArrowBottomSquare,
    IconArrowBottomTail,
    IconArrowDown,
    IconArrowDownCircle,
    IconArrowDownLeft,
    IconArrowDownLeftCircle,
    IconArrowDownRight,
    IconArrowDownRightCircle,
    IconArrowForward,
    IconArrowForwardUp,
    IconArrowLeft,
    IconArrowLeftBar,
    IconArrowLeftCircle,
    IconArrowLeftSquare,
    IconArrowLeftTail,
    IconArrowLoopLeft,
    IconArrowLoopRight,
    IconArrowNarrowDown,
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconArrowNarrowUp,
    IconArrowRampLeft,
    IconArrowRampRight,
    IconArrowRight,
    IconArrowRightBar,
    IconArrowRightCircle,
    IconArrowRightSquare,
    IconArrowRightTail,
    IconArrowsDiagonal,
    IconArrowsDiagonal2,
    IconArrowsDoubleNeSw,
    IconArrowsDoubleNwSe,
    IconArrowsDoubleSeNw,
    IconArrowsDoubleSwNe,
    IconArrowsDown,
    IconArrowsDownUp,
    IconArrowsHorizontal,
    IconArrowsJoin,
    IconArrowsJoin2,
    IconArrowsLeft,
    IconArrowsLeftDown,
    IconArrowsLeftRight,
    IconArrowsMaximize,
    IconArrowsMinimize,
    IconArrowsRight,
    IconArrowsRightDown,
    IconArrowsRightLeft,
    IconArrowsSort,
    IconArrowsSplit,
    IconArrowsSplit2,
    IconArrowsUp,
    IconArrowsUpDown,
    IconArrowsUpLeft,
    IconArrowsUpRight,
    IconArrowsVertical,
    IconArrowTopBar,
    IconArrowTopCircle,
    IconArrowTopSquare,
    IconArrowTopTail,
    IconArrowUp,
    IconArrowUpCircle,
    IconArrowUpLeft,
    IconArrowUpLeftCircle,
    IconArrowUpRight,
    IconArrowUpRightCircle,
    IconArrowWaveLeftDown,
    IconArrowWaveLeftUp,
    IconArrowWaveRightDown,
    IconArrowWaveRightUp,
    IconArtboard,
    IconAspectRatio,
    IconAt,
    IconAtom,
    IconAtom2,
    IconAward,
    IconBackhoe,
    IconBackspace,
    IconBallAmericanFootball,
    IconBallBasketball,
    IconBallBowling,
    IconBallFootball,
    IconBallFootballOff,
    IconBallTennis,
    IconBallVolleyball,
    IconBan,
    IconBandage,
    IconBarcode,
    IconBasket,
    IconBattery,
    IconBattery1,
    IconBattery2,
    IconBattery3,
    IconBattery4,
    IconBatteryAutomotive,
    IconBatteryCharging,
    IconBatteryOff,
    IconBed,
    IconBell,
    IconBellMinus,
    IconBellOff,
    IconBellPlus,
    IconBellRinging,
    IconBellRinging2,
    IconBellX,
    IconBike,
    IconBinary,
    IconBiohazard,
    IconBlockquote,
    IconBluetooth,
    IconBluetoothConnected,
    IconBluetoothOff,
    IconBold,
    IconBolt,
    IconBoltOff,
    IconBone,
    IconBook,
    IconBookmark,
    IconBookmarkOff,
    IconBookmarks,
    IconBorderAll,
    IconBorderBottom,
    IconBorderHorizontal,
    IconBorderInner,
    IconBorderLeft,
    IconBorderNone,
    IconBorderOuter,
    IconBorderRadius,
    IconBorderRight,
    IconBorderStyle,
    IconBorderStyle2,
    IconBorderTop,
    IconBorderVertical,
    IconBottle,
    IconBox,
    IconBoxMargin,
    IconBoxModel,
    IconBoxModel2,
    IconBoxMultiple,
    IconBoxMultiple0,
    IconBoxMultiple1,
    IconBoxMultiple2,
    IconBoxMultiple3,
    IconBoxMultiple4,
    IconBoxMultiple5,
    IconBoxMultiple6,
    IconBoxMultiple7,
    IconBoxMultiple8,
    IconBoxMultiple9,
    IconBoxPadding,
    IconBraces,
    IconBrackets,
    IconBrandAirbnb,
    IconBrandAndroid,
    IconBrandApple,
    IconBrandAppleArcade,
    IconBrandAppstore,
    IconBrandAsana,
    IconBrandBehance,
    IconBrandBing,
    IconBrandBitbucket,
    IconBrandBooking,
    IconBrandChrome,
    IconBrandCodepen,
    IconBrandCodesandbox,
    IconBrandCss3,
    IconBrandDeviantart,
    IconBrandDiscord,
    IconBrandDisqus,
    IconBrandDocker,
    IconBrandDribbble,
    IconBrandEdge,
    IconBrandFacebook,
    IconBrandFigma,
    IconBrandFirefox,
    IconBrandFlickr,
    IconBrandFoursquare,
    IconBrandFramer,
    IconBrandGithub,
    IconBrandGitlab,
    IconBrandGoogle,
    IconBrandGoogleAnalytics,
    IconBrandGoogleDrive,
    IconBrandGooglePlay,
    IconBrandGravatar,
    IconBrandHipchat,
    IconBrandHtml5,
    IconBrandInstagram,
    IconBrandJavascript,
    IconBrandKickstarter,
    IconBrandKotlin,
    IconBrandLinkedin,
    IconBrandMedium,
    IconBrandMessenger,
    IconBrandNetflix,
    IconBrandOpenSource,
    IconBrandOpera,
    IconBrandPagekit,
    IconBrandPatreon,
    IconBrandPaypal,
    IconBrandPinterest,
    IconBrandPocket,
    IconBrandProducthunt,
    IconBrandPython,
    IconBrandReddit,
    IconBrandSafari,
    IconBrandSass,
    IconBrandSentry,
    IconBrandShazam,
    IconBrandSketch,
    IconBrandSkype,
    IconBrandSlack,
    IconBrandSnapchat,
    IconBrandSoundcloud,
    IconBrandSpotify,
    IconBrandSteam,
    IconBrandStripe,
    IconBrandTabler,
    IconBrandTailwind,
    IconBrandTelegram,
    IconBrandTidal,
    IconBrandTiktok,
    IconBrandTinder,
    IconBrandTumblr,
    IconBrandTwitch,
    IconBrandTwitter,
    IconBrandUnsplash,
    IconBrandVercel,
    IconBrandVimeo,
    IconBrandVk,
    IconBrandWhatsapp,
    IconBrandWindows,
    IconBrandYahoo,
    IconBrandYcombinator,
    IconBrandYoutube,
    IconBriefcase,
    IconBrightness,
    IconBrightness2,
    IconBrightnessDown,
    IconBrightnessHalf,
    IconBrightnessUp,
    IconBrowser,
    IconBrush,
    IconBucket,
    IconBug,
    IconBuilding,
    IconBuildingArch,
    IconBuildingBank,
    IconBuildingBridge,
    IconBuildingBridge2,
    IconBuildingCarousel,
    IconBuildingCastle,
    IconBuildingChurch,
    IconBuildingCommunity,
    IconBuildingCottage,
    IconBuildingFactory,
    IconBuildingFortress,
    IconBuildingHospital,
    IconBuildingLighthouse,
    IconBuildingMonument,
    IconBuildingPavilon,
    IconBuildingSkyscraper,
    IconBuildingStore,
    IconBuildingWarehouse,
    IconBulb,
    IconBulbOff,
    IconBulldozer,
    IconBus,
    IconBusinessplan,
    IconCalculator,
    IconCalendar,
    IconCalendarEvent,
    IconCalendarMinus,
    IconCalendarOff,
    IconCalendarPlus,
    IconCalendarStats,
    IconCalendarTime,
    IconCamera,
    IconCameraMinus,
    IconCameraOff,
    IconCameraPlus,
    IconCameraRotate,
    IconCameraSelfie,
    IconCandy,
    IconCapture,
    IconCar,
    IconCaravan,
    IconCarCrane,
    IconCardboards,
    IconCaretDown,
    IconCaretLeft,
    IconCaretRight,
    IconCaretUp,
    IconCash,
    IconCashBanknote,
    IconCashBanknoteOff,
    IconCast,
    IconCe,
    IconCertificate,
    IconChargingPile,
    IconChartArcs,
    IconChartArcs3,
    IconChartArea,
    IconChartAreaLine,
    IconChartArrows,
    IconChartArrowsVertical,
    IconChartBar,
    IconChartBubble,
    IconChartCandle,
    IconChartCircles,
    IconChartDonut,
    IconChartDonut2,
    IconChartDonut3,
    IconChartDonut4,
    IconChartDots,
    IconChartInfographic,
    IconChartLine,
    IconChartPie,
    IconChartPie2,
    IconChartPie3,
    IconChartPie4,
    IconChartRadar,
    IconCheck,
    IconCheckbox,
    IconChecks,
    IconCheese,
    IconChevronDown,
    IconChevronDownLeft,
    IconChevronDownRight,
    IconChevronLeft,
    IconChevronRight,
    IconChevronsDown,
    IconChevronsDownLeft,
    IconChevronsDownRight,
    IconChevronsLeft,
    IconChevronsRight,
    IconChevronsUp,
    IconChevronsUpLeft,
    IconChevronsUpRight,
    IconChevronUp,
    IconChevronUpLeft,
    IconChevronUpRight,
    IconChristmasTree,
    IconCircle,
    IconCircle0,
    IconCircle1,
    IconCircle2,
    IconCircle3,
    IconCircle4,
    IconCircle5,
    IconCircle6,
    IconCircle7,
    IconCircle8,
    IconCircle9,
    IconCircleCheck,
    IconCircleDashed,
    IconCircleDotted,
    IconCircleHalf,
    IconCircleHalfVertical,
    IconCircleMinus,
    IconCircleOff,
    IconCirclePlus,
    IconCircles,
    IconCircleSquare,
    IconCircleX,
    IconClearAll,
    IconClearFormatting,
    IconClick,
    IconClipboard,
    IconClipboardCheck,
    IconClipboardList,
    IconClipboardX,
    IconClock,
    IconCloud,
    IconCloudDownload,
    IconCloudFog,
    IconCloudOff,
    IconCloudRain,
    IconCloudSnow,
    IconCloudStorm,
    IconCloudUpload,
    IconCode,
    IconCodeMinus,
    IconCodePlus,
    IconCoffee,
    IconCoin,
    IconColorPicker,
    IconColorSwatch,
    IconColumnInsertLeft,
    IconColumnInsertRight,
    IconColumns,
    IconComet,
    IconCommand,
    IconCompass,
    IconConfetti,
    IconContainer,
    IconContrast,
    IconCookie,
    IconCopy,
    IconCopyleft,
    IconCopyright,
    IconCornerDownLeft,
    IconCornerDownLeftDouble,
    IconCornerDownRight,
    IconCornerDownRightDouble,
    IconCornerLeftDown,
    IconCornerLeftDownDouble,
    IconCornerLeftUp,
    IconCornerLeftUpDouble,
    IconCornerRightDown,
    IconCornerRightDownDouble,
    IconCornerRightUp,
    IconCornerRightUpDouble,
    IconCornerUpLeft,
    IconCornerUpLeftDouble,
    IconCornerUpRight,
    IconCornerUpRightDouble,
    IconCrane,
    IconCreditCard,
    IconCreditCardOff,
    IconCrop,
    IconCrosshair,
    IconCrown,
    IconCrownOff,
    IconCup,
    IconCurlyLoop,
    IconCurrencyBahraini,
    IconCurrencyBath,
    IconCurrencyBitcoin,
    IconCurrencyCent,
    IconCurrencyDinar,
    IconCurrencyDirham,
    IconCurrencyDollar,
    IconCurrencyDollarAustralian,
    IconCurrencyDollarCanadian,
    IconCurrencyDollarSingapore,
    IconCurrencyEthereum,
    IconCurrencyEuro,
    IconCurrencyForint,
    IconCurrencyFrank,
    IconCurrencyKroneCzech,
    IconCurrencyKroneDanish,
    IconCurrencyKroneSwedish,
    IconCurrencyLeu,
    IconCurrencyLira,
    IconCurrencyLitecoin,
    IconCurrencyNaira,
    IconCurrencyPound,
    IconCurrencyReal,
    IconCurrencyRenminbi,
    IconCurrencyRipple,
    IconCurrencyRiyal,
    IconCurrencyRubel,
    IconCurrencyRupee,
    IconCurrencyShekel,
    IconCurrencyTaka,
    IconCurrencyTugrik,
    IconCurrencyWon,
    IconCurrencyYen,
    IconCurrencyZloty,
    IconCurrentLocation,
    IconCursorText,
    IconCut,
    IconDashboard,
    IconDatabase,
    IconDatabaseExport,
    IconDatabaseImport,
    IconDatabaseOff,
    IconDetails,
    IconDeviceAnalytics,
    IconDeviceAudioTape,
    IconDeviceCctv,
    IconDeviceComputerCamera,
    IconDeviceComputerCameraOff,
    IconDeviceDesktop,
    IconDeviceDesktopAnalytics,
    IconDeviceDesktopOff,
    IconDeviceFloppy,
    IconDeviceGamepad,
    IconDeviceLaptop,
    IconDeviceMobile,
    IconDeviceMobileMessage,
    IconDeviceMobileRotated,
    IconDeviceMobileVibration,
    IconDevices,
    IconDevices2,
    IconDevicesPc,
    IconDeviceSpeaker,
    IconDeviceTablet,
    IconDeviceTv,
    IconDeviceWatch,
    IconDiamond,
    IconDice,
    IconDimensions,
    IconDirection,
    IconDirectionHorizontal,
    IconDirections,
    IconDisabled,
    IconDisabled2,
    IconDisc,
    IconDiscount,
    IconDiscount2,
    IconDivide,
    IconDna,
    IconDogBowl,
    IconDots,
    IconDotsCircleHorizontal,
    IconDotsDiagonal,
    IconDotsDiagonal2,
    IconDotsVertical,
    IconDownload,
    IconDragDrop,
    IconDragDrop2,
    IconDrone,
    IconDroneOff,
    IconDroplet,
    IconDropletFilled,
    IconDropletFilled2,
    IconDropletHalf,
    IconDropletHalf2,
    IconDropletOff,
    IconEar,
    IconEarOff,
    IconEdit,
    IconEditCircle,
    IconEgg,
    IconEmphasis,
    IconEqual,
    IconEqualNot,
    IconEraser,
    IconExchange,
    IconExposure,
    IconExternalLink,
    IconEye,
    IconEyeCheck,
    IconEyeglass,
    IconEyeglass2,
    IconEyeOff,
    IconFaceId,
    IconFall,
    IconFeather,
    IconFence,
    IconFile,
    IconFileAlert,
    IconFileAnalytics,
    IconFileCertificate,
    IconFileCheck,
    IconFileCode,
    IconFileCode2,
    IconFileDiff,
    IconFileDislike,
    IconFileDownload,
    IconFileExport,
    IconFileHorizontal,
    IconFileImport,
    IconFileInfo,
    IconFileInvoice,
    IconFileLike,
    IconFileMinus,
    IconFileMusic,
    IconFileOff,
    IconFilePhone,
    IconFilePlus,
    IconFileReport,
    IconFiles,
    IconFileSearch,
    IconFileShredder,
    IconFilesOff,
    IconFileSymlink,
    IconFileText,
    IconFileUpload,
    IconFileX,
    IconFileZip,
    IconFilter,
    IconFilterOff,
    IconFingerprint,
    IconFiretruck,
    IconFish,
    IconFlag,
    IconFlag2,
    IconFlag3,
    IconFlame,
    IconFlare,
    IconFlask,
    IconFlipHorizontal,
    IconFlipVertical,
    IconFloatCenter,
    IconFloatLeft,
    IconFloatNone,
    IconFloatRight,
    IconFocus,
    IconFocus2,
    IconFold,
    IconFoldDown,
    IconFolder,
    IconFolderMinus,
    IconFolderOff,
    IconFolderPlus,
    IconFolders,
    IconFolderX,
    IconFoldUp,
    IconForbid,
    IconForbid2,
    IconForklift,
    IconForms,
    IconFrame,
    IconFriends,
    IconGasStation,
    IconGauge,
    IconGeometry,
    IconGhost,
    IconGift,
    IconGitBranch,
    IconGitCommit,
    IconGitCompare,
    IconGitFork,
    IconGitMerge,
    IconGitPullRequest,
    IconGlass,
    IconGlassFull,
    IconGlassOff,
    IconGlobe,
    IconGolf,
    IconGps,
    IconGrain,
    IconGridDots,
    IconGripHorizontal,
    IconGripVertical,
    IconGrowth,
    IconH1,
    IconH2,
    IconH3,
    IconH4,
    IconH5,
    IconH6,
    IconHandFinger,
    IconHandLittleFinger,
    IconHandMiddleFinger,
    IconHandOff,
    IconHandRingFinger,
    IconHandRock,
    IconHandStop,
    IconHandThreeFingers,
    IconHandTwoFingers,
    IconHanger,
    IconHash,
    IconHeading,
    IconHeadphones,
    IconHeadphonesOff,
    IconHeadset,
    IconHeart,
    IconHeartBroken,
    IconHelicopter,
    IconHelicopterLanding,
    IconHelp,
    IconHexagon,
    IconHexagonOff,
    IconHierarchy,
    IconHierarchy2,
    IconHistory,
    IconHome,
    IconHome2,
    IconIceCream,
    IconIceCream2,
    IconId,
    IconInbox,
    IconIndentDecrease,
    IconIndentIncrease,
    IconInfinity,
    IconInfoCircle,
    IconInfoSquare,
    IconItalic,
    IconJumpRope,
    IconKarate,
    IconKey,
    IconKeyboard,
    IconKeyboardHide,
    IconKeyboardOff,
    IconKeyboardShow,
    IconLanguage,
    IconLayersDifference,
    IconLayersIntersect,
    IconLayersLinked,
    IconLayersSubtract,
    IconLayersUnion,
    IconLayout,
    IconLayout2,
    IconLayoutAlignBottom,
    IconLayoutAlignCenter,
    IconLayoutAlignLeft,
    IconLayoutAlignMiddle,
    IconLayoutAlignRight,
    IconLayoutAlignTop,
    IconLayoutBottombar,
    IconLayoutCards,
    IconLayoutColumns,
    IconLayoutDistributeHorizontal,
    IconLayoutDistributeVertical,
    IconLayoutGrid,
    IconLayoutGridAdd,
    IconLayoutKanban,
    IconLayoutList,
    IconLayoutNavbar,
    IconLayoutRows,
    IconLayoutSidebar,
    IconLayoutSidebarRight,
    IconLeaf,
    IconLego,
    IconLemon,
    IconLetterA,
    IconLetterB,
    IconLetterC,
    IconLetterCase,
    IconLetterCaseLower,
    IconLetterCaseToggle,
    IconLetterCaseUpper,
    IconLetterD,
    IconLetterE,
    IconLetterF,
    IconLetterG,
    IconLetterH,
    IconLetterI,
    IconLetterJ,
    IconLetterK,
    IconLetterL,
    IconLetterM,
    IconLetterN,
    IconLetterO,
    IconLetterP,
    IconLetterQ,
    IconLetterR,
    IconLetterS,
    IconLettersCase,
    IconLetterSpacing,
    IconLetterT,
    IconLetterU,
    IconLetterV,
    IconLetterW,
    IconLetterX,
    IconLetterY,
    IconLetterZ,
    IconLicense,
    IconLifebuoy,
    IconLine,
    IconLineDashed,
    IconLineDotted,
    IconLineHeight,
    IconLink,
    IconList,
    IconListCheck,
    IconListNumbers,
    IconListSearch,
    IconLivePhoto,
    IconLiveView,
    IconLoader,
    IconLoaderQuarter,
    IconLocation,
    IconLock,
    IconLockAccess,
    IconLockOff,
    IconLockOpen,
    IconLogin,
    IconLogout,
    IconMacro,
    IconMagnet,
    IconMail,
    IconMailbox,
    IconMailForward,
    IconMailOpened,
    IconMan,
    IconManualGearbox,
    IconMap,
    IconMap2,
    IconMapPin,
    IconMapPinOff,
    IconMapPins,
    IconMarkdown,
    IconMarquee,
    IconMarquee2,
    IconMars,
    IconMask,
    IconMaskOff,
    IconMassage,
    IconMath,
    IconMathFunction,
    IconMathSymbols,
    IconMaximize,
    IconMeat,
    IconMedal,
    IconMedicalCross,
    IconMenu,
    IconMenu2,
    IconMessage,
    IconMessage2,
    IconMessageCircle,
    IconMessageCircle2,
    IconMessageCircleOff,
    IconMessageDots,
    IconMessageOff,
    IconMessagePlus,
    IconMessageReport,
    IconMessages,
    IconMessagesOff,
    IconMicrophone,
    IconMicrophone2,
    IconMicrophoneOff,
    IconMilk,
    IconMinimize,
    IconMinus,
    IconMinusVertical,
    IconMist,
    IconMoodBoy,
    IconMoodConfuzed,
    IconMoodCrazyHappy,
    IconMoodCry,
    IconMoodEmpty,
    IconMoodHappy,
    IconMoodKid,
    IconMoodNeutral,
    IconMoodSad,
    IconMoodSmile,
    IconMoodSuprised,
    IconMoodTongue,
    IconMoon,
    IconMoon2,
    IconMoonStars,
    IconMoped,
    IconMotorbike,
    IconMouse,
    IconMovie,
    IconMug,
    IconMushroom,
    IconMusic,
    IconNews,
    IconNewSection,
    IconNfc,
    IconNote,
    IconNotebook,
    IconNotes,
    IconNotification,
    IconNumber0,
    IconNumber1,
    IconNumber2,
    IconNumber3,
    IconNumber4,
    IconNumber5,
    IconNumber6,
    IconNumber7,
    IconNumber8,
    IconNumber9,
    IconOctagon,
    IconOctagonOff,
    IconOld,
    IconOlympics,
    IconOmega,
    IconOutlet,
    IconOverline,
    IconPackage,
    IconPacman,
    IconPageBreak,
    IconPaint,
    IconPalette,
    IconPanoramaHorizontal,
    IconPanoramaVertical,
    IconPaperclip,
    IconParachute,
    IconParentheses,
    IconParking,
    IconPeace,
    IconPencil,
    IconPennant,
    IconPepper,
    IconPercentage,
    IconPerspective,
    IconPhone,
    IconPhoneCall,
    IconPhoneCalling,
    IconPhoneCheck,
    IconPhoneIncoming,
    IconPhoneOff,
    IconPhoneOutgoing,
    IconPhonePause,
    IconPhonePlus,
    IconPhoneX,
    IconPhoto,
    IconPhotoOff,
    IconPhysotherapist,
    IconPictureInPicture,
    IconPictureInPictureOff,
    IconPictureInPictureOn,
    IconPill,
    IconPin,
    IconPinned,
    IconPinnedOff,
    IconPizza,
    IconPlane,
    IconPlaneArrival,
    IconPlaneDeparture,
    IconPlanet,
    IconPlant,
    IconPlant2,
    IconPlayCard,
    IconPlayerPause,
    IconPlayerPlay,
    IconPlayerRecord,
    IconPlayerSkipBack,
    IconPlayerSkipForward,
    IconPlayerStop,
    IconPlayerTrackNext,
    IconPlayerTrackPrev,
    IconPlaylist,
    IconPlug,
    IconPlus,
    IconPoint,
    IconPokeball,
    IconPolaroid,
    IconPool,
    IconPower,
    IconPray,
    IconPresentation,
    IconPresentationAnalytics,
    IconPrinter,
    IconPrompt,
    IconPropeller,
    IconPuzzle,
    IconPyramid,
    IconQrcode,
    IconQuestionMark,
    IconRadio,
    IconRadioactive,
    IconRadiusBottomLeft,
    IconRadiusBottomRight,
    IconRadiusTopLeft,
    IconRadiusTopRight,
    IconRainbow,
    IconReceipt,
    IconReceipt2,
    IconReceiptOff,
    IconReceiptRefund,
    IconReceiptTax,
    IconRecharging,
    IconRecordMail,
    IconRectangle,
    IconRectangleVertical,
    IconRecycle,
    IconRefresh,
    IconRefreshAlert,
    IconRegistered,
    IconRelationManyToMany,
    IconRelationOneToMany,
    IconRelationOneToOne,
    IconRepeat,
    IconRepeatOnce,
    IconReplace,
    IconReport,
    IconReportAnalytics,
    IconReportMedical,
    IconReportMoney,
    IconResize,
    IconRipple,
    IconRoadSign,
    IconRocket,
    IconRotate,
    IconRotate2,
    IconRotateClockwise,
    IconRotateClockwise2,
    IconRotateRectangle,
    IconRoute,
    IconRouter,
    IconRowInsertBottom,
    IconRowInsertTop,
    IconRss,
    IconRuler,
    IconRuler2,
    IconRun,
    IconSailboat,
    IconSalt,
    IconSatellite,
    IconSausage,
    IconScale,
    IconScan,
    IconSchool,
    IconScissors,
    IconScooter,
    IconScooterElectric,
    IconScreenShare,
    IconScreenShareOff,
    IconScubaMask,
    IconSearch,
    IconSection,
    IconSeeding,
    IconSelect,
    IconSelector,
    IconSend,
    IconSeparator,
    IconSeparatorHorizontal,
    IconSeparatorVertical,
    IconServer,
    IconServicemark,
    IconSettings,
    IconSettingsAutomation,
    IconShadow,
    IconShadowOff,
    IconShape,
    IconShape2,
    IconShape3,
    IconShare,
    IconShield,
    IconShieldCheck,
    IconShieldLock,
    IconShieldOff,
    IconShieldX,
    IconShip,
    IconShirt,
    IconShoppingCart,
    IconShoppingCartDiscount,
    IconShoppingCartOff,
    IconShoppingCartPlus,
    IconShoppingCartX,
    IconShredder,
    IconSignature,
    IconSitemap,
    IconSkateboard,
    IconSlice,
    IconSlideshow,
    IconSmartHome,
    IconSmoking,
    IconSmokingNo,
    IconSnowflake,
    IconSoccerField,
    IconSocial,
    IconSock,
    IconSortAscending,
    IconSortAscending2,
    IconSortAscendingLetters,
    IconSortAscendingNumbers,
    IconSortDescending,
    IconSortDescending2,
    IconSortDescendingLetters,
    IconSortDescendingNumbers,
    IconSoup,
    IconSpace,
    IconSpeakerphone,
    IconSpeedboat,
    IconSportBillard,
    IconSquare,
    IconSquare0,
    IconSquare1,
    IconSquare2,
    IconSquare3,
    IconSquare4,
    IconSquare5,
    IconSquare6,
    IconSquare7,
    IconSquare8,
    IconSquare9,
    IconSquareCheck,
    IconSquareDot,
    IconSquareForbid,
    IconSquareForbid2,
    IconSquareMinus,
    IconSquareOff,
    IconSquarePlus,
    IconSquareRoot,
    IconSquareRoot2,
    IconSquareRotated,
    IconSquareRotatedOff,
    IconSquaresDiagonal,
    IconSquaresFilled,
    IconSquareToggle,
    IconSquareToggleHorizontal,
    IconSquareX,
    IconStack,
    IconStack2,
    IconStairs,
    IconStairsDown,
    IconStairsUp,
    IconStar,
    IconStarHalf,
    IconStarOff,
    IconStars,
    IconSteeringWheel,
    IconStepInto,
    IconStepOut,
    IconStethoscope,
    IconSticker,
    IconStrikethrough,
    IconSubmarine,
    IconSubscript,
    IconSubtask,
    IconSum,
    IconSun,
    IconSunOff,
    IconSunrise,
    IconSunset,
    IconSuperscript,
    IconSwimming,
    IconSwitch,
    IconSwitch2,
    IconSwitch3,
    IconSwitchHorizontal,
    IconSwitchVertical,
    IconTable,
    IconTableExport,
    IconTableImport,
    IconTableOff,
    IconTag,
    IconTallymark1,
    IconTallymark2,
    IconTallymark3,
    IconTallymark4,
    IconTallymarks,
    IconTank,
    IconTarget,
    IconTemperature,
    IconTemperatureCelsius,
    IconTemperatureFahrenheit,
    IconTemperatureMinus,
    IconTemperaturePlus,
    IconTemplate,
    IconTent,
    IconTerminal,
    IconTerminal2,
    IconTestPipe,
    IconTextDirectionLtr,
    IconTextDirectionRtl,
    IconTextWrap,
    IconTextWrapDisabled,
    IconThumbDown,
    IconThumbUp,
    IconTicket,
    IconTiltShift,
    IconTir,
    IconToggleLeft,
    IconToggleRight,
    IconTool,
    IconTools,
    IconToolsKitchen,
    IconToolsKitchen2,
    IconTornado,
    IconTournament,
    IconTrack,
    IconTractor,
    IconTrademark,
    IconTrafficCone,
    IconTrafficLights,
    IconTrain,
    IconTransferIn,
    IconTransferOut,
    IconTrash,
    IconTrashOff,
    IconTree,
    IconTrees,
    IconTrendingDown,
    IconTrendingDown2,
    IconTrendingDown3,
    IconTrendingUp,
    IconTrendingUp2,
    IconTrendingUp3,
    IconTriangle,
    IconTriangleOff,
    IconTriangleSquareCircle,
    IconTrident,
    IconTrophy,
    IconTruck,
    IconTruckDelivery,
    IconTruckOff,
    IconTruckReturn,
    IconTypography,
    IconUmbrella,
    IconUnderline,
    IconUnlink,
    IconUpload,
    IconUrgent,
    IconUser,
    IconUserCheck,
    IconUserExclamation,
    IconUserMinus,
    IconUserOff,
    IconUserPlus,
    IconUsers,
    IconUserX,
    IconVaccine,
    IconVariable,
    IconVector,
    IconVectorBeizer2,
    IconVectorBezier,
    IconVectorTriangle,
    IconVenus,
    IconVersions,
    IconVideo,
    IconVideoMinus,
    IconVideoOff,
    IconVideoPlus,
    IconView360,
    IconViewfinder,
    IconViewportNarrow,
    IconViewportWide,
    IconVirus,
    IconVirusOff,
    IconVirusSearch,
    IconVocabulary,
    IconVolume,
    IconVolume2,
    IconVolume3,
    IconWalk,
    IconWallet,
    IconWand,
    IconWaveSawTool,
    IconWaveSine,
    IconWaveSquare,
    IconWifi,
    IconWifi0,
    IconWifi1,
    IconWifi2,
    IconWifiOff,
    IconWind,
    IconWindmill,
    IconWindow,
    IconWiper,
    IconWiperWash,
    IconWoman,
    IconWorld,
    IconWorldLatitude,
    IconWorldLongitude,
    IconWreckingBall,
    IconWriting,
    IconWritingSign,
    IconX,
    IconYinYang,
    IconZodiacAquarius,
    IconZodiacAries,
    IconZodiacCancer,
    IconZodiacCapricorn,
    IconZodiacGemini,
    IconZodiacLeo,
    IconZodiacLibra,
    IconZodiacPisces,
    IconZodiacSagittarius,
    IconZodiacScorpio,
    IconZodiacTaurus,
    IconZodiacVirgo,
    IconZoomCancel,
    IconZoomCheck,
    IconZoomIn,
    IconZoomMoney,
    TablerIconProps,
} from '@tabler/icons';
import * as React from 'react';

interface IIconByNameProps extends TablerIconProps {
    icon: keyof typeof icons;
}

export function IconByName(props: IIconByNameProps) {
    const icon = icons[(props.icon as string).replaceAll('-', '').toLowerCase() as keyof typeof icons];
    return <>{icon ? icon(props) : <IconBug {...props} />}</>;
}

const icons = {
    '2fa': (props: TablerIconProps) => <Icon2fa {...props} />,
    '3dcubesphere': (props: TablerIconProps) => <Icon3dCubeSphere {...props} />,
    ab: (props: TablerIconProps) => <IconAB {...props} />,
    accesspointoff: (props: TablerIconProps) => <IconAccessPointOff {...props} />,
    accesspoint: (props: TablerIconProps) => <IconAccessPoint {...props} />,
    accessible: (props: TablerIconProps) => <IconAccessible {...props} />,
    activity: (props: TablerIconProps) => <IconActivity {...props} />,
    ad2: (props: TablerIconProps) => <IconAd2 {...props} />,
    ad: (props: TablerIconProps) => <IconAd {...props} />,
    adjustmentsalt: (props: TablerIconProps) => <IconAdjustmentsAlt {...props} />,
    adjustmentshorizontal: (props: TablerIconProps) => <IconAdjustmentsHorizontal {...props} />,
    adjustments: (props: TablerIconProps) => <IconAdjustments {...props} />,
    aeriallift: (props: TablerIconProps) => <IconAerialLift {...props} />,
    affiliate: (props: TablerIconProps) => <IconAffiliate {...props} />,
    alarm: (props: TablerIconProps) => <IconAlarm {...props} />,
    alertcircle: (props: TablerIconProps) => <IconAlertCircle {...props} />,
    alertoctagon: (props: TablerIconProps) => <IconAlertOctagon {...props} />,
    alerttriangle: (props: TablerIconProps) => <IconAlertTriangle {...props} />,
    alien: (props: TablerIconProps) => <IconAlien {...props} />,
    aligncenter: (props: TablerIconProps) => <IconAlignCenter {...props} />,
    alignjustified: (props: TablerIconProps) => <IconAlignJustified {...props} />,
    alignleft: (props: TablerIconProps) => <IconAlignLeft {...props} />,
    alignright: (props: TablerIconProps) => <IconAlignRight {...props} />,
    ambulance: (props: TablerIconProps) => <IconAmbulance {...props} />,
    anchor: (props: TablerIconProps) => <IconAnchor {...props} />,
    angle: (props: TablerIconProps) => <IconAngle {...props} />,
    antennabars1: (props: TablerIconProps) => <IconAntennaBars1 {...props} />,
    antennabars2: (props: TablerIconProps) => <IconAntennaBars2 {...props} />,
    antennabars3: (props: TablerIconProps) => <IconAntennaBars3 {...props} />,
    antennabars4: (props: TablerIconProps) => <IconAntennaBars4 {...props} />,
    antennabars5: (props: TablerIconProps) => <IconAntennaBars5 {...props} />,
    aperture: (props: TablerIconProps) => <IconAperture {...props} />,
    apple: (props: TablerIconProps) => <IconApple {...props} />,
    apps: (props: TablerIconProps) => <IconApps {...props} />,
    archive: (props: TablerIconProps) => <IconArchive {...props} />,
    arrowbackup: (props: TablerIconProps) => <IconArrowBackUp {...props} />,
    arrowback: (props: TablerIconProps) => <IconArrowBack {...props} />,
    arrowbardown: (props: TablerIconProps) => <IconArrowBarDown {...props} />,
    arrowbarleft: (props: TablerIconProps) => <IconArrowBarLeft {...props} />,
    arrowbarright: (props: TablerIconProps) => <IconArrowBarRight {...props} />,
    arrowbartodown: (props: TablerIconProps) => <IconArrowBarToDown {...props} />,
    arrowbartoleft: (props: TablerIconProps) => <IconArrowBarToLeft {...props} />,
    arrowbartoright: (props: TablerIconProps) => <IconArrowBarToRight {...props} />,
    arrowbartoup: (props: TablerIconProps) => <IconArrowBarToUp {...props} />,
    arrowbarup: (props: TablerIconProps) => <IconArrowBarUp {...props} />,
    arrowbigdown: (props: TablerIconProps) => <IconArrowBigDown {...props} />,
    arrowbigleft: (props: TablerIconProps) => <IconArrowBigLeft {...props} />,
    arrowbigright: (props: TablerIconProps) => <IconArrowBigRight {...props} />,
    arrowbigtop: (props: TablerIconProps) => <IconArrowBigTop {...props} />,
    arrowbottombar: (props: TablerIconProps) => <IconArrowBottomBar {...props} />,
    arrowbottomcircle: (props: TablerIconProps) => <IconArrowBottomCircle {...props} />,
    arrowbottomsquare: (props: TablerIconProps) => <IconArrowBottomSquare {...props} />,
    arrowbottomtail: (props: TablerIconProps) => <IconArrowBottomTail {...props} />,
    arrowdowncircle: (props: TablerIconProps) => <IconArrowDownCircle {...props} />,
    arrowdownleftcircle: (props: TablerIconProps) => <IconArrowDownLeftCircle {...props} />,
    arrowdownleft: (props: TablerIconProps) => <IconArrowDownLeft {...props} />,
    arrowdownrightcircle: (props: TablerIconProps) => <IconArrowDownRightCircle {...props} />,
    arrowdownright: (props: TablerIconProps) => <IconArrowDownRight {...props} />,
    arrowdown: (props: TablerIconProps) => <IconArrowDown {...props} />,
    arrowforwardup: (props: TablerIconProps) => <IconArrowForwardUp {...props} />,
    arrowforward: (props: TablerIconProps) => <IconArrowForward {...props} />,
    arrowleftbar: (props: TablerIconProps) => <IconArrowLeftBar {...props} />,
    arrowleftcircle: (props: TablerIconProps) => <IconArrowLeftCircle {...props} />,
    arrowleftsquare: (props: TablerIconProps) => <IconArrowLeftSquare {...props} />,
    arrowlefttail: (props: TablerIconProps) => <IconArrowLeftTail {...props} />,
    arrowleft: (props: TablerIconProps) => <IconArrowLeft {...props} />,
    arrowloopleft: (props: TablerIconProps) => <IconArrowLoopLeft {...props} />,
    arrowloopright: (props: TablerIconProps) => <IconArrowLoopRight {...props} />,
    arrownarrowdown: (props: TablerIconProps) => <IconArrowNarrowDown {...props} />,
    arrownarrowleft: (props: TablerIconProps) => <IconArrowNarrowLeft {...props} />,
    arrownarrowright: (props: TablerIconProps) => <IconArrowNarrowRight {...props} />,
    arrownarrowup: (props: TablerIconProps) => <IconArrowNarrowUp {...props} />,
    arrowrampleft: (props: TablerIconProps) => <IconArrowRampLeft {...props} />,
    arrowrampright: (props: TablerIconProps) => <IconArrowRampRight {...props} />,
    arrowrightbar: (props: TablerIconProps) => <IconArrowRightBar {...props} />,
    arrowrightcircle: (props: TablerIconProps) => <IconArrowRightCircle {...props} />,
    arrowrightsquare: (props: TablerIconProps) => <IconArrowRightSquare {...props} />,
    arrowrighttail: (props: TablerIconProps) => <IconArrowRightTail {...props} />,
    arrowright: (props: TablerIconProps) => <IconArrowRight {...props} />,
    arrowtopbar: (props: TablerIconProps) => <IconArrowTopBar {...props} />,
    arrowtopcircle: (props: TablerIconProps) => <IconArrowTopCircle {...props} />,
    arrowtopsquare: (props: TablerIconProps) => <IconArrowTopSquare {...props} />,
    arrowtoptail: (props: TablerIconProps) => <IconArrowTopTail {...props} />,
    arrowupcircle: (props: TablerIconProps) => <IconArrowUpCircle {...props} />,
    arrowupleftcircle: (props: TablerIconProps) => <IconArrowUpLeftCircle {...props} />,
    arrowupleft: (props: TablerIconProps) => <IconArrowUpLeft {...props} />,
    arrowuprightcircle: (props: TablerIconProps) => <IconArrowUpRightCircle {...props} />,
    arrowupright: (props: TablerIconProps) => <IconArrowUpRight {...props} />,
    arrowup: (props: TablerIconProps) => <IconArrowUp {...props} />,
    arrowwaveleftdown: (props: TablerIconProps) => <IconArrowWaveLeftDown {...props} />,
    arrowwaveleftup: (props: TablerIconProps) => <IconArrowWaveLeftUp {...props} />,
    arrowwaverightdown: (props: TablerIconProps) => <IconArrowWaveRightDown {...props} />,
    arrowwaverightup: (props: TablerIconProps) => <IconArrowWaveRightUp {...props} />,
    arrowsdiagonal2: (props: TablerIconProps) => <IconArrowsDiagonal2 {...props} />,
    arrowsdiagonal: (props: TablerIconProps) => <IconArrowsDiagonal {...props} />,
    arrowsdoublenesw: (props: TablerIconProps) => <IconArrowsDoubleNeSw {...props} />,
    arrowsdoublenwse: (props: TablerIconProps) => <IconArrowsDoubleNwSe {...props} />,
    arrowsdoublesenw: (props: TablerIconProps) => <IconArrowsDoubleSeNw {...props} />,
    arrowsdoubleswne: (props: TablerIconProps) => <IconArrowsDoubleSwNe {...props} />,
    arrowsdownup: (props: TablerIconProps) => <IconArrowsDownUp {...props} />,
    arrowsdown: (props: TablerIconProps) => <IconArrowsDown {...props} />,
    arrowshorizontal: (props: TablerIconProps) => <IconArrowsHorizontal {...props} />,
    arrowsjoin2: (props: TablerIconProps) => <IconArrowsJoin2 {...props} />,
    arrowsjoin: (props: TablerIconProps) => <IconArrowsJoin {...props} />,
    arrowsleftdown: (props: TablerIconProps) => <IconArrowsLeftDown {...props} />,
    arrowsleftright: (props: TablerIconProps) => <IconArrowsLeftRight {...props} />,
    arrowsleft: (props: TablerIconProps) => <IconArrowsLeft {...props} />,
    arrowsmaximize: (props: TablerIconProps) => <IconArrowsMaximize {...props} />,
    arrowsminimize: (props: TablerIconProps) => <IconArrowsMinimize {...props} />,
    arrowsrightdown: (props: TablerIconProps) => <IconArrowsRightDown {...props} />,
    arrowsrightleft: (props: TablerIconProps) => <IconArrowsRightLeft {...props} />,
    arrowsright: (props: TablerIconProps) => <IconArrowsRight {...props} />,
    arrowssort: (props: TablerIconProps) => <IconArrowsSort {...props} />,
    arrowssplit2: (props: TablerIconProps) => <IconArrowsSplit2 {...props} />,
    arrowssplit: (props: TablerIconProps) => <IconArrowsSplit {...props} />,
    arrowsupdown: (props: TablerIconProps) => <IconArrowsUpDown {...props} />,
    arrowsupleft: (props: TablerIconProps) => <IconArrowsUpLeft {...props} />,
    arrowsupright: (props: TablerIconProps) => <IconArrowsUpRight {...props} />,
    arrowsup: (props: TablerIconProps) => <IconArrowsUp {...props} />,
    arrowsvertical: (props: TablerIconProps) => <IconArrowsVertical {...props} />,
    artboard: (props: TablerIconProps) => <IconArtboard {...props} />,
    aspectratio: (props: TablerIconProps) => <IconAspectRatio {...props} />,
    at: (props: TablerIconProps) => <IconAt {...props} />,
    atom2: (props: TablerIconProps) => <IconAtom2 {...props} />,
    atom: (props: TablerIconProps) => <IconAtom {...props} />,
    award: (props: TablerIconProps) => <IconAward {...props} />,
    backhoe: (props: TablerIconProps) => <IconBackhoe {...props} />,
    backspace: (props: TablerIconProps) => <IconBackspace {...props} />,
    ballamericanfootball: (props: TablerIconProps) => <IconBallAmericanFootball {...props} />,
    ballbasketball: (props: TablerIconProps) => <IconBallBasketball {...props} />,
    ballbowling: (props: TablerIconProps) => <IconBallBowling {...props} />,
    ballfootballoff: (props: TablerIconProps) => <IconBallFootballOff {...props} />,
    ballfootball: (props: TablerIconProps) => <IconBallFootball {...props} />,
    balltennis: (props: TablerIconProps) => <IconBallTennis {...props} />,
    ballvolleyball: (props: TablerIconProps) => <IconBallVolleyball {...props} />,
    ban: (props: TablerIconProps) => <IconBan {...props} />,
    bandage: (props: TablerIconProps) => <IconBandage {...props} />,
    barcode: (props: TablerIconProps) => <IconBarcode {...props} />,
    basket: (props: TablerIconProps) => <IconBasket {...props} />,
    battery1: (props: TablerIconProps) => <IconBattery1 {...props} />,
    battery2: (props: TablerIconProps) => <IconBattery2 {...props} />,
    battery3: (props: TablerIconProps) => <IconBattery3 {...props} />,
    battery4: (props: TablerIconProps) => <IconBattery4 {...props} />,
    batteryautomotive: (props: TablerIconProps) => <IconBatteryAutomotive {...props} />,
    batterycharging: (props: TablerIconProps) => <IconBatteryCharging {...props} />,
    batteryoff: (props: TablerIconProps) => <IconBatteryOff {...props} />,
    battery: (props: TablerIconProps) => <IconBattery {...props} />,
    bed: (props: TablerIconProps) => <IconBed {...props} />,
    bellminus: (props: TablerIconProps) => <IconBellMinus {...props} />,
    belloff: (props: TablerIconProps) => <IconBellOff {...props} />,
    bellplus: (props: TablerIconProps) => <IconBellPlus {...props} />,
    bellringing2: (props: TablerIconProps) => <IconBellRinging2 {...props} />,
    bellringing: (props: TablerIconProps) => <IconBellRinging {...props} />,
    bellx: (props: TablerIconProps) => <IconBellX {...props} />,
    bell: (props: TablerIconProps) => <IconBell {...props} />,
    bike: (props: TablerIconProps) => <IconBike {...props} />,
    binary: (props: TablerIconProps) => <IconBinary {...props} />,
    biohazard: (props: TablerIconProps) => <IconBiohazard {...props} />,
    blockquote: (props: TablerIconProps) => <IconBlockquote {...props} />,
    bluetoothconnected: (props: TablerIconProps) => <IconBluetoothConnected {...props} />,
    bluetoothoff: (props: TablerIconProps) => <IconBluetoothOff {...props} />,
    bluetooth: (props: TablerIconProps) => <IconBluetooth {...props} />,
    bold: (props: TablerIconProps) => <IconBold {...props} />,
    boltoff: (props: TablerIconProps) => <IconBoltOff {...props} />,
    bolt: (props: TablerIconProps) => <IconBolt {...props} />,
    bone: (props: TablerIconProps) => <IconBone {...props} />,
    book: (props: TablerIconProps) => <IconBook {...props} />,
    bookmarkoff: (props: TablerIconProps) => <IconBookmarkOff {...props} />,
    bookmark: (props: TablerIconProps) => <IconBookmark {...props} />,
    bookmarks: (props: TablerIconProps) => <IconBookmarks {...props} />,
    borderall: (props: TablerIconProps) => <IconBorderAll {...props} />,
    borderbottom: (props: TablerIconProps) => <IconBorderBottom {...props} />,
    borderhorizontal: (props: TablerIconProps) => <IconBorderHorizontal {...props} />,
    borderinner: (props: TablerIconProps) => <IconBorderInner {...props} />,
    borderleft: (props: TablerIconProps) => <IconBorderLeft {...props} />,
    bordernone: (props: TablerIconProps) => <IconBorderNone {...props} />,
    borderouter: (props: TablerIconProps) => <IconBorderOuter {...props} />,
    borderradius: (props: TablerIconProps) => <IconBorderRadius {...props} />,
    borderright: (props: TablerIconProps) => <IconBorderRight {...props} />,
    borderstyle2: (props: TablerIconProps) => <IconBorderStyle2 {...props} />,
    borderstyle: (props: TablerIconProps) => <IconBorderStyle {...props} />,
    bordertop: (props: TablerIconProps) => <IconBorderTop {...props} />,
    bordervertical: (props: TablerIconProps) => <IconBorderVertical {...props} />,
    bottle: (props: TablerIconProps) => <IconBottle {...props} />,
    boxmargin: (props: TablerIconProps) => <IconBoxMargin {...props} />,
    boxmodel2: (props: TablerIconProps) => <IconBoxModel2 {...props} />,
    boxmodel: (props: TablerIconProps) => <IconBoxModel {...props} />,
    boxmultiple0: (props: TablerIconProps) => <IconBoxMultiple0 {...props} />,
    boxmultiple1: (props: TablerIconProps) => <IconBoxMultiple1 {...props} />,
    boxmultiple2: (props: TablerIconProps) => <IconBoxMultiple2 {...props} />,
    boxmultiple3: (props: TablerIconProps) => <IconBoxMultiple3 {...props} />,
    boxmultiple4: (props: TablerIconProps) => <IconBoxMultiple4 {...props} />,
    boxmultiple5: (props: TablerIconProps) => <IconBoxMultiple5 {...props} />,
    boxmultiple6: (props: TablerIconProps) => <IconBoxMultiple6 {...props} />,
    boxmultiple7: (props: TablerIconProps) => <IconBoxMultiple7 {...props} />,
    boxmultiple8: (props: TablerIconProps) => <IconBoxMultiple8 {...props} />,
    boxmultiple9: (props: TablerIconProps) => <IconBoxMultiple9 {...props} />,
    boxmultiple: (props: TablerIconProps) => <IconBoxMultiple {...props} />,
    boxpadding: (props: TablerIconProps) => <IconBoxPadding {...props} />,
    box: (props: TablerIconProps) => <IconBox {...props} />,
    braces: (props: TablerIconProps) => <IconBraces {...props} />,
    brackets: (props: TablerIconProps) => <IconBrackets {...props} />,
    brandairbnb: (props: TablerIconProps) => <IconBrandAirbnb {...props} />,
    brandandroid: (props: TablerIconProps) => <IconBrandAndroid {...props} />,
    brandapplearcade: (props: TablerIconProps) => <IconBrandAppleArcade {...props} />,
    brandapple: (props: TablerIconProps) => <IconBrandApple {...props} />,
    brandappstore: (props: TablerIconProps) => <IconBrandAppstore {...props} />,
    brandasana: (props: TablerIconProps) => <IconBrandAsana {...props} />,
    brandbehance: (props: TablerIconProps) => <IconBrandBehance {...props} />,
    brandbing: (props: TablerIconProps) => <IconBrandBing {...props} />,
    brandbitbucket: (props: TablerIconProps) => <IconBrandBitbucket {...props} />,
    brandbooking: (props: TablerIconProps) => <IconBrandBooking {...props} />,
    brandchrome: (props: TablerIconProps) => <IconBrandChrome {...props} />,
    brandcodepen: (props: TablerIconProps) => <IconBrandCodepen {...props} />,
    brandcodesandbox: (props: TablerIconProps) => <IconBrandCodesandbox {...props} />,
    brandcss3: (props: TablerIconProps) => <IconBrandCss3 {...props} />,
    branddeviantart: (props: TablerIconProps) => <IconBrandDeviantart {...props} />,
    branddiscord: (props: TablerIconProps) => <IconBrandDiscord {...props} />,
    branddisqus: (props: TablerIconProps) => <IconBrandDisqus {...props} />,
    branddocker: (props: TablerIconProps) => <IconBrandDocker {...props} />,
    branddribbble: (props: TablerIconProps) => <IconBrandDribbble {...props} />,
    brandedge: (props: TablerIconProps) => <IconBrandEdge {...props} />,
    brandfacebook: (props: TablerIconProps) => <IconBrandFacebook {...props} />,
    brandfigma: (props: TablerIconProps) => <IconBrandFigma {...props} />,
    brandfirefox: (props: TablerIconProps) => <IconBrandFirefox {...props} />,
    brandflickr: (props: TablerIconProps) => <IconBrandFlickr {...props} />,
    brandfoursquare: (props: TablerIconProps) => <IconBrandFoursquare {...props} />,
    brandframer: (props: TablerIconProps) => <IconBrandFramer {...props} />,
    brandgithub: (props: TablerIconProps) => <IconBrandGithub {...props} />,
    brandgitlab: (props: TablerIconProps) => <IconBrandGitlab {...props} />,
    brandgoogleanalytics: (props: TablerIconProps) => <IconBrandGoogleAnalytics {...props} />,
    brandgoogledrive: (props: TablerIconProps) => <IconBrandGoogleDrive {...props} />,
    brandgoogleplay: (props: TablerIconProps) => <IconBrandGooglePlay {...props} />,
    brandgoogle: (props: TablerIconProps) => <IconBrandGoogle {...props} />,
    brandgravatar: (props: TablerIconProps) => <IconBrandGravatar {...props} />,
    brandhipchat: (props: TablerIconProps) => <IconBrandHipchat {...props} />,
    brandhtml5: (props: TablerIconProps) => <IconBrandHtml5 {...props} />,
    brandinstagram: (props: TablerIconProps) => <IconBrandInstagram {...props} />,
    brandjavascript: (props: TablerIconProps) => <IconBrandJavascript {...props} />,
    brandkickstarter: (props: TablerIconProps) => <IconBrandKickstarter {...props} />,
    brandkotlin: (props: TablerIconProps) => <IconBrandKotlin {...props} />,
    brandlinkedin: (props: TablerIconProps) => <IconBrandLinkedin {...props} />,
    brandmedium: (props: TablerIconProps) => <IconBrandMedium {...props} />,
    brandmessenger: (props: TablerIconProps) => <IconBrandMessenger {...props} />,
    brandnetflix: (props: TablerIconProps) => <IconBrandNetflix {...props} />,
    brandopensource: (props: TablerIconProps) => <IconBrandOpenSource {...props} />,
    brandopera: (props: TablerIconProps) => <IconBrandOpera {...props} />,
    brandpagekit: (props: TablerIconProps) => <IconBrandPagekit {...props} />,
    brandpatreon: (props: TablerIconProps) => <IconBrandPatreon {...props} />,
    brandpaypal: (props: TablerIconProps) => <IconBrandPaypal {...props} />,
    brandpinterest: (props: TablerIconProps) => <IconBrandPinterest {...props} />,
    brandpocket: (props: TablerIconProps) => <IconBrandPocket {...props} />,
    brandproducthunt: (props: TablerIconProps) => <IconBrandProducthunt {...props} />,
    brandpython: (props: TablerIconProps) => <IconBrandPython {...props} />,
    brandreddit: (props: TablerIconProps) => <IconBrandReddit {...props} />,
    brandsafari: (props: TablerIconProps) => <IconBrandSafari {...props} />,
    brandsass: (props: TablerIconProps) => <IconBrandSass {...props} />,
    brandsentry: (props: TablerIconProps) => <IconBrandSentry {...props} />,
    brandshazam: (props: TablerIconProps) => <IconBrandShazam {...props} />,
    brandsketch: (props: TablerIconProps) => <IconBrandSketch {...props} />,
    brandskype: (props: TablerIconProps) => <IconBrandSkype {...props} />,
    brandslack: (props: TablerIconProps) => <IconBrandSlack {...props} />,
    brandsnapchat: (props: TablerIconProps) => <IconBrandSnapchat {...props} />,
    brandsoundcloud: (props: TablerIconProps) => <IconBrandSoundcloud {...props} />,
    brandspotify: (props: TablerIconProps) => <IconBrandSpotify {...props} />,
    brandsteam: (props: TablerIconProps) => <IconBrandSteam {...props} />,
    brandstripe: (props: TablerIconProps) => <IconBrandStripe {...props} />,
    brandtabler: (props: TablerIconProps) => <IconBrandTabler {...props} />,
    brandtailwind: (props: TablerIconProps) => <IconBrandTailwind {...props} />,
    brandtelegram: (props: TablerIconProps) => <IconBrandTelegram {...props} />,
    brandtidal: (props: TablerIconProps) => <IconBrandTidal {...props} />,
    brandtiktok: (props: TablerIconProps) => <IconBrandTiktok {...props} />,
    brandtinder: (props: TablerIconProps) => <IconBrandTinder {...props} />,
    brandtumblr: (props: TablerIconProps) => <IconBrandTumblr {...props} />,
    brandtwitch: (props: TablerIconProps) => <IconBrandTwitch {...props} />,
    brandtwitter: (props: TablerIconProps) => <IconBrandTwitter {...props} />,
    brandunsplash: (props: TablerIconProps) => <IconBrandUnsplash {...props} />,
    brandvercel: (props: TablerIconProps) => <IconBrandVercel {...props} />,
    brandvimeo: (props: TablerIconProps) => <IconBrandVimeo {...props} />,
    brandvk: (props: TablerIconProps) => <IconBrandVk {...props} />,
    brandwhatsapp: (props: TablerIconProps) => <IconBrandWhatsapp {...props} />,
    brandwindows: (props: TablerIconProps) => <IconBrandWindows {...props} />,
    brandyahoo: (props: TablerIconProps) => <IconBrandYahoo {...props} />,
    brandycombinator: (props: TablerIconProps) => <IconBrandYcombinator {...props} />,
    brandyoutube: (props: TablerIconProps) => <IconBrandYoutube {...props} />,
    briefcase: (props: TablerIconProps) => <IconBriefcase {...props} />,
    brightness2: (props: TablerIconProps) => <IconBrightness2 {...props} />,
    brightnessdown: (props: TablerIconProps) => <IconBrightnessDown {...props} />,
    brightnesshalf: (props: TablerIconProps) => <IconBrightnessHalf {...props} />,
    brightnessup: (props: TablerIconProps) => <IconBrightnessUp {...props} />,
    brightness: (props: TablerIconProps) => <IconBrightness {...props} />,
    browser: (props: TablerIconProps) => <IconBrowser {...props} />,
    brush: (props: TablerIconProps) => <IconBrush {...props} />,
    bucket: (props: TablerIconProps) => <IconBucket {...props} />,
    bug: (props: TablerIconProps) => <IconBug {...props} />,
    buildingarch: (props: TablerIconProps) => <IconBuildingArch {...props} />,
    buildingbank: (props: TablerIconProps) => <IconBuildingBank {...props} />,
    buildingbridge2: (props: TablerIconProps) => <IconBuildingBridge2 {...props} />,
    buildingbridge: (props: TablerIconProps) => <IconBuildingBridge {...props} />,
    buildingcarousel: (props: TablerIconProps) => <IconBuildingCarousel {...props} />,
    buildingcastle: (props: TablerIconProps) => <IconBuildingCastle {...props} />,
    buildingchurch: (props: TablerIconProps) => <IconBuildingChurch {...props} />,
    buildingcommunity: (props: TablerIconProps) => <IconBuildingCommunity {...props} />,
    buildingcottage: (props: TablerIconProps) => <IconBuildingCottage {...props} />,
    buildingfactory: (props: TablerIconProps) => <IconBuildingFactory {...props} />,
    buildingfortress: (props: TablerIconProps) => <IconBuildingFortress {...props} />,
    buildinghospital: (props: TablerIconProps) => <IconBuildingHospital {...props} />,
    buildinglighthouse: (props: TablerIconProps) => <IconBuildingLighthouse {...props} />,
    buildingmonument: (props: TablerIconProps) => <IconBuildingMonument {...props} />,
    buildingpavilon: (props: TablerIconProps) => <IconBuildingPavilon {...props} />,
    buildingskyscraper: (props: TablerIconProps) => <IconBuildingSkyscraper {...props} />,
    buildingstore: (props: TablerIconProps) => <IconBuildingStore {...props} />,
    buildingwarehouse: (props: TablerIconProps) => <IconBuildingWarehouse {...props} />,
    building: (props: TablerIconProps) => <IconBuilding {...props} />,
    bulboff: (props: TablerIconProps) => <IconBulbOff {...props} />,
    bulb: (props: TablerIconProps) => <IconBulb {...props} />,
    bulldozer: (props: TablerIconProps) => <IconBulldozer {...props} />,
    bus: (props: TablerIconProps) => <IconBus {...props} />,
    businessplan: (props: TablerIconProps) => <IconBusinessplan {...props} />,
    calculator: (props: TablerIconProps) => <IconCalculator {...props} />,
    calendarevent: (props: TablerIconProps) => <IconCalendarEvent {...props} />,
    calendarminus: (props: TablerIconProps) => <IconCalendarMinus {...props} />,
    calendaroff: (props: TablerIconProps) => <IconCalendarOff {...props} />,
    calendarplus: (props: TablerIconProps) => <IconCalendarPlus {...props} />,
    calendarstats: (props: TablerIconProps) => <IconCalendarStats {...props} />,
    calendartime: (props: TablerIconProps) => <IconCalendarTime {...props} />,
    calendar: (props: TablerIconProps) => <IconCalendar {...props} />,
    cameraminus: (props: TablerIconProps) => <IconCameraMinus {...props} />,
    cameraoff: (props: TablerIconProps) => <IconCameraOff {...props} />,
    cameraplus: (props: TablerIconProps) => <IconCameraPlus {...props} />,
    camerarotate: (props: TablerIconProps) => <IconCameraRotate {...props} />,
    cameraselfie: (props: TablerIconProps) => <IconCameraSelfie {...props} />,
    camera: (props: TablerIconProps) => <IconCamera {...props} />,
    candy: (props: TablerIconProps) => <IconCandy {...props} />,
    capture: (props: TablerIconProps) => <IconCapture {...props} />,
    carcrane: (props: TablerIconProps) => <IconCarCrane {...props} />,
    car: (props: TablerIconProps) => <IconCar {...props} />,
    caravan: (props: TablerIconProps) => <IconCaravan {...props} />,
    cardboards: (props: TablerIconProps) => <IconCardboards {...props} />,
    caretdown: (props: TablerIconProps) => <IconCaretDown {...props} />,
    caretleft: (props: TablerIconProps) => <IconCaretLeft {...props} />,
    caretright: (props: TablerIconProps) => <IconCaretRight {...props} />,
    caretup: (props: TablerIconProps) => <IconCaretUp {...props} />,
    cashbanknoteoff: (props: TablerIconProps) => <IconCashBanknoteOff {...props} />,
    cashbanknote: (props: TablerIconProps) => <IconCashBanknote {...props} />,
    cash: (props: TablerIconProps) => <IconCash {...props} />,
    cast: (props: TablerIconProps) => <IconCast {...props} />,
    ce: (props: TablerIconProps) => <IconCe {...props} />,
    certificate: (props: TablerIconProps) => <IconCertificate {...props} />,
    chargingpile: (props: TablerIconProps) => <IconChargingPile {...props} />,
    chartarcs3: (props: TablerIconProps) => <IconChartArcs3 {...props} />,
    chartarcs: (props: TablerIconProps) => <IconChartArcs {...props} />,
    chartarealine: (props: TablerIconProps) => <IconChartAreaLine {...props} />,
    chartarea: (props: TablerIconProps) => <IconChartArea {...props} />,
    chartarrowsvertical: (props: TablerIconProps) => <IconChartArrowsVertical {...props} />,
    chartarrows: (props: TablerIconProps) => <IconChartArrows {...props} />,
    chartbar: (props: TablerIconProps) => <IconChartBar {...props} />,
    chartbubble: (props: TablerIconProps) => <IconChartBubble {...props} />,
    chartcandle: (props: TablerIconProps) => <IconChartCandle {...props} />,
    chartcircles: (props: TablerIconProps) => <IconChartCircles {...props} />,
    chartdonut2: (props: TablerIconProps) => <IconChartDonut2 {...props} />,
    chartdonut3: (props: TablerIconProps) => <IconChartDonut3 {...props} />,
    chartdonut4: (props: TablerIconProps) => <IconChartDonut4 {...props} />,
    chartdonut: (props: TablerIconProps) => <IconChartDonut {...props} />,
    chartdots: (props: TablerIconProps) => <IconChartDots {...props} />,
    chartinfographic: (props: TablerIconProps) => <IconChartInfographic {...props} />,
    chartline: (props: TablerIconProps) => <IconChartLine {...props} />,
    chartpie2: (props: TablerIconProps) => <IconChartPie2 {...props} />,
    chartpie3: (props: TablerIconProps) => <IconChartPie3 {...props} />,
    chartpie4: (props: TablerIconProps) => <IconChartPie4 {...props} />,
    chartpie: (props: TablerIconProps) => <IconChartPie {...props} />,
    chartradar: (props: TablerIconProps) => <IconChartRadar {...props} />,
    check: (props: TablerIconProps) => <IconCheck {...props} />,
    checkbox: (props: TablerIconProps) => <IconCheckbox {...props} />,
    checks: (props: TablerIconProps) => <IconChecks {...props} />,
    cheese: (props: TablerIconProps) => <IconCheese {...props} />,
    chevrondownleft: (props: TablerIconProps) => <IconChevronDownLeft {...props} />,
    chevrondownright: (props: TablerIconProps) => <IconChevronDownRight {...props} />,
    chevrondown: (props: TablerIconProps) => <IconChevronDown {...props} />,
    chevronleft: (props: TablerIconProps) => <IconChevronLeft {...props} />,
    chevronright: (props: TablerIconProps) => <IconChevronRight {...props} />,
    chevronupleft: (props: TablerIconProps) => <IconChevronUpLeft {...props} />,
    chevronupright: (props: TablerIconProps) => <IconChevronUpRight {...props} />,
    chevronup: (props: TablerIconProps) => <IconChevronUp {...props} />,
    chevronsdownleft: (props: TablerIconProps) => <IconChevronsDownLeft {...props} />,
    chevronsdownright: (props: TablerIconProps) => <IconChevronsDownRight {...props} />,
    chevronsdown: (props: TablerIconProps) => <IconChevronsDown {...props} />,
    chevronsleft: (props: TablerIconProps) => <IconChevronsLeft {...props} />,
    chevronsright: (props: TablerIconProps) => <IconChevronsRight {...props} />,
    chevronsupleft: (props: TablerIconProps) => <IconChevronsUpLeft {...props} />,
    chevronsupright: (props: TablerIconProps) => <IconChevronsUpRight {...props} />,
    chevronsup: (props: TablerIconProps) => <IconChevronsUp {...props} />,
    christmastree: (props: TablerIconProps) => <IconChristmasTree {...props} />,
    circle0: (props: TablerIconProps) => <IconCircle0 {...props} />,
    circle1: (props: TablerIconProps) => <IconCircle1 {...props} />,
    circle2: (props: TablerIconProps) => <IconCircle2 {...props} />,
    circle3: (props: TablerIconProps) => <IconCircle3 {...props} />,
    circle4: (props: TablerIconProps) => <IconCircle4 {...props} />,
    circle5: (props: TablerIconProps) => <IconCircle5 {...props} />,
    circle6: (props: TablerIconProps) => <IconCircle6 {...props} />,
    circle7: (props: TablerIconProps) => <IconCircle7 {...props} />,
    circle8: (props: TablerIconProps) => <IconCircle8 {...props} />,
    circle9: (props: TablerIconProps) => <IconCircle9 {...props} />,
    circlecheck: (props: TablerIconProps) => <IconCircleCheck {...props} />,
    circledashed: (props: TablerIconProps) => <IconCircleDashed {...props} />,
    circledotted: (props: TablerIconProps) => <IconCircleDotted {...props} />,
    circlehalfvertical: (props: TablerIconProps) => <IconCircleHalfVertical {...props} />,
    circlehalf: (props: TablerIconProps) => <IconCircleHalf {...props} />,
    circleminus: (props: TablerIconProps) => <IconCircleMinus {...props} />,
    circleoff: (props: TablerIconProps) => <IconCircleOff {...props} />,
    circleplus: (props: TablerIconProps) => <IconCirclePlus {...props} />,
    circlesquare: (props: TablerIconProps) => <IconCircleSquare {...props} />,
    circlex: (props: TablerIconProps) => <IconCircleX {...props} />,
    circle: (props: TablerIconProps) => <IconCircle {...props} />,
    circles: (props: TablerIconProps) => <IconCircles {...props} />,
    clearall: (props: TablerIconProps) => <IconClearAll {...props} />,
    clearformatting: (props: TablerIconProps) => <IconClearFormatting {...props} />,
    click: (props: TablerIconProps) => <IconClick {...props} />,
    clipboardcheck: (props: TablerIconProps) => <IconClipboardCheck {...props} />,
    clipboardlist: (props: TablerIconProps) => <IconClipboardList {...props} />,
    clipboardx: (props: TablerIconProps) => <IconClipboardX {...props} />,
    clipboard: (props: TablerIconProps) => <IconClipboard {...props} />,
    clock: (props: TablerIconProps) => <IconClock {...props} />,
    clouddownload: (props: TablerIconProps) => <IconCloudDownload {...props} />,
    cloudfog: (props: TablerIconProps) => <IconCloudFog {...props} />,
    cloudoff: (props: TablerIconProps) => <IconCloudOff {...props} />,
    cloudrain: (props: TablerIconProps) => <IconCloudRain {...props} />,
    cloudsnow: (props: TablerIconProps) => <IconCloudSnow {...props} />,
    cloudstorm: (props: TablerIconProps) => <IconCloudStorm {...props} />,
    cloudupload: (props: TablerIconProps) => <IconCloudUpload {...props} />,
    cloud: (props: TablerIconProps) => <IconCloud {...props} />,
    codeminus: (props: TablerIconProps) => <IconCodeMinus {...props} />,
    codeplus: (props: TablerIconProps) => <IconCodePlus {...props} />,
    code: (props: TablerIconProps) => <IconCode {...props} />,
    coffee: (props: TablerIconProps) => <IconCoffee {...props} />,
    coin: (props: TablerIconProps) => <IconCoin {...props} />,
    colorpicker: (props: TablerIconProps) => <IconColorPicker {...props} />,
    colorswatch: (props: TablerIconProps) => <IconColorSwatch {...props} />,
    columninsertleft: (props: TablerIconProps) => <IconColumnInsertLeft {...props} />,
    columninsertright: (props: TablerIconProps) => <IconColumnInsertRight {...props} />,
    columns: (props: TablerIconProps) => <IconColumns {...props} />,
    comet: (props: TablerIconProps) => <IconComet {...props} />,
    command: (props: TablerIconProps) => <IconCommand {...props} />,
    compass: (props: TablerIconProps) => <IconCompass {...props} />,
    confetti: (props: TablerIconProps) => <IconConfetti {...props} />,
    container: (props: TablerIconProps) => <IconContainer {...props} />,
    contrast: (props: TablerIconProps) => <IconContrast {...props} />,
    cookie: (props: TablerIconProps) => <IconCookie {...props} />,
    copy: (props: TablerIconProps) => <IconCopy {...props} />,
    copyleft: (props: TablerIconProps) => <IconCopyleft {...props} />,
    copyright: (props: TablerIconProps) => <IconCopyright {...props} />,
    cornerdownleftdouble: (props: TablerIconProps) => <IconCornerDownLeftDouble {...props} />,
    cornerdownleft: (props: TablerIconProps) => <IconCornerDownLeft {...props} />,
    cornerdownrightdouble: (props: TablerIconProps) => <IconCornerDownRightDouble {...props} />,
    cornerdownright: (props: TablerIconProps) => <IconCornerDownRight {...props} />,
    cornerleftdowndouble: (props: TablerIconProps) => <IconCornerLeftDownDouble {...props} />,
    cornerleftdown: (props: TablerIconProps) => <IconCornerLeftDown {...props} />,
    cornerleftupdouble: (props: TablerIconProps) => <IconCornerLeftUpDouble {...props} />,
    cornerleftup: (props: TablerIconProps) => <IconCornerLeftUp {...props} />,
    cornerrightdowndouble: (props: TablerIconProps) => <IconCornerRightDownDouble {...props} />,
    cornerrightdown: (props: TablerIconProps) => <IconCornerRightDown {...props} />,
    cornerrightupdouble: (props: TablerIconProps) => <IconCornerRightUpDouble {...props} />,
    cornerrightup: (props: TablerIconProps) => <IconCornerRightUp {...props} />,
    cornerupleftdouble: (props: TablerIconProps) => <IconCornerUpLeftDouble {...props} />,
    cornerupleft: (props: TablerIconProps) => <IconCornerUpLeft {...props} />,
    corneruprightdouble: (props: TablerIconProps) => <IconCornerUpRightDouble {...props} />,
    cornerupright: (props: TablerIconProps) => <IconCornerUpRight {...props} />,
    crane: (props: TablerIconProps) => <IconCrane {...props} />,
    creditcardoff: (props: TablerIconProps) => <IconCreditCardOff {...props} />,
    creditcard: (props: TablerIconProps) => <IconCreditCard {...props} />,
    crop: (props: TablerIconProps) => <IconCrop {...props} />,
    crosshair: (props: TablerIconProps) => <IconCrosshair {...props} />,
    crownoff: (props: TablerIconProps) => <IconCrownOff {...props} />,
    crown: (props: TablerIconProps) => <IconCrown {...props} />,
    cup: (props: TablerIconProps) => <IconCup {...props} />,
    curlyloop: (props: TablerIconProps) => <IconCurlyLoop {...props} />,
    currencybahraini: (props: TablerIconProps) => <IconCurrencyBahraini {...props} />,
    currencybath: (props: TablerIconProps) => <IconCurrencyBath {...props} />,
    currencybitcoin: (props: TablerIconProps) => <IconCurrencyBitcoin {...props} />,
    currencycent: (props: TablerIconProps) => <IconCurrencyCent {...props} />,
    currencydinar: (props: TablerIconProps) => <IconCurrencyDinar {...props} />,
    currencydirham: (props: TablerIconProps) => <IconCurrencyDirham {...props} />,
    currencydollaraustralian: (props: TablerIconProps) => <IconCurrencyDollarAustralian {...props} />,
    currencydollarcanadian: (props: TablerIconProps) => <IconCurrencyDollarCanadian {...props} />,
    currencydollarsingapore: (props: TablerIconProps) => <IconCurrencyDollarSingapore {...props} />,
    currencydollar: (props: TablerIconProps) => <IconCurrencyDollar {...props} />,
    currencyethereum: (props: TablerIconProps) => <IconCurrencyEthereum {...props} />,
    currencyeuro: (props: TablerIconProps) => <IconCurrencyEuro {...props} />,
    currencyforint: (props: TablerIconProps) => <IconCurrencyForint {...props} />,
    currencyfrank: (props: TablerIconProps) => <IconCurrencyFrank {...props} />,
    currencykroneczech: (props: TablerIconProps) => <IconCurrencyKroneCzech {...props} />,
    currencykronedanish: (props: TablerIconProps) => <IconCurrencyKroneDanish {...props} />,
    currencykroneswedish: (props: TablerIconProps) => <IconCurrencyKroneSwedish {...props} />,
    currencyleu: (props: TablerIconProps) => <IconCurrencyLeu {...props} />,
    currencylira: (props: TablerIconProps) => <IconCurrencyLira {...props} />,
    currencylitecoin: (props: TablerIconProps) => <IconCurrencyLitecoin {...props} />,
    currencynaira: (props: TablerIconProps) => <IconCurrencyNaira {...props} />,
    currencypound: (props: TablerIconProps) => <IconCurrencyPound {...props} />,
    currencyreal: (props: TablerIconProps) => <IconCurrencyReal {...props} />,
    currencyrenminbi: (props: TablerIconProps) => <IconCurrencyRenminbi {...props} />,
    currencyripple: (props: TablerIconProps) => <IconCurrencyRipple {...props} />,
    currencyriyal: (props: TablerIconProps) => <IconCurrencyRiyal {...props} />,
    currencyrubel: (props: TablerIconProps) => <IconCurrencyRubel {...props} />,
    currencyrupee: (props: TablerIconProps) => <IconCurrencyRupee {...props} />,
    currencyshekel: (props: TablerIconProps) => <IconCurrencyShekel {...props} />,
    currencytaka: (props: TablerIconProps) => <IconCurrencyTaka {...props} />,
    currencytugrik: (props: TablerIconProps) => <IconCurrencyTugrik {...props} />,
    currencywon: (props: TablerIconProps) => <IconCurrencyWon {...props} />,
    currencyyen: (props: TablerIconProps) => <IconCurrencyYen {...props} />,
    currencyzloty: (props: TablerIconProps) => <IconCurrencyZloty {...props} />,
    currentlocation: (props: TablerIconProps) => <IconCurrentLocation {...props} />,
    cursortext: (props: TablerIconProps) => <IconCursorText {...props} />,
    cut: (props: TablerIconProps) => <IconCut {...props} />,
    dashboard: (props: TablerIconProps) => <IconDashboard {...props} />,
    databaseexport: (props: TablerIconProps) => <IconDatabaseExport {...props} />,
    databaseimport: (props: TablerIconProps) => <IconDatabaseImport {...props} />,
    databaseoff: (props: TablerIconProps) => <IconDatabaseOff {...props} />,
    database: (props: TablerIconProps) => <IconDatabase {...props} />,
    details: (props: TablerIconProps) => <IconDetails {...props} />,
    deviceanalytics: (props: TablerIconProps) => <IconDeviceAnalytics {...props} />,
    deviceaudiotape: (props: TablerIconProps) => <IconDeviceAudioTape {...props} />,
    devicecctv: (props: TablerIconProps) => <IconDeviceCctv {...props} />,
    devicecomputercameraoff: (props: TablerIconProps) => <IconDeviceComputerCameraOff {...props} />,
    devicecomputercamera: (props: TablerIconProps) => <IconDeviceComputerCamera {...props} />,
    devicedesktopanalytics: (props: TablerIconProps) => <IconDeviceDesktopAnalytics {...props} />,
    devicedesktopoff: (props: TablerIconProps) => <IconDeviceDesktopOff {...props} />,
    devicedesktop: (props: TablerIconProps) => <IconDeviceDesktop {...props} />,
    devicefloppy: (props: TablerIconProps) => <IconDeviceFloppy {...props} />,
    devicegamepad: (props: TablerIconProps) => <IconDeviceGamepad {...props} />,
    devicelaptop: (props: TablerIconProps) => <IconDeviceLaptop {...props} />,
    devicemobilemessage: (props: TablerIconProps) => <IconDeviceMobileMessage {...props} />,
    devicemobilerotated: (props: TablerIconProps) => <IconDeviceMobileRotated {...props} />,
    devicemobilevibration: (props: TablerIconProps) => <IconDeviceMobileVibration {...props} />,
    devicemobile: (props: TablerIconProps) => <IconDeviceMobile {...props} />,
    devicespeaker: (props: TablerIconProps) => <IconDeviceSpeaker {...props} />,
    devicetablet: (props: TablerIconProps) => <IconDeviceTablet {...props} />,
    devicetv: (props: TablerIconProps) => <IconDeviceTv {...props} />,
    devicewatch: (props: TablerIconProps) => <IconDeviceWatch {...props} />,
    devices2: (props: TablerIconProps) => <IconDevices2 {...props} />,
    devicespc: (props: TablerIconProps) => <IconDevicesPc {...props} />,
    devices: (props: TablerIconProps) => <IconDevices {...props} />,
    diamond: (props: TablerIconProps) => <IconDiamond {...props} />,
    dice: (props: TablerIconProps) => <IconDice {...props} />,
    dimensions: (props: TablerIconProps) => <IconDimensions {...props} />,
    directionhorizontal: (props: TablerIconProps) => <IconDirectionHorizontal {...props} />,
    direction: (props: TablerIconProps) => <IconDirection {...props} />,
    directions: (props: TablerIconProps) => <IconDirections {...props} />,
    disabled2: (props: TablerIconProps) => <IconDisabled2 {...props} />,
    disabled: (props: TablerIconProps) => <IconDisabled {...props} />,
    disc: (props: TablerIconProps) => <IconDisc {...props} />,
    discount2: (props: TablerIconProps) => <IconDiscount2 {...props} />,
    discount: (props: TablerIconProps) => <IconDiscount {...props} />,
    divide: (props: TablerIconProps) => <IconDivide {...props} />,
    dna: (props: TablerIconProps) => <IconDna {...props} />,
    dogbowl: (props: TablerIconProps) => <IconDogBowl {...props} />,
    dotscirclehorizontal: (props: TablerIconProps) => <IconDotsCircleHorizontal {...props} />,
    dotsdiagonal2: (props: TablerIconProps) => <IconDotsDiagonal2 {...props} />,
    dotsdiagonal: (props: TablerIconProps) => <IconDotsDiagonal {...props} />,
    dotsvertical: (props: TablerIconProps) => <IconDotsVertical {...props} />,
    dots: (props: TablerIconProps) => <IconDots {...props} />,
    download: (props: TablerIconProps) => <IconDownload {...props} />,
    dragdrop2: (props: TablerIconProps) => <IconDragDrop2 {...props} />,
    dragdrop: (props: TablerIconProps) => <IconDragDrop {...props} />,
    droneoff: (props: TablerIconProps) => <IconDroneOff {...props} />,
    drone: (props: TablerIconProps) => <IconDrone {...props} />,
    dropletfilled2: (props: TablerIconProps) => <IconDropletFilled2 {...props} />,
    dropletfilled: (props: TablerIconProps) => <IconDropletFilled {...props} />,
    droplethalf2: (props: TablerIconProps) => <IconDropletHalf2 {...props} />,
    droplethalf: (props: TablerIconProps) => <IconDropletHalf {...props} />,
    dropletoff: (props: TablerIconProps) => <IconDropletOff {...props} />,
    droplet: (props: TablerIconProps) => <IconDroplet {...props} />,
    earoff: (props: TablerIconProps) => <IconEarOff {...props} />,
    ear: (props: TablerIconProps) => <IconEar {...props} />,
    editcircle: (props: TablerIconProps) => <IconEditCircle {...props} />,
    edit: (props: TablerIconProps) => <IconEdit {...props} />,
    egg: (props: TablerIconProps) => <IconEgg {...props} />,
    emphasis: (props: TablerIconProps) => <IconEmphasis {...props} />,
    equalnot: (props: TablerIconProps) => <IconEqualNot {...props} />,
    equal: (props: TablerIconProps) => <IconEqual {...props} />,
    eraser: (props: TablerIconProps) => <IconEraser {...props} />,
    exchange: (props: TablerIconProps) => <IconExchange {...props} />,
    exposure: (props: TablerIconProps) => <IconExposure {...props} />,
    externallink: (props: TablerIconProps) => <IconExternalLink {...props} />,
    eyecheck: (props: TablerIconProps) => <IconEyeCheck {...props} />,
    eyeoff: (props: TablerIconProps) => <IconEyeOff {...props} />,
    eye: (props: TablerIconProps) => <IconEye {...props} />,
    eyeglass2: (props: TablerIconProps) => <IconEyeglass2 {...props} />,
    eyeglass: (props: TablerIconProps) => <IconEyeglass {...props} />,
    faceid: (props: TablerIconProps) => <IconFaceId {...props} />,
    fall: (props: TablerIconProps) => <IconFall {...props} />,
    feather: (props: TablerIconProps) => <IconFeather {...props} />,
    fence: (props: TablerIconProps) => <IconFence {...props} />,
    filealert: (props: TablerIconProps) => <IconFileAlert {...props} />,
    fileanalytics: (props: TablerIconProps) => <IconFileAnalytics {...props} />,
    filecertificate: (props: TablerIconProps) => <IconFileCertificate {...props} />,
    filecheck: (props: TablerIconProps) => <IconFileCheck {...props} />,
    filecode2: (props: TablerIconProps) => <IconFileCode2 {...props} />,
    filecode: (props: TablerIconProps) => <IconFileCode {...props} />,
    filediff: (props: TablerIconProps) => <IconFileDiff {...props} />,
    filedislike: (props: TablerIconProps) => <IconFileDislike {...props} />,
    filedownload: (props: TablerIconProps) => <IconFileDownload {...props} />,
    fileexport: (props: TablerIconProps) => <IconFileExport {...props} />,
    filehorizontal: (props: TablerIconProps) => <IconFileHorizontal {...props} />,
    fileimport: (props: TablerIconProps) => <IconFileImport {...props} />,
    fileinfo: (props: TablerIconProps) => <IconFileInfo {...props} />,
    fileinvoice: (props: TablerIconProps) => <IconFileInvoice {...props} />,
    filelike: (props: TablerIconProps) => <IconFileLike {...props} />,
    fileminus: (props: TablerIconProps) => <IconFileMinus {...props} />,
    filemusic: (props: TablerIconProps) => <IconFileMusic {...props} />,
    fileoff: (props: TablerIconProps) => <IconFileOff {...props} />,
    filephone: (props: TablerIconProps) => <IconFilePhone {...props} />,
    fileplus: (props: TablerIconProps) => <IconFilePlus {...props} />,
    filereport: (props: TablerIconProps) => <IconFileReport {...props} />,
    filesearch: (props: TablerIconProps) => <IconFileSearch {...props} />,
    fileshredder: (props: TablerIconProps) => <IconFileShredder {...props} />,
    filesymlink: (props: TablerIconProps) => <IconFileSymlink {...props} />,
    filetext: (props: TablerIconProps) => <IconFileText {...props} />,
    fileupload: (props: TablerIconProps) => <IconFileUpload {...props} />,
    filex: (props: TablerIconProps) => <IconFileX {...props} />,
    filezip: (props: TablerIconProps) => <IconFileZip {...props} />,
    file: (props: TablerIconProps) => <IconFile {...props} />,
    filesoff: (props: TablerIconProps) => <IconFilesOff {...props} />,
    files: (props: TablerIconProps) => <IconFiles {...props} />,
    filteroff: (props: TablerIconProps) => <IconFilterOff {...props} />,
    filter: (props: TablerIconProps) => <IconFilter {...props} />,
    fingerprint: (props: TablerIconProps) => <IconFingerprint {...props} />,
    firetruck: (props: TablerIconProps) => <IconFiretruck {...props} />,
    fish: (props: TablerIconProps) => <IconFish {...props} />,
    flag2: (props: TablerIconProps) => <IconFlag2 {...props} />,
    flag3: (props: TablerIconProps) => <IconFlag3 {...props} />,
    flag: (props: TablerIconProps) => <IconFlag {...props} />,
    flame: (props: TablerIconProps) => <IconFlame {...props} />,
    flare: (props: TablerIconProps) => <IconFlare {...props} />,
    flask: (props: TablerIconProps) => <IconFlask {...props} />,
    fliphorizontal: (props: TablerIconProps) => <IconFlipHorizontal {...props} />,
    flipvertical: (props: TablerIconProps) => <IconFlipVertical {...props} />,
    floatcenter: (props: TablerIconProps) => <IconFloatCenter {...props} />,
    floatleft: (props: TablerIconProps) => <IconFloatLeft {...props} />,
    floatnone: (props: TablerIconProps) => <IconFloatNone {...props} />,
    floatright: (props: TablerIconProps) => <IconFloatRight {...props} />,
    focus2: (props: TablerIconProps) => <IconFocus2 {...props} />,
    focus: (props: TablerIconProps) => <IconFocus {...props} />,
    folddown: (props: TablerIconProps) => <IconFoldDown {...props} />,
    foldup: (props: TablerIconProps) => <IconFoldUp {...props} />,
    fold: (props: TablerIconProps) => <IconFold {...props} />,
    folderminus: (props: TablerIconProps) => <IconFolderMinus {...props} />,
    folderoff: (props: TablerIconProps) => <IconFolderOff {...props} />,
    folderplus: (props: TablerIconProps) => <IconFolderPlus {...props} />,
    folderx: (props: TablerIconProps) => <IconFolderX {...props} />,
    folder: (props: TablerIconProps) => <IconFolder {...props} />,
    folders: (props: TablerIconProps) => <IconFolders {...props} />,
    forbid2: (props: TablerIconProps) => <IconForbid2 {...props} />,
    forbid: (props: TablerIconProps) => <IconForbid {...props} />,
    forklift: (props: TablerIconProps) => <IconForklift {...props} />,
    forms: (props: TablerIconProps) => <IconForms {...props} />,
    frame: (props: TablerIconProps) => <IconFrame {...props} />,
    friends: (props: TablerIconProps) => <IconFriends {...props} />,
    gasstation: (props: TablerIconProps) => <IconGasStation {...props} />,
    gauge: (props: TablerIconProps) => <IconGauge {...props} />,
    geometry: (props: TablerIconProps) => <IconGeometry {...props} />,
    ghost: (props: TablerIconProps) => <IconGhost {...props} />,
    gift: (props: TablerIconProps) => <IconGift {...props} />,
    gitbranch: (props: TablerIconProps) => <IconGitBranch {...props} />,
    gitcommit: (props: TablerIconProps) => <IconGitCommit {...props} />,
    gitcompare: (props: TablerIconProps) => <IconGitCompare {...props} />,
    gitfork: (props: TablerIconProps) => <IconGitFork {...props} />,
    gitmerge: (props: TablerIconProps) => <IconGitMerge {...props} />,
    gitpullrequest: (props: TablerIconProps) => <IconGitPullRequest {...props} />,
    glassfull: (props: TablerIconProps) => <IconGlassFull {...props} />,
    glassoff: (props: TablerIconProps) => <IconGlassOff {...props} />,
    glass: (props: TablerIconProps) => <IconGlass {...props} />,
    globe: (props: TablerIconProps) => <IconGlobe {...props} />,
    golf: (props: TablerIconProps) => <IconGolf {...props} />,
    gps: (props: TablerIconProps) => <IconGps {...props} />,
    grain: (props: TablerIconProps) => <IconGrain {...props} />,
    griddots: (props: TablerIconProps) => <IconGridDots {...props} />,
    griphorizontal: (props: TablerIconProps) => <IconGripHorizontal {...props} />,
    gripvertical: (props: TablerIconProps) => <IconGripVertical {...props} />,
    growth: (props: TablerIconProps) => <IconGrowth {...props} />,
    h1: (props: TablerIconProps) => <IconH1 {...props} />,
    h2: (props: TablerIconProps) => <IconH2 {...props} />,
    h3: (props: TablerIconProps) => <IconH3 {...props} />,
    h4: (props: TablerIconProps) => <IconH4 {...props} />,
    h5: (props: TablerIconProps) => <IconH5 {...props} />,
    h6: (props: TablerIconProps) => <IconH6 {...props} />,
    handfinger: (props: TablerIconProps) => <IconHandFinger {...props} />,
    handlittlefinger: (props: TablerIconProps) => <IconHandLittleFinger {...props} />,
    handmiddlefinger: (props: TablerIconProps) => <IconHandMiddleFinger {...props} />,
    handoff: (props: TablerIconProps) => <IconHandOff {...props} />,
    handringfinger: (props: TablerIconProps) => <IconHandRingFinger {...props} />,
    handrock: (props: TablerIconProps) => <IconHandRock {...props} />,
    handstop: (props: TablerIconProps) => <IconHandStop {...props} />,
    handthreefingers: (props: TablerIconProps) => <IconHandThreeFingers {...props} />,
    handtwofingers: (props: TablerIconProps) => <IconHandTwoFingers {...props} />,
    hanger: (props: TablerIconProps) => <IconHanger {...props} />,
    hash: (props: TablerIconProps) => <IconHash {...props} />,
    heading: (props: TablerIconProps) => <IconHeading {...props} />,
    headphonesoff: (props: TablerIconProps) => <IconHeadphonesOff {...props} />,
    headphones: (props: TablerIconProps) => <IconHeadphones {...props} />,
    headset: (props: TablerIconProps) => <IconHeadset {...props} />,
    heartbroken: (props: TablerIconProps) => <IconHeartBroken {...props} />,
    heart: (props: TablerIconProps) => <IconHeart {...props} />,
    helicopterlanding: (props: TablerIconProps) => <IconHelicopterLanding {...props} />,
    helicopter: (props: TablerIconProps) => <IconHelicopter {...props} />,
    help: (props: TablerIconProps) => <IconHelp {...props} />,
    hexagonoff: (props: TablerIconProps) => <IconHexagonOff {...props} />,
    hexagon: (props: TablerIconProps) => <IconHexagon {...props} />,
    hierarchy2: (props: TablerIconProps) => <IconHierarchy2 {...props} />,
    hierarchy: (props: TablerIconProps) => <IconHierarchy {...props} />,
    history: (props: TablerIconProps) => <IconHistory {...props} />,
    home2: (props: TablerIconProps) => <IconHome2 {...props} />,
    home: (props: TablerIconProps) => <IconHome {...props} />,
    icecream2: (props: TablerIconProps) => <IconIceCream2 {...props} />,
    icecream: (props: TablerIconProps) => <IconIceCream {...props} />,
    id: (props: TablerIconProps) => <IconId {...props} />,
    inbox: (props: TablerIconProps) => <IconInbox {...props} />,
    indentdecrease: (props: TablerIconProps) => <IconIndentDecrease {...props} />,
    indentincrease: (props: TablerIconProps) => <IconIndentIncrease {...props} />,
    infinity: (props: TablerIconProps) => <IconInfinity {...props} />,
    infocircle: (props: TablerIconProps) => <IconInfoCircle {...props} />,
    infosquare: (props: TablerIconProps) => <IconInfoSquare {...props} />,
    italic: (props: TablerIconProps) => <IconItalic {...props} />,
    jumprope: (props: TablerIconProps) => <IconJumpRope {...props} />,
    karate: (props: TablerIconProps) => <IconKarate {...props} />,
    key: (props: TablerIconProps) => <IconKey {...props} />,
    keyboardhide: (props: TablerIconProps) => <IconKeyboardHide {...props} />,
    keyboardoff: (props: TablerIconProps) => <IconKeyboardOff {...props} />,
    keyboardshow: (props: TablerIconProps) => <IconKeyboardShow {...props} />,
    keyboard: (props: TablerIconProps) => <IconKeyboard {...props} />,
    language: (props: TablerIconProps) => <IconLanguage {...props} />,
    layersdifference: (props: TablerIconProps) => <IconLayersDifference {...props} />,
    layersintersect: (props: TablerIconProps) => <IconLayersIntersect {...props} />,
    layerslinked: (props: TablerIconProps) => <IconLayersLinked {...props} />,
    layerssubtract: (props: TablerIconProps) => <IconLayersSubtract {...props} />,
    layersunion: (props: TablerIconProps) => <IconLayersUnion {...props} />,
    layout2: (props: TablerIconProps) => <IconLayout2 {...props} />,
    layoutalignbottom: (props: TablerIconProps) => <IconLayoutAlignBottom {...props} />,
    layoutaligncenter: (props: TablerIconProps) => <IconLayoutAlignCenter {...props} />,
    layoutalignleft: (props: TablerIconProps) => <IconLayoutAlignLeft {...props} />,
    layoutalignmiddle: (props: TablerIconProps) => <IconLayoutAlignMiddle {...props} />,
    layoutalignright: (props: TablerIconProps) => <IconLayoutAlignRight {...props} />,
    layoutaligntop: (props: TablerIconProps) => <IconLayoutAlignTop {...props} />,
    layoutbottombar: (props: TablerIconProps) => <IconLayoutBottombar {...props} />,
    layoutcards: (props: TablerIconProps) => <IconLayoutCards {...props} />,
    layoutcolumns: (props: TablerIconProps) => <IconLayoutColumns {...props} />,
    layoutdistributehorizontal: (props: TablerIconProps) => <IconLayoutDistributeHorizontal {...props} />,
    layoutdistributevertical: (props: TablerIconProps) => <IconLayoutDistributeVertical {...props} />,
    layoutgridadd: (props: TablerIconProps) => <IconLayoutGridAdd {...props} />,
    layoutgrid: (props: TablerIconProps) => <IconLayoutGrid {...props} />,
    layoutkanban: (props: TablerIconProps) => <IconLayoutKanban {...props} />,
    layoutlist: (props: TablerIconProps) => <IconLayoutList {...props} />,
    layoutnavbar: (props: TablerIconProps) => <IconLayoutNavbar {...props} />,
    layoutrows: (props: TablerIconProps) => <IconLayoutRows {...props} />,
    layoutsidebarright: (props: TablerIconProps) => <IconLayoutSidebarRight {...props} />,
    layoutsidebar: (props: TablerIconProps) => <IconLayoutSidebar {...props} />,
    layout: (props: TablerIconProps) => <IconLayout {...props} />,
    leaf: (props: TablerIconProps) => <IconLeaf {...props} />,
    lego: (props: TablerIconProps) => <IconLego {...props} />,
    lemon: (props: TablerIconProps) => <IconLemon {...props} />,
    lettera: (props: TablerIconProps) => <IconLetterA {...props} />,
    letterb: (props: TablerIconProps) => <IconLetterB {...props} />,
    letterc: (props: TablerIconProps) => <IconLetterC {...props} />,
    lettercaselower: (props: TablerIconProps) => <IconLetterCaseLower {...props} />,
    lettercasetoggle: (props: TablerIconProps) => <IconLetterCaseToggle {...props} />,
    lettercaseupper: (props: TablerIconProps) => <IconLetterCaseUpper {...props} />,
    lettercase: (props: TablerIconProps) => <IconLetterCase {...props} />,
    letterd: (props: TablerIconProps) => <IconLetterD {...props} />,
    lettere: (props: TablerIconProps) => <IconLetterE {...props} />,
    letterf: (props: TablerIconProps) => <IconLetterF {...props} />,
    letterg: (props: TablerIconProps) => <IconLetterG {...props} />,
    letterh: (props: TablerIconProps) => <IconLetterH {...props} />,
    letteri: (props: TablerIconProps) => <IconLetterI {...props} />,
    letterj: (props: TablerIconProps) => <IconLetterJ {...props} />,
    letterk: (props: TablerIconProps) => <IconLetterK {...props} />,
    letterl: (props: TablerIconProps) => <IconLetterL {...props} />,
    letterm: (props: TablerIconProps) => <IconLetterM {...props} />,
    lettern: (props: TablerIconProps) => <IconLetterN {...props} />,
    lettero: (props: TablerIconProps) => <IconLetterO {...props} />,
    letterp: (props: TablerIconProps) => <IconLetterP {...props} />,
    letterq: (props: TablerIconProps) => <IconLetterQ {...props} />,
    letterr: (props: TablerIconProps) => <IconLetterR {...props} />,
    letters: (props: TablerIconProps) => <IconLetterS {...props} />,
    letterspacing: (props: TablerIconProps) => <IconLetterSpacing {...props} />,
    lettert: (props: TablerIconProps) => <IconLetterT {...props} />,
    letteru: (props: TablerIconProps) => <IconLetterU {...props} />,
    letterv: (props: TablerIconProps) => <IconLetterV {...props} />,
    letterw: (props: TablerIconProps) => <IconLetterW {...props} />,
    letterx: (props: TablerIconProps) => <IconLetterX {...props} />,
    lettery: (props: TablerIconProps) => <IconLetterY {...props} />,
    letterz: (props: TablerIconProps) => <IconLetterZ {...props} />,
    letterscase: (props: TablerIconProps) => <IconLettersCase {...props} />,
    license: (props: TablerIconProps) => <IconLicense {...props} />,
    lifebuoy: (props: TablerIconProps) => <IconLifebuoy {...props} />,
    linedashed: (props: TablerIconProps) => <IconLineDashed {...props} />,
    linedotted: (props: TablerIconProps) => <IconLineDotted {...props} />,
    lineheight: (props: TablerIconProps) => <IconLineHeight {...props} />,
    line: (props: TablerIconProps) => <IconLine {...props} />,
    link: (props: TablerIconProps) => <IconLink {...props} />,
    listcheck: (props: TablerIconProps) => <IconListCheck {...props} />,
    listnumbers: (props: TablerIconProps) => <IconListNumbers {...props} />,
    listsearch: (props: TablerIconProps) => <IconListSearch {...props} />,
    list: (props: TablerIconProps) => <IconList {...props} />,
    livephoto: (props: TablerIconProps) => <IconLivePhoto {...props} />,
    liveview: (props: TablerIconProps) => <IconLiveView {...props} />,
    loaderquarter: (props: TablerIconProps) => <IconLoaderQuarter {...props} />,
    loader: (props: TablerIconProps) => <IconLoader {...props} />,
    location: (props: TablerIconProps) => <IconLocation {...props} />,
    lockaccess: (props: TablerIconProps) => <IconLockAccess {...props} />,
    lockoff: (props: TablerIconProps) => <IconLockOff {...props} />,
    lockopen: (props: TablerIconProps) => <IconLockOpen {...props} />,
    lock: (props: TablerIconProps) => <IconLock {...props} />,
    login: (props: TablerIconProps) => <IconLogin {...props} />,
    logout: (props: TablerIconProps) => <IconLogout {...props} />,
    macro: (props: TablerIconProps) => <IconMacro {...props} />,
    magnet: (props: TablerIconProps) => <IconMagnet {...props} />,
    mailforward: (props: TablerIconProps) => <IconMailForward {...props} />,
    mailopened: (props: TablerIconProps) => <IconMailOpened {...props} />,
    mail: (props: TablerIconProps) => <IconMail {...props} />,
    mailbox: (props: TablerIconProps) => <IconMailbox {...props} />,
    man: (props: TablerIconProps) => <IconMan {...props} />,
    manualgearbox: (props: TablerIconProps) => <IconManualGearbox {...props} />,
    map2: (props: TablerIconProps) => <IconMap2 {...props} />,
    mappinoff: (props: TablerIconProps) => <IconMapPinOff {...props} />,
    mappin: (props: TablerIconProps) => <IconMapPin {...props} />,
    mappins: (props: TablerIconProps) => <IconMapPins {...props} />,
    map: (props: TablerIconProps) => <IconMap {...props} />,
    markdown: (props: TablerIconProps) => <IconMarkdown {...props} />,
    marquee2: (props: TablerIconProps) => <IconMarquee2 {...props} />,
    marquee: (props: TablerIconProps) => <IconMarquee {...props} />,
    mars: (props: TablerIconProps) => <IconMars {...props} />,
    maskoff: (props: TablerIconProps) => <IconMaskOff {...props} />,
    mask: (props: TablerIconProps) => <IconMask {...props} />,
    massage: (props: TablerIconProps) => <IconMassage {...props} />,
    mathfunction: (props: TablerIconProps) => <IconMathFunction {...props} />,
    mathsymbols: (props: TablerIconProps) => <IconMathSymbols {...props} />,
    math: (props: TablerIconProps) => <IconMath {...props} />,
    maximize: (props: TablerIconProps) => <IconMaximize {...props} />,
    meat: (props: TablerIconProps) => <IconMeat {...props} />,
    medal: (props: TablerIconProps) => <IconMedal {...props} />,
    medicalcross: (props: TablerIconProps) => <IconMedicalCross {...props} />,
    menu2: (props: TablerIconProps) => <IconMenu2 {...props} />,
    menu: (props: TablerIconProps) => <IconMenu {...props} />,
    message2: (props: TablerIconProps) => <IconMessage2 {...props} />,
    messagecircle2: (props: TablerIconProps) => <IconMessageCircle2 {...props} />,
    messagecircleoff: (props: TablerIconProps) => <IconMessageCircleOff {...props} />,
    messagecircle: (props: TablerIconProps) => <IconMessageCircle {...props} />,
    messagedots: (props: TablerIconProps) => <IconMessageDots {...props} />,
    messageoff: (props: TablerIconProps) => <IconMessageOff {...props} />,
    messageplus: (props: TablerIconProps) => <IconMessagePlus {...props} />,
    messagereport: (props: TablerIconProps) => <IconMessageReport {...props} />,
    message: (props: TablerIconProps) => <IconMessage {...props} />,
    messagesoff: (props: TablerIconProps) => <IconMessagesOff {...props} />,
    messages: (props: TablerIconProps) => <IconMessages {...props} />,
    microphone2: (props: TablerIconProps) => <IconMicrophone2 {...props} />,
    microphoneoff: (props: TablerIconProps) => <IconMicrophoneOff {...props} />,
    microphone: (props: TablerIconProps) => <IconMicrophone {...props} />,
    milk: (props: TablerIconProps) => <IconMilk {...props} />,
    minimize: (props: TablerIconProps) => <IconMinimize {...props} />,
    minusvertical: (props: TablerIconProps) => <IconMinusVertical {...props} />,
    minus: (props: TablerIconProps) => <IconMinus {...props} />,
    mist: (props: TablerIconProps) => <IconMist {...props} />,
    moodboy: (props: TablerIconProps) => <IconMoodBoy {...props} />,
    moodconfuzed: (props: TablerIconProps) => <IconMoodConfuzed {...props} />,
    moodcrazyhappy: (props: TablerIconProps) => <IconMoodCrazyHappy {...props} />,
    moodcry: (props: TablerIconProps) => <IconMoodCry {...props} />,
    moodempty: (props: TablerIconProps) => <IconMoodEmpty {...props} />,
    moodhappy: (props: TablerIconProps) => <IconMoodHappy {...props} />,
    moodkid: (props: TablerIconProps) => <IconMoodKid {...props} />,
    moodneutral: (props: TablerIconProps) => <IconMoodNeutral {...props} />,
    moodsad: (props: TablerIconProps) => <IconMoodSad {...props} />,
    moodsmile: (props: TablerIconProps) => <IconMoodSmile {...props} />,
    moodsuprised: (props: TablerIconProps) => <IconMoodSuprised {...props} />,
    moodtongue: (props: TablerIconProps) => <IconMoodTongue {...props} />,
    moon2: (props: TablerIconProps) => <IconMoon2 {...props} />,
    moonstars: (props: TablerIconProps) => <IconMoonStars {...props} />,
    moon: (props: TablerIconProps) => <IconMoon {...props} />,
    moped: (props: TablerIconProps) => <IconMoped {...props} />,
    motorbike: (props: TablerIconProps) => <IconMotorbike {...props} />,
    mouse: (props: TablerIconProps) => <IconMouse {...props} />,
    movie: (props: TablerIconProps) => <IconMovie {...props} />,
    mug: (props: TablerIconProps) => <IconMug {...props} />,
    mushroom: (props: TablerIconProps) => <IconMushroom {...props} />,
    music: (props: TablerIconProps) => <IconMusic {...props} />,
    newsection: (props: TablerIconProps) => <IconNewSection {...props} />,
    news: (props: TablerIconProps) => <IconNews {...props} />,
    nfc: (props: TablerIconProps) => <IconNfc {...props} />,
    note: (props: TablerIconProps) => <IconNote {...props} />,
    notebook: (props: TablerIconProps) => <IconNotebook {...props} />,
    notes: (props: TablerIconProps) => <IconNotes {...props} />,
    notification: (props: TablerIconProps) => <IconNotification {...props} />,
    number0: (props: TablerIconProps) => <IconNumber0 {...props} />,
    number1: (props: TablerIconProps) => <IconNumber1 {...props} />,
    number2: (props: TablerIconProps) => <IconNumber2 {...props} />,
    number3: (props: TablerIconProps) => <IconNumber3 {...props} />,
    number4: (props: TablerIconProps) => <IconNumber4 {...props} />,
    number5: (props: TablerIconProps) => <IconNumber5 {...props} />,
    number6: (props: TablerIconProps) => <IconNumber6 {...props} />,
    number7: (props: TablerIconProps) => <IconNumber7 {...props} />,
    number8: (props: TablerIconProps) => <IconNumber8 {...props} />,
    number9: (props: TablerIconProps) => <IconNumber9 {...props} />,
    octagonoff: (props: TablerIconProps) => <IconOctagonOff {...props} />,
    octagon: (props: TablerIconProps) => <IconOctagon {...props} />,
    old: (props: TablerIconProps) => <IconOld {...props} />,
    olympics: (props: TablerIconProps) => <IconOlympics {...props} />,
    omega: (props: TablerIconProps) => <IconOmega {...props} />,
    outlet: (props: TablerIconProps) => <IconOutlet {...props} />,
    overline: (props: TablerIconProps) => <IconOverline {...props} />,
    package: (props: TablerIconProps) => <IconPackage {...props} />,
    pacman: (props: TablerIconProps) => <IconPacman {...props} />,
    pagebreak: (props: TablerIconProps) => <IconPageBreak {...props} />,
    paint: (props: TablerIconProps) => <IconPaint {...props} />,
    palette: (props: TablerIconProps) => <IconPalette {...props} />,
    panoramahorizontal: (props: TablerIconProps) => <IconPanoramaHorizontal {...props} />,
    panoramavertical: (props: TablerIconProps) => <IconPanoramaVertical {...props} />,
    paperclip: (props: TablerIconProps) => <IconPaperclip {...props} />,
    parachute: (props: TablerIconProps) => <IconParachute {...props} />,
    parentheses: (props: TablerIconProps) => <IconParentheses {...props} />,
    parking: (props: TablerIconProps) => <IconParking {...props} />,
    peace: (props: TablerIconProps) => <IconPeace {...props} />,
    pencil: (props: TablerIconProps) => <IconPencil {...props} />,
    pennant: (props: TablerIconProps) => <IconPennant {...props} />,
    pepper: (props: TablerIconProps) => <IconPepper {...props} />,
    percentage: (props: TablerIconProps) => <IconPercentage {...props} />,
    perspective: (props: TablerIconProps) => <IconPerspective {...props} />,
    phonecall: (props: TablerIconProps) => <IconPhoneCall {...props} />,
    phonecalling: (props: TablerIconProps) => <IconPhoneCalling {...props} />,
    phonecheck: (props: TablerIconProps) => <IconPhoneCheck {...props} />,
    phoneincoming: (props: TablerIconProps) => <IconPhoneIncoming {...props} />,
    phoneoff: (props: TablerIconProps) => <IconPhoneOff {...props} />,
    phoneoutgoing: (props: TablerIconProps) => <IconPhoneOutgoing {...props} />,
    phonepause: (props: TablerIconProps) => <IconPhonePause {...props} />,
    phoneplus: (props: TablerIconProps) => <IconPhonePlus {...props} />,
    phonex: (props: TablerIconProps) => <IconPhoneX {...props} />,
    phone: (props: TablerIconProps) => <IconPhone {...props} />,
    photooff: (props: TablerIconProps) => <IconPhotoOff {...props} />,
    photo: (props: TablerIconProps) => <IconPhoto {...props} />,
    physotherapist: (props: TablerIconProps) => <IconPhysotherapist {...props} />,
    pictureinpictureoff: (props: TablerIconProps) => <IconPictureInPictureOff {...props} />,
    pictureinpictureon: (props: TablerIconProps) => <IconPictureInPictureOn {...props} />,
    pictureinpicture: (props: TablerIconProps) => <IconPictureInPicture {...props} />,
    pill: (props: TablerIconProps) => <IconPill {...props} />,
    pin: (props: TablerIconProps) => <IconPin {...props} />,
    pinnedoff: (props: TablerIconProps) => <IconPinnedOff {...props} />,
    pinned: (props: TablerIconProps) => <IconPinned {...props} />,
    pizza: (props: TablerIconProps) => <IconPizza {...props} />,
    planearrival: (props: TablerIconProps) => <IconPlaneArrival {...props} />,
    planedeparture: (props: TablerIconProps) => <IconPlaneDeparture {...props} />,
    plane: (props: TablerIconProps) => <IconPlane {...props} />,
    planet: (props: TablerIconProps) => <IconPlanet {...props} />,
    plant2: (props: TablerIconProps) => <IconPlant2 {...props} />,
    plant: (props: TablerIconProps) => <IconPlant {...props} />,
    playcard: (props: TablerIconProps) => <IconPlayCard {...props} />,
    playerpause: (props: TablerIconProps) => <IconPlayerPause {...props} />,
    playerplay: (props: TablerIconProps) => <IconPlayerPlay {...props} />,
    playerrecord: (props: TablerIconProps) => <IconPlayerRecord {...props} />,
    playerskipback: (props: TablerIconProps) => <IconPlayerSkipBack {...props} />,
    playerskipforward: (props: TablerIconProps) => <IconPlayerSkipForward {...props} />,
    playerstop: (props: TablerIconProps) => <IconPlayerStop {...props} />,
    playertracknext: (props: TablerIconProps) => <IconPlayerTrackNext {...props} />,
    playertrackprev: (props: TablerIconProps) => <IconPlayerTrackPrev {...props} />,
    playlist: (props: TablerIconProps) => <IconPlaylist {...props} />,
    plug: (props: TablerIconProps) => <IconPlug {...props} />,
    plus: (props: TablerIconProps) => <IconPlus {...props} />,
    point: (props: TablerIconProps) => <IconPoint {...props} />,
    pokeball: (props: TablerIconProps) => <IconPokeball {...props} />,
    polaroid: (props: TablerIconProps) => <IconPolaroid {...props} />,
    pool: (props: TablerIconProps) => <IconPool {...props} />,
    power: (props: TablerIconProps) => <IconPower {...props} />,
    pray: (props: TablerIconProps) => <IconPray {...props} />,
    presentationanalytics: (props: TablerIconProps) => <IconPresentationAnalytics {...props} />,
    presentation: (props: TablerIconProps) => <IconPresentation {...props} />,
    printer: (props: TablerIconProps) => <IconPrinter {...props} />,
    prompt: (props: TablerIconProps) => <IconPrompt {...props} />,
    propeller: (props: TablerIconProps) => <IconPropeller {...props} />,
    puzzle: (props: TablerIconProps) => <IconPuzzle {...props} />,
    pyramid: (props: TablerIconProps) => <IconPyramid {...props} />,
    qrcode: (props: TablerIconProps) => <IconQrcode {...props} />,
    questionmark: (props: TablerIconProps) => <IconQuestionMark {...props} />,
    radio: (props: TablerIconProps) => <IconRadio {...props} />,
    radioactive: (props: TablerIconProps) => <IconRadioactive {...props} />,
    radiusbottomleft: (props: TablerIconProps) => <IconRadiusBottomLeft {...props} />,
    radiusbottomright: (props: TablerIconProps) => <IconRadiusBottomRight {...props} />,
    radiustopleft: (props: TablerIconProps) => <IconRadiusTopLeft {...props} />,
    radiustopright: (props: TablerIconProps) => <IconRadiusTopRight {...props} />,
    rainbow: (props: TablerIconProps) => <IconRainbow {...props} />,
    receipt2: (props: TablerIconProps) => <IconReceipt2 {...props} />,
    receiptoff: (props: TablerIconProps) => <IconReceiptOff {...props} />,
    receiptrefund: (props: TablerIconProps) => <IconReceiptRefund {...props} />,
    receipttax: (props: TablerIconProps) => <IconReceiptTax {...props} />,
    receipt: (props: TablerIconProps) => <IconReceipt {...props} />,
    recharging: (props: TablerIconProps) => <IconRecharging {...props} />,
    recordmail: (props: TablerIconProps) => <IconRecordMail {...props} />,
    rectanglevertical: (props: TablerIconProps) => <IconRectangleVertical {...props} />,
    rectangle: (props: TablerIconProps) => <IconRectangle {...props} />,
    recycle: (props: TablerIconProps) => <IconRecycle {...props} />,
    refreshalert: (props: TablerIconProps) => <IconRefreshAlert {...props} />,
    refresh: (props: TablerIconProps) => <IconRefresh {...props} />,
    registered: (props: TablerIconProps) => <IconRegistered {...props} />,
    relationmanytomany: (props: TablerIconProps) => <IconRelationManyToMany {...props} />,
    relationonetomany: (props: TablerIconProps) => <IconRelationOneToMany {...props} />,
    relationonetoone: (props: TablerIconProps) => <IconRelationOneToOne {...props} />,
    repeatonce: (props: TablerIconProps) => <IconRepeatOnce {...props} />,
    repeat: (props: TablerIconProps) => <IconRepeat {...props} />,
    replace: (props: TablerIconProps) => <IconReplace {...props} />,
    reportanalytics: (props: TablerIconProps) => <IconReportAnalytics {...props} />,
    reportmedical: (props: TablerIconProps) => <IconReportMedical {...props} />,
    reportmoney: (props: TablerIconProps) => <IconReportMoney {...props} />,
    report: (props: TablerIconProps) => <IconReport {...props} />,
    resize: (props: TablerIconProps) => <IconResize {...props} />,
    ripple: (props: TablerIconProps) => <IconRipple {...props} />,
    roadsign: (props: TablerIconProps) => <IconRoadSign {...props} />,
    rocket: (props: TablerIconProps) => <IconRocket {...props} />,
    rotate2: (props: TablerIconProps) => <IconRotate2 {...props} />,
    rotateclockwise2: (props: TablerIconProps) => <IconRotateClockwise2 {...props} />,
    rotateclockwise: (props: TablerIconProps) => <IconRotateClockwise {...props} />,
    rotaterectangle: (props: TablerIconProps) => <IconRotateRectangle {...props} />,
    rotate: (props: TablerIconProps) => <IconRotate {...props} />,
    route: (props: TablerIconProps) => <IconRoute {...props} />,
    router: (props: TablerIconProps) => <IconRouter {...props} />,
    rowinsertbottom: (props: TablerIconProps) => <IconRowInsertBottom {...props} />,
    rowinserttop: (props: TablerIconProps) => <IconRowInsertTop {...props} />,
    rss: (props: TablerIconProps) => <IconRss {...props} />,
    ruler2: (props: TablerIconProps) => <IconRuler2 {...props} />,
    ruler: (props: TablerIconProps) => <IconRuler {...props} />,
    run: (props: TablerIconProps) => <IconRun {...props} />,
    sailboat: (props: TablerIconProps) => <IconSailboat {...props} />,
    salt: (props: TablerIconProps) => <IconSalt {...props} />,
    satellite: (props: TablerIconProps) => <IconSatellite {...props} />,
    sausage: (props: TablerIconProps) => <IconSausage {...props} />,
    scale: (props: TablerIconProps) => <IconScale {...props} />,
    scan: (props: TablerIconProps) => <IconScan {...props} />,
    school: (props: TablerIconProps) => <IconSchool {...props} />,
    scissors: (props: TablerIconProps) => <IconScissors {...props} />,
    scooterelectric: (props: TablerIconProps) => <IconScooterElectric {...props} />,
    scooter: (props: TablerIconProps) => <IconScooter {...props} />,
    screenshareoff: (props: TablerIconProps) => <IconScreenShareOff {...props} />,
    screenshare: (props: TablerIconProps) => <IconScreenShare {...props} />,
    scubamask: (props: TablerIconProps) => <IconScubaMask {...props} />,
    search: (props: TablerIconProps) => <IconSearch {...props} />,
    section: (props: TablerIconProps) => <IconSection {...props} />,
    seeding: (props: TablerIconProps) => <IconSeeding {...props} />,
    select: (props: TablerIconProps) => <IconSelect {...props} />,
    selector: (props: TablerIconProps) => <IconSelector {...props} />,
    send: (props: TablerIconProps) => <IconSend {...props} />,
    separatorhorizontal: (props: TablerIconProps) => <IconSeparatorHorizontal {...props} />,
    separatorvertical: (props: TablerIconProps) => <IconSeparatorVertical {...props} />,
    separator: (props: TablerIconProps) => <IconSeparator {...props} />,
    server: (props: TablerIconProps) => <IconServer {...props} />,
    servicemark: (props: TablerIconProps) => <IconServicemark {...props} />,
    settingsautomation: (props: TablerIconProps) => <IconSettingsAutomation {...props} />,
    settings: (props: TablerIconProps) => <IconSettings {...props} />,
    shadowoff: (props: TablerIconProps) => <IconShadowOff {...props} />,
    shadow: (props: TablerIconProps) => <IconShadow {...props} />,
    shape2: (props: TablerIconProps) => <IconShape2 {...props} />,
    shape3: (props: TablerIconProps) => <IconShape3 {...props} />,
    shape: (props: TablerIconProps) => <IconShape {...props} />,
    share: (props: TablerIconProps) => <IconShare {...props} />,
    shieldcheck: (props: TablerIconProps) => <IconShieldCheck {...props} />,
    shieldlock: (props: TablerIconProps) => <IconShieldLock {...props} />,
    shieldoff: (props: TablerIconProps) => <IconShieldOff {...props} />,
    shieldx: (props: TablerIconProps) => <IconShieldX {...props} />,
    shield: (props: TablerIconProps) => <IconShield {...props} />,
    ship: (props: TablerIconProps) => <IconShip {...props} />,
    shirt: (props: TablerIconProps) => <IconShirt {...props} />,
    shoppingcartdiscount: (props: TablerIconProps) => <IconShoppingCartDiscount {...props} />,
    shoppingcartoff: (props: TablerIconProps) => <IconShoppingCartOff {...props} />,
    shoppingcartplus: (props: TablerIconProps) => <IconShoppingCartPlus {...props} />,
    shoppingcartx: (props: TablerIconProps) => <IconShoppingCartX {...props} />,
    shoppingcart: (props: TablerIconProps) => <IconShoppingCart {...props} />,
    shredder: (props: TablerIconProps) => <IconShredder {...props} />,
    signature: (props: TablerIconProps) => <IconSignature {...props} />,
    sitemap: (props: TablerIconProps) => <IconSitemap {...props} />,
    skateboard: (props: TablerIconProps) => <IconSkateboard {...props} />,
    slice: (props: TablerIconProps) => <IconSlice {...props} />,
    slideshow: (props: TablerIconProps) => <IconSlideshow {...props} />,
    smarthome: (props: TablerIconProps) => <IconSmartHome {...props} />,
    smokingno: (props: TablerIconProps) => <IconSmokingNo {...props} />,
    smoking: (props: TablerIconProps) => <IconSmoking {...props} />,
    snowflake: (props: TablerIconProps) => <IconSnowflake {...props} />,
    soccerfield: (props: TablerIconProps) => <IconSoccerField {...props} />,
    social: (props: TablerIconProps) => <IconSocial {...props} />,
    sock: (props: TablerIconProps) => <IconSock {...props} />,
    sortascending2: (props: TablerIconProps) => <IconSortAscending2 {...props} />,
    sortascendingletters: (props: TablerIconProps) => <IconSortAscendingLetters {...props} />,
    sortascendingnumbers: (props: TablerIconProps) => <IconSortAscendingNumbers {...props} />,
    sortascending: (props: TablerIconProps) => <IconSortAscending {...props} />,
    sortdescending2: (props: TablerIconProps) => <IconSortDescending2 {...props} />,
    sortdescendingletters: (props: TablerIconProps) => <IconSortDescendingLetters {...props} />,
    sortdescendingnumbers: (props: TablerIconProps) => <IconSortDescendingNumbers {...props} />,
    sortdescending: (props: TablerIconProps) => <IconSortDescending {...props} />,
    soup: (props: TablerIconProps) => <IconSoup {...props} />,
    space: (props: TablerIconProps) => <IconSpace {...props} />,
    speakerphone: (props: TablerIconProps) => <IconSpeakerphone {...props} />,
    speedboat: (props: TablerIconProps) => <IconSpeedboat {...props} />,
    sportbillard: (props: TablerIconProps) => <IconSportBillard {...props} />,
    square0: (props: TablerIconProps) => <IconSquare0 {...props} />,
    square1: (props: TablerIconProps) => <IconSquare1 {...props} />,
    square2: (props: TablerIconProps) => <IconSquare2 {...props} />,
    square3: (props: TablerIconProps) => <IconSquare3 {...props} />,
    square4: (props: TablerIconProps) => <IconSquare4 {...props} />,
    square5: (props: TablerIconProps) => <IconSquare5 {...props} />,
    square6: (props: TablerIconProps) => <IconSquare6 {...props} />,
    square7: (props: TablerIconProps) => <IconSquare7 {...props} />,
    square8: (props: TablerIconProps) => <IconSquare8 {...props} />,
    square9: (props: TablerIconProps) => <IconSquare9 {...props} />,
    squarecheck: (props: TablerIconProps) => <IconSquareCheck {...props} />,
    squaredot: (props: TablerIconProps) => <IconSquareDot {...props} />,
    squareforbid2: (props: TablerIconProps) => <IconSquareForbid2 {...props} />,
    squareforbid: (props: TablerIconProps) => <IconSquareForbid {...props} />,
    squareminus: (props: TablerIconProps) => <IconSquareMinus {...props} />,
    squareoff: (props: TablerIconProps) => <IconSquareOff {...props} />,
    squareplus: (props: TablerIconProps) => <IconSquarePlus {...props} />,
    squareroot2: (props: TablerIconProps) => <IconSquareRoot2 {...props} />,
    squareroot: (props: TablerIconProps) => <IconSquareRoot {...props} />,
    squarerotatedoff: (props: TablerIconProps) => <IconSquareRotatedOff {...props} />,
    squarerotated: (props: TablerIconProps) => <IconSquareRotated {...props} />,
    squaretogglehorizontal: (props: TablerIconProps) => <IconSquareToggleHorizontal {...props} />,
    squaretoggle: (props: TablerIconProps) => <IconSquareToggle {...props} />,
    squarex: (props: TablerIconProps) => <IconSquareX {...props} />,
    square: (props: TablerIconProps) => <IconSquare {...props} />,
    squaresdiagonal: (props: TablerIconProps) => <IconSquaresDiagonal {...props} />,
    squaresfilled: (props: TablerIconProps) => <IconSquaresFilled {...props} />,
    stack2: (props: TablerIconProps) => <IconStack2 {...props} />,
    stack: (props: TablerIconProps) => <IconStack {...props} />,
    stairsdown: (props: TablerIconProps) => <IconStairsDown {...props} />,
    stairsup: (props: TablerIconProps) => <IconStairsUp {...props} />,
    stairs: (props: TablerIconProps) => <IconStairs {...props} />,
    starhalf: (props: TablerIconProps) => <IconStarHalf {...props} />,
    staroff: (props: TablerIconProps) => <IconStarOff {...props} />,
    star: (props: TablerIconProps) => <IconStar {...props} />,
    stars: (props: TablerIconProps) => <IconStars {...props} />,
    steeringwheel: (props: TablerIconProps) => <IconSteeringWheel {...props} />,
    stepinto: (props: TablerIconProps) => <IconStepInto {...props} />,
    stepout: (props: TablerIconProps) => <IconStepOut {...props} />,
    stethoscope: (props: TablerIconProps) => <IconStethoscope {...props} />,
    sticker: (props: TablerIconProps) => <IconSticker {...props} />,
    strikethrough: (props: TablerIconProps) => <IconStrikethrough {...props} />,
    submarine: (props: TablerIconProps) => <IconSubmarine {...props} />,
    subscript: (props: TablerIconProps) => <IconSubscript {...props} />,
    subtask: (props: TablerIconProps) => <IconSubtask {...props} />,
    sum: (props: TablerIconProps) => <IconSum {...props} />,
    sunoff: (props: TablerIconProps) => <IconSunOff {...props} />,
    sun: (props: TablerIconProps) => <IconSun {...props} />,
    sunrise: (props: TablerIconProps) => <IconSunrise {...props} />,
    sunset: (props: TablerIconProps) => <IconSunset {...props} />,
    superscript: (props: TablerIconProps) => <IconSuperscript {...props} />,
    swimming: (props: TablerIconProps) => <IconSwimming {...props} />,
    switch2: (props: TablerIconProps) => <IconSwitch2 {...props} />,
    switch3: (props: TablerIconProps) => <IconSwitch3 {...props} />,
    switchhorizontal: (props: TablerIconProps) => <IconSwitchHorizontal {...props} />,
    switchvertical: (props: TablerIconProps) => <IconSwitchVertical {...props} />,
    switch: (props: TablerIconProps) => <IconSwitch {...props} />,
    tableexport: (props: TablerIconProps) => <IconTableExport {...props} />,
    tableimport: (props: TablerIconProps) => <IconTableImport {...props} />,
    tableoff: (props: TablerIconProps) => <IconTableOff {...props} />,
    table: (props: TablerIconProps) => <IconTable {...props} />,
    tag: (props: TablerIconProps) => <IconTag {...props} />,
    tallymark1: (props: TablerIconProps) => <IconTallymark1 {...props} />,
    tallymark2: (props: TablerIconProps) => <IconTallymark2 {...props} />,
    tallymark3: (props: TablerIconProps) => <IconTallymark3 {...props} />,
    tallymark4: (props: TablerIconProps) => <IconTallymark4 {...props} />,
    tallymarks: (props: TablerIconProps) => <IconTallymarks {...props} />,
    tank: (props: TablerIconProps) => <IconTank {...props} />,
    target: (props: TablerIconProps) => <IconTarget {...props} />,
    temperaturecelsius: (props: TablerIconProps) => <IconTemperatureCelsius {...props} />,
    temperaturefahrenheit: (props: TablerIconProps) => <IconTemperatureFahrenheit {...props} />,
    temperatureminus: (props: TablerIconProps) => <IconTemperatureMinus {...props} />,
    temperatureplus: (props: TablerIconProps) => <IconTemperaturePlus {...props} />,
    temperature: (props: TablerIconProps) => <IconTemperature {...props} />,
    template: (props: TablerIconProps) => <IconTemplate {...props} />,
    tent: (props: TablerIconProps) => <IconTent {...props} />,
    terminal2: (props: TablerIconProps) => <IconTerminal2 {...props} />,
    terminal: (props: TablerIconProps) => <IconTerminal {...props} />,
    testpipe: (props: TablerIconProps) => <IconTestPipe {...props} />,
    textdirectionltr: (props: TablerIconProps) => <IconTextDirectionLtr {...props} />,
    textdirectionrtl: (props: TablerIconProps) => <IconTextDirectionRtl {...props} />,
    textwrapdisabled: (props: TablerIconProps) => <IconTextWrapDisabled {...props} />,
    textwrap: (props: TablerIconProps) => <IconTextWrap {...props} />,
    thumbdown: (props: TablerIconProps) => <IconThumbDown {...props} />,
    thumbup: (props: TablerIconProps) => <IconThumbUp {...props} />,
    ticket: (props: TablerIconProps) => <IconTicket {...props} />,
    tiltshift: (props: TablerIconProps) => <IconTiltShift {...props} />,
    tir: (props: TablerIconProps) => <IconTir {...props} />,
    toggleleft: (props: TablerIconProps) => <IconToggleLeft {...props} />,
    toggleright: (props: TablerIconProps) => <IconToggleRight {...props} />,
    tool: (props: TablerIconProps) => <IconTool {...props} />,
    toolskitchen2: (props: TablerIconProps) => <IconToolsKitchen2 {...props} />,
    toolskitchen: (props: TablerIconProps) => <IconToolsKitchen {...props} />,
    tools: (props: TablerIconProps) => <IconTools {...props} />,
    tornado: (props: TablerIconProps) => <IconTornado {...props} />,
    tournament: (props: TablerIconProps) => <IconTournament {...props} />,
    track: (props: TablerIconProps) => <IconTrack {...props} />,
    tractor: (props: TablerIconProps) => <IconTractor {...props} />,
    trademark: (props: TablerIconProps) => <IconTrademark {...props} />,
    trafficcone: (props: TablerIconProps) => <IconTrafficCone {...props} />,
    trafficlights: (props: TablerIconProps) => <IconTrafficLights {...props} />,
    train: (props: TablerIconProps) => <IconTrain {...props} />,
    transferin: (props: TablerIconProps) => <IconTransferIn {...props} />,
    transferout: (props: TablerIconProps) => <IconTransferOut {...props} />,
    trashoff: (props: TablerIconProps) => <IconTrashOff {...props} />,
    trash: (props: TablerIconProps) => <IconTrash {...props} />,
    tree: (props: TablerIconProps) => <IconTree {...props} />,
    trees: (props: TablerIconProps) => <IconTrees {...props} />,
    trendingdown2: (props: TablerIconProps) => <IconTrendingDown2 {...props} />,
    trendingdown3: (props: TablerIconProps) => <IconTrendingDown3 {...props} />,
    trendingdown: (props: TablerIconProps) => <IconTrendingDown {...props} />,
    trendingup2: (props: TablerIconProps) => <IconTrendingUp2 {...props} />,
    trendingup3: (props: TablerIconProps) => <IconTrendingUp3 {...props} />,
    trendingup: (props: TablerIconProps) => <IconTrendingUp {...props} />,
    triangleoff: (props: TablerIconProps) => <IconTriangleOff {...props} />,
    trianglesquarecircle: (props: TablerIconProps) => <IconTriangleSquareCircle {...props} />,
    triangle: (props: TablerIconProps) => <IconTriangle {...props} />,
    trident: (props: TablerIconProps) => <IconTrident {...props} />,
    trophy: (props: TablerIconProps) => <IconTrophy {...props} />,
    truckdelivery: (props: TablerIconProps) => <IconTruckDelivery {...props} />,
    truckoff: (props: TablerIconProps) => <IconTruckOff {...props} />,
    truckreturn: (props: TablerIconProps) => <IconTruckReturn {...props} />,
    truck: (props: TablerIconProps) => <IconTruck {...props} />,
    typography: (props: TablerIconProps) => <IconTypography {...props} />,
    umbrella: (props: TablerIconProps) => <IconUmbrella {...props} />,
    underline: (props: TablerIconProps) => <IconUnderline {...props} />,
    unlink: (props: TablerIconProps) => <IconUnlink {...props} />,
    upload: (props: TablerIconProps) => <IconUpload {...props} />,
    urgent: (props: TablerIconProps) => <IconUrgent {...props} />,
    usercheck: (props: TablerIconProps) => <IconUserCheck {...props} />,
    userexclamation: (props: TablerIconProps) => <IconUserExclamation {...props} />,
    userminus: (props: TablerIconProps) => <IconUserMinus {...props} />,
    useroff: (props: TablerIconProps) => <IconUserOff {...props} />,
    userplus: (props: TablerIconProps) => <IconUserPlus {...props} />,
    userx: (props: TablerIconProps) => <IconUserX {...props} />,
    user: (props: TablerIconProps) => <IconUser {...props} />,
    users: (props: TablerIconProps) => <IconUsers {...props} />,
    vaccine: (props: TablerIconProps) => <IconVaccine {...props} />,
    variable: (props: TablerIconProps) => <IconVariable {...props} />,
    vectorbeizer2: (props: TablerIconProps) => <IconVectorBeizer2 {...props} />,
    vectorbezier: (props: TablerIconProps) => <IconVectorBezier {...props} />,
    vectortriangle: (props: TablerIconProps) => <IconVectorTriangle {...props} />,
    vector: (props: TablerIconProps) => <IconVector {...props} />,
    venus: (props: TablerIconProps) => <IconVenus {...props} />,
    versions: (props: TablerIconProps) => <IconVersions {...props} />,
    videominus: (props: TablerIconProps) => <IconVideoMinus {...props} />,
    videooff: (props: TablerIconProps) => <IconVideoOff {...props} />,
    videoplus: (props: TablerIconProps) => <IconVideoPlus {...props} />,
    video: (props: TablerIconProps) => <IconVideo {...props} />,
    view360: (props: TablerIconProps) => <IconView360 {...props} />,
    viewfinder: (props: TablerIconProps) => <IconViewfinder {...props} />,
    viewportnarrow: (props: TablerIconProps) => <IconViewportNarrow {...props} />,
    viewportwide: (props: TablerIconProps) => <IconViewportWide {...props} />,
    virusoff: (props: TablerIconProps) => <IconVirusOff {...props} />,
    virussearch: (props: TablerIconProps) => <IconVirusSearch {...props} />,
    virus: (props: TablerIconProps) => <IconVirus {...props} />,
    vocabulary: (props: TablerIconProps) => <IconVocabulary {...props} />,
    volume2: (props: TablerIconProps) => <IconVolume2 {...props} />,
    volume3: (props: TablerIconProps) => <IconVolume3 {...props} />,
    volume: (props: TablerIconProps) => <IconVolume {...props} />,
    walk: (props: TablerIconProps) => <IconWalk {...props} />,
    wallet: (props: TablerIconProps) => <IconWallet {...props} />,
    wand: (props: TablerIconProps) => <IconWand {...props} />,
    wavesawtool: (props: TablerIconProps) => <IconWaveSawTool {...props} />,
    wavesine: (props: TablerIconProps) => <IconWaveSine {...props} />,
    wavesquare: (props: TablerIconProps) => <IconWaveSquare {...props} />,
    wifi0: (props: TablerIconProps) => <IconWifi0 {...props} />,
    wifi1: (props: TablerIconProps) => <IconWifi1 {...props} />,
    wifi2: (props: TablerIconProps) => <IconWifi2 {...props} />,
    wifioff: (props: TablerIconProps) => <IconWifiOff {...props} />,
    wifi: (props: TablerIconProps) => <IconWifi {...props} />,
    wind: (props: TablerIconProps) => <IconWind {...props} />,
    windmill: (props: TablerIconProps) => <IconWindmill {...props} />,
    window: (props: TablerIconProps) => <IconWindow {...props} />,
    wiperwash: (props: TablerIconProps) => <IconWiperWash {...props} />,
    wiper: (props: TablerIconProps) => <IconWiper {...props} />,
    woman: (props: TablerIconProps) => <IconWoman {...props} />,
    worldlatitude: (props: TablerIconProps) => <IconWorldLatitude {...props} />,
    worldlongitude: (props: TablerIconProps) => <IconWorldLongitude {...props} />,
    world: (props: TablerIconProps) => <IconWorld {...props} />,
    wreckingball: (props: TablerIconProps) => <IconWreckingBall {...props} />,
    writingsign: (props: TablerIconProps) => <IconWritingSign {...props} />,
    writing: (props: TablerIconProps) => <IconWriting {...props} />,
    x: (props: TablerIconProps) => <IconX {...props} />,
    yinyang: (props: TablerIconProps) => <IconYinYang {...props} />,
    zodiacaquarius: (props: TablerIconProps) => <IconZodiacAquarius {...props} />,
    zodiacaries: (props: TablerIconProps) => <IconZodiacAries {...props} />,
    zodiaccancer: (props: TablerIconProps) => <IconZodiacCancer {...props} />,
    zodiaccapricorn: (props: TablerIconProps) => <IconZodiacCapricorn {...props} />,
    zodiacgemini: (props: TablerIconProps) => <IconZodiacGemini {...props} />,
    zodiacleo: (props: TablerIconProps) => <IconZodiacLeo {...props} />,
    zodiaclibra: (props: TablerIconProps) => <IconZodiacLibra {...props} />,
    zodiacpisces: (props: TablerIconProps) => <IconZodiacPisces {...props} />,
    zodiacsagittarius: (props: TablerIconProps) => <IconZodiacSagittarius {...props} />,
    zodiacscorpio: (props: TablerIconProps) => <IconZodiacScorpio {...props} />,
    zodiactaurus: (props: TablerIconProps) => <IconZodiacTaurus {...props} />,
    zodiacvirgo: (props: TablerIconProps) => <IconZodiacVirgo {...props} />,
    zoomcancel: (props: TablerIconProps) => <IconZoomCancel {...props} />,
    zoomcheck: (props: TablerIconProps) => <IconZoomCheck {...props} />,
    zoomin: (props: TablerIconProps) => <IconZoomIn {...props} />,
    zoommoney: (props: TablerIconProps) => <IconZoomMoney {...props} />,
};
