const locale = {
    plural: (num: number) => {
        num = Math.abs(num);
        if (num === 1) {
            return 1;
        }
        if (num <= 4) {
            return 2;
        }
        return 3;
    },
    order: (num: number) => {
        return 1;
    },
    gender: (gender: string) => {
        switch (gender) {
            case 'male':
                return 1;
            case 'female':
                return 2;
            default:
                return 3;
        }
    },
    direct: (value: number) => value,
};

export default locale;
