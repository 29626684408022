import { IconCalendarEvent, IconCheck, IconPencil, IconX } from '@tabler/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Event } from '../../../../api/entities/Event';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { columnDefinition } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { Routes } from '../../Routes';

export const eventColumns: (appState: AppState) => columnDefinition[] = (appState) => [
    // @translation events.date: "Veřejné"
    {
        title: 'events.public',
        width: 0,
        getter: (row) => ((row['public'] as boolean) ? <IconCheck /> : <IconX />),
    },
    // @translation events.title: "Title"
    { title: 'events.title', width: 5, getter: (row) => row['title'] as string, sortBy: 'title' },
    // @translation events.date: "Datum"
    {
        title: 'events.date',
        width: 4,
        getter: (row) =>
            row['from'] && row['to']
                ? moment(row['from'] as string)
                      .twix(row['to'] as any)
                      .format({ ...TWIX_FORMAT, monthFormat: 'M.' })
                : 'Neuvedeno',
        sortBy: 'from',
    },
    // @translation events.leaders: "Event leaders"
    {
        title: 'events.leaders',
        width: 3,
        getter: (row) => (row.leaders as any as { nickname: string }[]).map((leader) => leader.nickname).join(', '),
    },
    // @translation events.numAttendees: "Number of attendees"
    {
        title: 'events.numAttendees',
        width: 2,
        getter: (row) => (row as any as Event).attendees.filter((attendee) => attendee.answer === 'yes').length,
    },
    {
        width: 0,
        getter: (row) => (
            <div className="actions">
                <Link to={Routes.eventsDetail(appState.memberId, row.id as number)}>
                    <IconCalendarEvent />
                </Link>
                <Link to={Routes.eventsEdit(appState.memberId, row.id as number)}>
                    <IconPencil />
                </Link>
            </div>
        ),
    },
];
