import { IconPlus } from '@tabler/icons';
import * as React from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { logout } from '../../../utils/logout';
import { Translate } from '../../../utils/Translate';
import { popBacklink, RedirectPopBacklink } from '../../utils/RedirectPopBacklink';
import { RedirectStoreBacklink } from '../../utils/RedirectStoreBaclink';
import { RoleIcons } from '../../utils/RoleIcons';
import { Routes } from '../Routes';
import { Apply } from './application/Apply';
import { AuthAs } from './AuthAs';

interface IAuthProps {
    appState: AppState;
}

export function Auth({ appState }: IAuthProps) {
    const location = useLocation();

    const urlReminder = location.pathname.replace(Routes.auth(), '');

    return (
        <Switch>
            <Route path={Routes.auth()} exact={true}>
                {appState.members.length === 1 ? (
                    <RedirectPopBacklink to="" prefix={Routes.authAs(0)} />
                ) : (
                    <div className="wrapper center-screen spot-rh">
                        <main>
                            <h1 className="h2 mb-4 fw-normal">
                                {Translate.message('auth.title', 'Za koho chcete pracovat?')}
                            </h1>
                            {appState.members.map((member, id) => (
                                <Link key={id} to={popBacklink('', Routes.authAs(id))} className="link-hidden d-inline">
                                    <div className="block tight text-start d-flex flex-row w-100 align-items-center">
                                        <img
                                            src={member.image}
                                            alt=""
                                            className="profile-picture"
                                            width="28"
                                            height="28"
                                        />
                                        <div className="flex-fill">
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </div>
                                        <div>
                                            <RoleIcons member={member} />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                            <Link to={Routes.apply()} className="link-hidden d-inline">
                                <div className="block tight text-start translucent">
                                    <IconPlus style={{ marginLeft: 4, marginRight: 'calc(0.5em + 4px)' }} size={20} />
                                    {Translate.message('sidebar.register', 'Nová přihláška')}
                                </div>
                            </Link>
                            <p className="mt-4 text-muted fw-lighter text-start">
                                <Link to={Routes.login()} onClick={() => logout(appState)}>
                                    {Translate.message('auth.logout', 'Odhlásit se')}
                                </Link>
                            </p>
                        </main>
                    </div>
                )}
            </Route>
            <Route path={Routes.apply()}>
                <Apply {...{ appState }} />
            </Route>
            <Route path={Routes.authAs()}>
                {urlReminder.match(/^\/\d+($|\/)/) ? (
                    <AuthAs {...{ appState }} />
                ) : (
                    <RedirectStoreBacklink to={Routes.auth()} prefix={Routes.auth()} />
                )}
            </Route>
            <Route>
                <RedirectStoreBacklink to={Routes.auth()} prefix={Routes.auth()} />
            </Route>
        </Switch>
    );
}
