import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Form } from '../../../utils/inputs/Form';
import { FormInput } from '../../../utils/inputs/FormInput';
import { date, email, nonempty, required } from '../../../utils/inputs/inputValidators/validators';
import { Routes } from '../../Routes';

interface IMembersNewProps {
    appState: AppState;
}

export function MembersNew(props: IMembersNewProps) {
    const [create, { loading, error, data }] = useMutation(Api.mutations.member.create);

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;
    if (data) return <Redirect to={Routes.membersDetail(props.appState.memberId, data.auth.as.createMember.id)} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('members.new.title', 'Nový člen')}</h1>
            <h6>{Translate.message('members.new.subtitle', 'Vytvoř nového člena a doplň k němu základní údaje')}</h6>

            <Form
                defaultValues={{
                    firstname: null,
                    lastname: null,
                    gender: 'male',
                    nickname: null,
                    birthdate: null,
                    email: null,
                    organization: null,
                }}
                validators={(data) => ({
                    firstname: [required, nonempty],
                    lastname: [required, nonempty],
                    gender: [required],
                    nickname: [required, nonempty],
                    birthdate: [required, date],
                    email: [required, email],
                    organization: [required, nonempty],
                })}
                onSubmit={(data) => {
                    const value = {
                        firstname: data.firstname,
                        lastname: data.lastname,
                        gender: data.gender,
                        nickname: data.nickname,
                        birthdate: data.birthdate,
                        email: data.email,
                        organization: data.organization,
                        otherInfo: '',
                        motd: '',
                        lector: false,
                        lectorNote: '',
                        birchLeaf: 0,
                    };
                    create({
                        variables: {
                            memberId: props.appState.activeMember.id,
                            value,
                        },
                    });
                }}
            >
                {(inputProps, state, buttonProps) => (
                    <>
                        <div className="mt-5">
                            <div className="d-flex align-items-start">
                                <FormInput
                                    {...inputProps('firstname', 'd-inline-block')}
                                    type="text"
                                    label={Translate.message('members.fields.firstname', 'Jméno')}
                                />
                                <FormInput
                                    {...inputProps('lastname', 'd-inline-block')}
                                    type="text"
                                    label={Translate.message('members.fields.lastname', 'Příjmení')}
                                />
                            </div>
                            <div className="d-flex align-items-start">
                                <FormInput
                                    {...inputProps('nickname', 'd-inline-block')}
                                    type="text"
                                    label={Translate.message('members.fields.nickname', 'Přezdívka')}
                                />
                            </div>
                            <div className="d-flex align-items-start my-3">
                                <FormInput
                                    {...inputProps('gender', 'd-inline-block input-radio')}
                                    type="radio"
                                    options={[
                                        {
                                            title: Translate.message('members.fields.gender.male', 'Muž'),
                                            value: 'male',
                                        },
                                        {
                                            title: Translate.message('members.fields.gender.female', 'Žena'),
                                            value: 'female',
                                        },
                                        {
                                            title: Translate.message('members.fields.gender.other', 'Jiné'),
                                            value: 'other',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="d-flex align-items-start">
                                <FormInput
                                    {...inputProps('birthdate', 'd-inline-block')}
                                    type="date"
                                    label={Translate.message('members.fields.birthdate', 'Datum narození')}
                                />
                                <FormInput
                                    {...inputProps('email', 'd-inline-block')}
                                    type="email"
                                    label={Translate.message('members.fields.email', 'Email')}
                                />
                            </div>
                            <FormInput
                                {...inputProps('organization')}
                                type="text"
                                label={Translate.message('members.fields.organization', 'Organizace')}
                            />
                        </div>
                        <div className="mt-5">
                            <button {...buttonProps}>{Translate.message('members.new.create', 'Vytvořit')}</button>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
}
