import moment from 'moment';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Rights } from '../../../../api/Rights';
import { Member } from '../../../../api/entities/Member';
import { Translate } from '../../../../utils/Translate';
import { hasRights } from '../../../../utils/hasRights';
import { ImageUpload } from '../../../utils/ImageUpload';
import { RoleIcons } from '../../../utils/RoleIcons';
import { Tabs, TabsDefinition } from '../../../utils/Tabs';
import { LiveInput } from '../../../utils/inputs/LiveInput';
import { BasicInfoEditor } from './BasicInfoEditor';
import { LectorInfoEditor } from './LectorInfoEditor';
import { MembershipEditor } from './MembershipEditor';
import { OrganizationRoleEditor } from './OrganizationRoleEditor';
import { RoleEditor } from './RoleEditor';

export interface IProfileEditorProps {
    appState: AppState;
    member: Member;
    refetch: () => void;
}

export function ProfileEditor(props: IProfileEditorProps) {
    const { member, appState } = props;

    let tabs: TabsDefinition = {
        basic: {
            title: Translate.message('profilePage.basicInfo', 'Základní údaje'),
            content: <BasicInfoEditor {...props} />,
        },
        lector: {
            title: 'Profil lektora',
            content: <LectorInfoEditor {...props} />,
        },
    };

    // if (member.hasPublicInfo) {
    //     // Is counselor
    /*
        tabs.counselor = {
        title: Translate.message('profilePage.counselor', 'Vedoucí'),
        content: <CounselorInfoEditor {...props} />,
    };
    */
    // }

    // if (member.id === appState.activeMember.id) {
    //     // If is editing self

    //     tabs.managers = {
    //         title: Translate.message('profilePage.managers', 'Správci členského účtu'),
    //         content: <ManagersEditor {...props} />,
    //     };
    // }

    if (hasRights(appState, Rights.MEMBER_CHANGE_MEMBERSHIP)) {
        // Can change membership

        tabs.membership = {
            title: Translate.message('profilePage.membership', 'Členství'),
            content: <MembershipEditor {...props} />,
        };
    }

    if (hasRights(appState, Rights.ORGANIZATION_ROLE_ASSIGN)) {
        // Can assign organization roles

        tabs.organizationRole = {
            title: Translate.message('profilePage.organizationRole', 'Role v organizaci'),
            content: <OrganizationRoleEditor {...props} />,
        };
    }

    if (hasRights(appState, Rights.ROLE_ASSIGN)) {
        // Can assign roles

        tabs.role = {
            title: Translate.message('profilePage.role', 'Oprávnění'),
            content: <RoleEditor {...props} />,
        };
    }

    return (
        <>
            <div className="mt-5 d-flex flex-column flex-md-row align-items-center">
                <ImageUpload
                    onChange={(url) =>
                        Api.client
                            .mutate({
                                mutation: Api.mutations.member.update(
                                    appState.activeMember.id,
                                    member.id,
                                    'image',
                                    'String',
                                ),
                                variables: {
                                    value: url,
                                },
                            })
                            .then((data) => props.refetch())
                    }
                    appState={appState}
                    url={props.member.image}
                    size={180}
                    rounded
                    remove
                />
                <div
                    className="align-self-start align-self-md-center my-5 my-md-0 ms-md-5 flex-shrink-1"
                    style={{ minWidth: 0, maxWidth: '100%' }}
                >
                    <h2>
                        {member.firstname} {member.lastname}
                        <span className="ps-3">
                            <RoleIcons member={member} />
                        </span>
                    </h2>
                    <p>
                        {Translate.message(
                            'profilePage.born',
                            '{{gender?gender|Narozen|Narozena|Datum narození:}} {{date}}',
                            {
                                date: moment(member.birthdate).calendar(),
                                gender: member.gender,
                            },
                        )}
                    </p>
                    <LiveInput
                        type="text"
                        initialValue={member.nickname}
                        label={Translate.message('profilePage.nickname', 'Přezdívka')}
                        mutation={Api.mutations.member.update(
                            appState.activeMember.id,
                            member.id,
                            'nickname',
                            'String',
                        )}
                        style={{ maxWidth: '100%' }}
                    />
                </div>
            </div>

            <div className="my-5">
                <Tabs tabs={tabs} default="basic" />
            </div>
        </>
    );
}
