import { useQuery } from '@apollo/client';
import { IconPlus } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { classNames } from '../../../../utils/classNames';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventHeading } from '../../../utils/EventHeading';
import { ListEndText } from '../../../utils/ListEndText';
import { Spinner } from '../../../utils/Spinner';
import { Timetable } from '../../../utils/Timetable';
import { Routes } from '../../Routes';

interface IEventsUpcomingProps {
    appState: AppState;
}

export function EventsMy(props: IEventsUpcomingProps) {
    const { loading, error, data } = useQuery(Api.queries.event.list(props.appState.activeMember.id), {
        variables: {
            pageInput: {
                skip: 0,
                take: 100,
            },
            filter: {
                leaderId: props.appState.activeMember.id,
            },
            sortOrder: [
                {
                    column: 'from',
                    direction: 'DESC',
                },
            ],
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const events = data.auth.as.events.items as Event[];

    return (
        <div className="col-12">
            <h1>{Translate.message('events.my.title', 'Moje akce')}</h1>
            <h6>
                {Translate.message(
                    'events.my.subtitle',
                    'Ve kterých akcích máš prsty a co je ještě potřeba udělat? Odpovědi hledej tady.',
                )}
            </h6>

            <div className="row">
                <div className="col-lg-7 col-12">
                    <Link className="link-hidden" to={Routes.eventsNew(props.appState.memberId)}>
                        <div className="block text-center translucent link">
                            <IconPlus className="me-4" /> {Translate.message('events.my.new', 'Nová akce')}
                        </div>
                    </Link>
                    {events.map((event, i) => (
                        <div key={i} className={classNames('block')}>
                            <EventHeading appState={props.appState} event={event} key={i} details noRegister />
                        </div>
                    ))}

                    <ListEndText>
                        <Translate.Component
                            name="events.my.listEnd"
                            alt="A to je zatím všechno... což takhle naplánovat <<link&další akci>>?"
                            wrappers={{
                                link: (children, key) => (
                                    <Link key={key} to={Routes.eventsNew(props.appState.memberId)}>
                                        {children}
                                    </Link>
                                ),
                            }}
                        />
                    </ListEndText>
                </div>
                <div className="col-lg-5 col-12 ps-lg-5">
                    <div className={classNames('block')}>
                        <Timetable appState={props.appState} days={3} date={new Date(2023, 8, 22)} />
                    </div>
                </div>
            </div>
        </div>
    );
}
