import { useMutation, useQuery } from '@apollo/client';
import { IconShield } from '@tabler/icons';
import { useRouteMatch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Rights } from '../../../../api/Rights';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { hasRights } from '../../../../utils/hasRights';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Tabs, TabsDefinition } from '../../../utils/Tabs';
import { BasicInfoEditor } from './editors/BasicInfoEditor';
import { RegistrationEditor } from './editors/RegistrationEditor';

export interface IEventsEditorProps {
    appState: AppState;
}

export function EventsEditor(props: IEventsEditorProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const query = useQuery(Api.queries.event.detail(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });
    const [edit, editState] = useMutation(Api.mutations.event.modify, { onCompleted: () => query.refetch() });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    const loading = query.loading || editState.loading;
    const error = query.error || editState.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const event = query.data.auth.as.eventById as Event;

    const editorProps = {
        ...props,
        onSubmit: (value: any) =>
            edit({
                variables: {
                    memberId: props.appState.activeMember.id,
                    eventId: event.id,
                    value,
                },
            }),
        event,
    };

    let tabs: TabsDefinition = {
        basic: {
            title: Translate.message('events.edit.tabs.basic', 'Základní informace'),
            content: <BasicInfoEditor {...editorProps} />,
        },
    };

    if (hasRights(props.appState, Rights.EVENT_MODIFY)) {
        tabs = {
            ...tabs,
            registration: {
                title: (
                    <>
                        {Translate.message('events.edit.tabs.management', 'Správa akce')} <IconShield size={'1em'} />
                    </>
                ),
                content: <RegistrationEditor {...editorProps} />,
            },
        };
    }

    return (
        <div className="col-12">
            <h1>{Translate.message('events.edit.title', 'Upravit akci')}</h1>
            <h6 className="mb-5">{Translate.message('events.edit.subtitle', 'Uprav a doplň všechny údaje o akci')}</h6>

            {/* <EventTimeline appState={props.appState} event={event} /> */}

            <Tabs tabs={tabs} default="basic" />
        </div>
    );
}
