import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { required } from '../../../../utils/inputs/inputValidators/validators';
import { birchLeafs } from '../../profile/CounselorInfoEditor';
import { IStepProps } from '../Apply';

export function StepOrganization(props: IStepProps) {
    return (
        <Form
            defaultValues={{
                birchLeaf: 0,
                organization: props.values.organization,
            }}
            onSubmit={(values) => {
                const next = { ...values, birchLeaf: parseInt(values.birchLeaf) };
                props.next(next);
            }}
            validators={(data) => ({
                birchLeaf: [],
                organization: [required],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.organization.title" alt="Odkud k nám jedeš?" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.organization.subtitle"
                                alt="Se členem jaké dužiny máme tu čest? Máš nějaký březový lístek?"
                            />
                        </p>
                        <FormInput
                            {...inputProps('organization')}
                            type="text"
                            label={Translate.message('application.organization.organization', 'Celý název dužiny')}
                        />
                        <FormInput
                            {...inputProps('birchLeaf')}
                            type="select"
                            label={Translate.message('member.fields.birchLeaf', 'Březový lístek')}
                            options={birchLeafs.map((title, value) => ({ title, value }))}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end  flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
