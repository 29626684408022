import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../AppState';
import { Api } from '../../api/Api';
import { Event } from '../../api/entities/Event';
import { capitalize } from '../../utils/capitalize';
import { classNames } from '../../utils/classNames';
import { incremental } from '../../utils/incremental';
import { TWIX_FORMAT_SHORT } from '../../utils/twixFormat';
import { Routes } from '../routes/Routes';
import { ErrorDisplay } from './ErrorDisplay';
import { Spinner } from './Spinner';

interface ITimetableProps {
    date?: Date;
    days: number;
    appState: AppState;
}

export function Timetable(props: ITimetableProps) {
    const {
        loading: loading1,
        error: error1,
        data: data1,
    } = useQuery(Api.queries.event.list(props.appState.activeMember.id), {
        variables: {
            pageInput: {
                skip: 0,
                take: 100,
            },
            filter: {
                attendeeId: props.appState.activeMember.id,
            },
            sortOrder: [
                {
                    column: 'from',
                    direction: 'ASC',
                },
            ],
        },
    });

    const {
        loading: loading2,
        error: error2,
        data: data2,
    } = useQuery(Api.queries.event.list(props.appState.activeMember.id), {
        variables: {
            pageInput: {
                skip: 0,
                take: 100,
            },
            filter: {
                leaderId: props.appState.activeMember.id,
            },
            sortOrder: [
                {
                    column: 'from',
                    direction: 'ASC',
                },
            ],
        },
    });

    if (loading1 || loading2) return <Spinner />;
    if (error1) return <ErrorDisplay error={error1} />;
    if (error2) return <ErrorDisplay error={error2} />;

    const events = [...data1.auth.as.events.items, ...data2.auth.as.events.items] as Event[];

    const date = (moment(props.date) || moment()).startOf('day');
    const today = moment().startOf('day');
    const now = moment();

    const todayColumn = today.diff(date, 'days');

    return (
        <>
            <div className="d-flex flex-row">
                <div className="flex-grow-1">
                    <h2>Tvoje programy</h2>
                </div>
            </div>
            <div
                className={classNames('calendar')}
                style={{
                    marginTop: 15,
                    gridTemplateColumns: `50px ${`calc((100% - 50px) / ${props.days}) `.repeat(props.days)}`,
                    gridTemplateRows: `24px ${`${16 / 4}px `.repeat(24 * 4)}`,
                }}
            >
                {incremental(24, 1).map((hour) => (
                    <>
                        <div
                            style={{
                                gridColumn: 1,
                                gridRowStart: hour * 4,
                                gridRowEnd: hour * 4 + 1,
                                paddingTop: 12,
                                paddingRight: 10,
                                textAlign: 'right',
                            }}
                            key={`${hour}-l`}
                        >
                            {hour}:00
                        </div>
                        {/* <div
                            style={{
                                gridColumn: 1,
                                gridRowStart: hour * 4 + 2,
                                gridRowEnd: hour * 4 + 1 + 2,
                                paddingTop: 12,
                                paddingRight: 10,
                                textAlign: 'right',
                                opacity: 0.5,
                            }}
                            key={`${hour}-l`}
                        >
                            -&nbsp;&nbsp;
                        </div> */}
                    </>
                ))}
                {incremental(props.days).map((offset) => {
                    const currentDate = moment(date).add(offset, 'days');
                    return (
                        <>
                            <div
                                style={{ gridColumn: offset + 2, gridRow: 1, textAlign: 'center' }}
                                className="calendar-day-heading"
                                key={`${offset}-h`}
                            >
                                <span style={{ display: 'inline-block' }}>
                                    {capitalize(currentDate.format('dddd D. M.'))}
                                </span>
                            </div>
                            <div
                                style={{
                                    gridColumn: offset + 2,
                                    gridRowStart: offset === 0 ? 17 * 4 + 2 : 2,
                                    gridRowEnd: offset === 2 ? 14 * 4 + 3 : 24 * 4 + 3,
                                }}
                                className={classNames(
                                    'calendar-day',
                                    today.isSame(currentDate, 'day') && 'calendar-day-today',
                                )}
                                key={`${offset}-h`}
                            ></div>

                            {events
                                .filter(
                                    (event) =>
                                        moment(event.from).isSameOrBefore(currentDate, 'day') &&
                                        moment(event.to).isSameOrAfter(currentDate, 'day'),
                                )
                                .map((event) => (
                                    <Link
                                        to={Routes.eventsDetail(props.appState.memberId, event.id)}
                                        className={classNames(
                                            'calendar-event',
                                            event.leaders.some((leader) => leader.id === props.appState.activeMember.id)
                                                ? 'bg-warning'
                                                : 'bg-success',
                                        )}
                                        style={{
                                            gridRowStart: Math.max(
                                                2,
                                                moment(event.from).diff(currentDate, 'minutes') / 15 + 2,
                                            ),
                                            gridRowEnd: Math.min(
                                                24 * 4 + 2,
                                                moment(event.to).diff(currentDate, 'minutes') / 15 + 1 + 2,
                                            ),
                                            gridColumn: offset + 2,
                                            height: 'unset',
                                            whiteSpace: 'normal',
                                        }}
                                        key={event.id}
                                    >
                                        <strong>{event.title}</strong>
                                        <br />
                                        {moment(event.from).twix(event.to).format(TWIX_FORMAT_SHORT)}
                                    </Link>
                                ))}
                        </>
                    );
                })}

                {/* HARDCODED */}
                {/* <div
                    className={classNames('calendar-event', 'bg-primary')}
                    style={{
                        gridRowStart: 18 * 4 + 2 + 2,
                        gridRowEnd: 20 * 4 + 3,
                        gridColumn: 2,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Večeře</strong>
                    <br />
                    18:30 - 20:00
                </div> */}
                <div
                    className={classNames('calendar-event', 'bg-dark')}
                    style={{
                        gridRowStart: 21 * 4 + 3 + 2,
                        gridRowEnd: 24 * 4 + 3,
                        gridColumn: 2,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Zahájení a noční hra</strong>
                    <br />
                    od 21:45
                </div>

                <div
                    className={classNames('calendar-event', 'bg-primary')}
                    style={{
                        gridRowStart: 7 * 4 + 2 + 2,
                        gridRowEnd: 8 * 4 + 2 + 3,
                        gridColumn: 3,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Snídaně</strong> | 7:30 - 8:30
                </div>
                <div
                    className={classNames('calendar-event', 'bg-primary')}
                    style={{
                        gridRowStart: 12 * 4 + 2,
                        gridRowEnd: 13 * 4 + 2 + 3,
                        gridColumn: 3,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Oběd</strong>
                    <br />
                    12:00 - 13:30
                </div>
                <div
                    className={classNames('calendar-event', 'bg-primary')}
                    style={{
                        gridRowStart: 18 * 4 + 2 + 2,
                        gridRowEnd: 20 * 4 + 3,
                        gridColumn: 3,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Večeře</strong>
                    <br />
                    18:30 - 20:00
                </div>
                <div
                    className={classNames('calendar-event', 'bg-dark')}
                    style={{
                        gridRowStart: 20 * 4 + 2,
                        gridRowEnd: 24 * 4 + 3,
                        gridColumn: 3,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Duhová oslava</strong>
                    <br />
                    od 20:00
                </div>

                <div
                    className={classNames('calendar-event', 'bg-primary')}
                    style={{
                        gridRowStart: 7 * 4 + 2 + 2,
                        gridRowEnd: 8 * 4 + 2 + 3,
                        gridColumn: 4,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Snídaně</strong> | 7:30 - 8:30
                </div>
                <div
                    className={classNames('calendar-event', 'bg-primary')}
                    style={{
                        gridRowStart: 12 * 4 + 2,
                        gridRowEnd: 13 * 4 + 2 + 3,
                        gridColumn: 4,
                        height: 'unset',
                        whiteSpace: 'normal',
                    }}
                >
                    <strong>Oběd</strong>
                    <br />
                    12:00 - 13:30
                </div>

                {todayColumn >= 0 && todayColumn < props.days && (
                    <div
                        style={{
                            gridColumn: todayColumn + 2,
                            gridRow: now.hour() * 4 + Math.floor(now.minute() / 15) + 2,
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                position: 'absolute',
                                top: `${(now.minute() / 15 - Math.floor(now.minute() / 15)) * 100}%`,
                                left: -3,
                                right: -3,
                                height: 3,
                                borderRadius: 2,
                                zIndex: 3,
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
