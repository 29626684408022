import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { LiveInput } from '../../../utils/inputs/LiveInput';
import { birchLeafs } from './CounselorInfoEditor';
import { IProfileEditorProps } from './ProfileEditor';

export function BasicInfoEditor(props: IProfileEditorProps) {
    const { appState, member } = props;

    return (
        <div>
            <h3>{Translate.message('profilePage.contacts', 'Kontakty')}</h3>
            <div className="columns">
                <div className="col-12 col-md-6 d-inline-block align-top">
                    <LiveInput
                        type="email"
                        initialValue={member.email}
                        label={Translate.message('profilePage.email', 'Email')}
                        mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'email', 'String')}
                    />
                </div>
                <div className="col-12 col-md-6 d-inline-block align-top">
                    <LiveInput
                        type="text"
                        initialValue={member.phone}
                        label={Translate.message('profilePage.phone', 'Telefon')}
                        mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'phone', 'String')}
                    />
                </div>
                <LiveInput
                    type="text"
                    initialValue={member.organization}
                    label={Translate.message('profilePage.organization', 'Organizace')}
                    mutation={Api.mutations.member.update(
                        appState.activeMember.id,
                        member.id,
                        'organization',
                        'String',
                    )}
                    className="w-100"
                />
                <LiveInput
                    type="select"
                    initialValue={member.birchLeaf}
                    label={Translate.message('member.fields.birchLeaf', 'Březový lístek')}
                    mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'birchLeaf', 'Int')}
                    options={birchLeafs.map((title, value) => ({ title, value }))}
                    time={100}
                    asInt
                />
            </div>
            <h3 className="section-heading">{Translate.message('profilePage.otherInfoSection', 'Další sdělení')}</h3>
            <div className="columns">
                <LiveInput
                    type="textarea"
                    initialValue={member.otherInfo}
                    label={Translate.message('profilePage.otherInfo', 'Další důležitá zdravotní a jiná sdělení')}
                    mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'otherInfo', 'String')}
                    className="w-100"
                />
            </div>
        </div>
    );
}
