import { gql, useQuery } from '@apollo/client';
import { AppState } from '../../../../AppState';
import { Member } from '../../../../api/entities/Member';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { food } from './food';

interface IFoodAdminProps {
    appState: AppState;
}

export function FoodAdmin(props: IFoodAdminProps) {
    const { loading, error, data } = useQuery(
        gql`
            query GetAttributes($memberId: Int!) {
                auth {
                    as(memberId: $memberId) {
                        members(pageInput: { skip: 0, take: 10000 }) {
                            items {
                                attributes {
                                    type
                                    value
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const members = data.auth.as.members.items as Member[];

    return (
        <>
            <div className="col-12">
                <h1>Stravování</h1>
                <h6>Seznam všech přihlášených jídel</h6>

                {food.map((day) => (
                    <div className="mt-5">
                        <h2>{day.day}</h2>
                        {day.foods.map((food) => {
                            const unused = members.filter(
                                (member) => !member.attributes.some((attr) => attr.type === food.id),
                            ).length;

                            return (
                                <div className="mt-4 mb-5">
                                    <h3>{food.name}</h3>
                                    <div style={{ opacity: 0.5 }}>
                                        <span style={{ width: 50, display: 'inline-block', fontWeight: 'bold' }}>
                                            {unused}
                                        </span>
                                        Neodpověděl
                                    </div>
                                    {food.menu.map((title, value) => {
                                        const number = members.filter((member) =>
                                            member.attributes.some(
                                                (attr) => attr.type === food.id && attr.value === value,
                                            ),
                                        ).length;
                                        return (
                                            <>
                                                <div
                                                    style={{
                                                        marginBottom: value === 0 ? '20px' : 0,
                                                        opacity: value === 0 ? 0.5 : 1,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            width: 50,
                                                            display: 'inline-block',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {number}
                                                    </span>
                                                    {title}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </>
    );
}
