import * as React from 'react';
import { ButtonProps } from 'react-bootstrap';
import { Translate } from '../../../utils/Translate';
import { classNames } from '../../../utils/classNames';
import { IFormInputProps } from './FormInput';
import { validate, validator } from './inputValidators';

export type FormBodyFacotry = (
    inputProps: (key: string, additionalClassNames?: string) => Partial<IFormInputProps>,
    state: { [key: string]: { value: string | number | boolean | null | undefined; valid: boolean } },
    buttonProps: Partial<ButtonProps>,
) => JSX.Element;

export interface IFormProps {
    children: FormBodyFacotry;
    onSubmit: (values: { [key: string]: any }) => void;
    defaultValues: { [key: string]: any };
    validators: (data: { [key: string]: { value: any; valid: boolean } }) => { [key: string]: validator[] };
}

export function Form(props: IFormProps) {
    const validateField = (key: string, value: any) => {
        let validators = props.validators({})[key];
        try {
            validators = props.validators(state.value)[key];
        } catch {}
        const errors = validate(value, validators);
        return errors.map((err) => Translate.message('error.' + err.messageId, undefined, err.context));
    };

    const initState = () =>
        Object.entries(props.validators({})).reduce(
            (prev, [key]) => ({
                ...prev,
                [key]: {
                    value: props.defaultValues[key] === null ? null : props.defaultValues[key],
                    valid:
                        validateField(key, props.defaultValues[key] === null ? null : props.defaultValues[key])
                            .length === 0,
                },
            }),
            {},
        );

    const [state, setState] = React.useState<{
        value: { [key: string]: { value: any; valid: boolean } };
        original: any;
    }>({ value: initState(), original: props.defaultValues });

    if (JSON.stringify(state.original) !== JSON.stringify(props.defaultValues)) {
        const init = initState();
        setState({
            original: props.defaultValues,
            value: init,
        });
    }

    // console.log(
    //     Object.entries(state)
    //         .map(([key, val]) => `${key}: ${val.valid} ("${val.value}")`)
    //         .join('\n'),
    // );

    const validForm = !(
        Object.values(state.value).length === 0 ||
        !Object.values(state.value).reduce((prev, curr) => prev && curr.valid, true as boolean)
    );

    const submit = () => {
        if (validForm) {
            props.onSubmit(
                Object.entries(state.value).reduce((prev, [key, val]) => ({ ...prev, [key]: val.value }), {}),
            );
        }
    };

    return (
        <>
            {props.children(
                (key, additionalClassNames) => ({
                    validators: props.validators(state.value)[key],
                    onChange: (value) => {
                        state.value[key] = {
                            value: typeof value !== 'string' || value.length > 0 ? value : null,
                            valid: false,
                        };
                        setState({
                            original: state.original,
                            value: Object.entries(state.value).reduce(
                                (prev, [key, { value }]) => ({
                                    ...prev,
                                    [key]: {
                                        value: value,
                                        valid: validateField(key, value as any).length === 0,
                                    },
                                }),
                                {},
                            ),
                        });

                        //forceUpdate(iterator + 1);
                    },
                    onSubmit: submit,

                    value: state.value[key]?.value || '',
                    error: validateField(key, state.value[key]?.value as any).join(', '),
                    className: classNames('input-fill mt-4 me-3', additionalClassNames),

                    key,
                }),
                state.value,
                {
                    className: 'btn btn-primary',
                    disabled: !validForm,
                    onClick: submit,
                },
            )}
        </>
    );
}
