import { DocumentNode, useQuery } from '@apollo/client';
import { IconSearch } from '@tabler/icons';
import * as React from 'react';
import { v4 } from 'uuid';
import { AppState } from '../../../AppState';
import { classNames } from '../../../utils/classNames';
import { Translate } from '../../../utils/Translate';
import { ErrorDisplay } from '../ErrorDisplay';
import { Spinner } from '../Spinner';
import { IFormInputProps } from './FormInput';

interface ISearchInputProps<T> extends IFormInputProps {
    appState: AppState;
    query: DocumentNode;
    searchResolver: (data: any) => {
        total: number;
        items: T[];
    } | null;
    resultResolver: (data: any) => T | null;
    itemDisplayJSX: (item: T) => JSX.Element;
    itemDisplayText: (item: T) => string;
    emptyTitle?: string;
    clearAfterInsert?: true;
}

export function SearchInput<T>(props: ISearchInputProps<T>) {
    const [id] = React.useState('input-' + v4());
    let {
        value,
        disabled,
        error,
        label,
        type,
        className,
        additionalJSX,
        appState,
        query,
        searchResolver,
        resultResolver,
        itemDisplayJSX,
        itemDisplayText,
        clearAfterInsert,
    } = props;
    label = label || '';

    const onChange = (item: T | null) => {
        if (item) {
            if (clearAfterInsert) {
                setText('');
            } else {
                setText(itemDisplayText(item));
            }
            setFocus(false);
        }
        if (props.onChange) {
            props.onChange(item ? (item as any).id : null);
        }
        if (props.onBlur) {
            props.onBlur(item ? (item as any).id : null);
        }
    };

    const stringLabel = typeof label === 'string' ? label : '';

    const [text, setText] = React.useState('');
    const [focus, setFocus] = React.useState(false);

    const {
        loading,
        error: queryError,
        data,
    } = useQuery(query, {
        variables: {
            asId: appState.activeMember.id,
            search: text,
            doSearch: true,
            id: value ? value : 0,
            doResult: !!value,
        },
    });

    const search = searchResolver(data);
    const result = resultResolver(data);

    return (
        <div
            className={classNames('form-floating align-top', className)}
            style={props.style}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            tabIndex={1}
        >
            <input
                type={type}
                className={classNames('form-control', error && 'is-invalid', 'text-start')}
                style={{ paddingRight: 45 }}
                id={id}
                placeholder={stringLabel}
                disabled={disabled}
                value={
                    focus
                        ? text
                        : value !== null && result
                        ? `[${value}] ${itemDisplayText(result)}`
                        : Translate.message('searchInput.notSelected', 'Není vybráno...')
                }
                onChange={(event) => {
                    onChange(null);
                    setText(event.target.value);
                }}
                autoComplete="off"
            ></input>
            <label htmlFor={id}>{label}</label>
            {error && <div className="invalid-feedback">{error}</div>}
            <IconSearch style={{ position: 'absolute', top: 14, right: 15, opacity: 0.5 }} />
            {additionalJSX}
            {focus && (
                <div className="dropdown-menu show dropdown-menu-light w-100" style={{ overflowX: 'hidden' }}>
                    <div className="dropdown-header">
                        {text.length === 0
                            ? props.emptyTitle || Translate.message('searchInput.startTyping', 'Začněte psát...')
                            : Translate.message('searchInput.search', 'Vyhledávání "{{query}}"', { query: text }) +
                              (search && search.total
                                  ? ' - ' +
                                    Translate.message(
                                        'searchInput.numResults',
                                        '{{num}} {{num?plural|výsledek|výsledky|výsledků}}',
                                        { num: search.total },
                                    )
                                  : '')}
                    </div>
                    {loading && <Spinner />}
                    {queryError && <ErrorDisplay error={queryError} />}
                    {search &&
                        search.items &&
                        search.items.map((item, i) => (
                            <span key={i} className="dropdown-item link" onClick={() => onChange(item)}>
                                {itemDisplayJSX(item)}
                            </span>
                        ))}
                </div>
            )}
        </div>
    );
}
