import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { AppState } from '../../../../AppState';
import { Event } from '../../../../api/entities/Event';
import { Member } from '../../../../api/entities/Member';
import { capitalize } from '../../../../utils/capitalize';
import { incremental } from '../../../../utils/incremental';
import { TWIX_FORMAT_DAYTIME } from '../../../../utils/twixFormat';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { accomodation } from './accomodation';
import { food } from './food';

interface IPrintPageProps {
    appState: AppState;
}

export function PrintPage(props: IPrintPageProps) {
    const { loading, error, data } = useQuery(
        gql`
            {
                auth {
                    as(memberId: ${props.appState.activeMember.id}) {
                        events(pageInput: {skip:0, take: 9999}) {
                            items {
                                title
                                annotation
                                from
                                to
                                room
                                id
                                maxAttendees
                                attendees {
                                    answer
                                    member {
                                    firstname
                                    lastname
                                    nickname
                                    }
                                }
                            }
                        }

                        members(pageInput: {skip:0, take: 9999}) {
                            items {
                                firstname
                                lastname
                                nickname
                                organization
                                attributes {
                                    type
                                    value
                                }
                            }
                        }
                    }
                }
            }
        `,
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const rooms: { [key: string]: Event[] } = {};
    const events = data.auth.as.events.items as Event[];
    const members = data.auth.as.members.items as Member[];

    events.forEach((event) => (rooms[event.room] = [...(rooms[event.room] ? rooms[event.room] : []), event]));

    return (
        <>
            <>
                {food.map((day, key) => (
                    <div className="page bg-white" key={key}>
                        <div className="text-center mb-5">
                            <img alt={'header'} src={`/media/header.svg`} />
                        </div>
                        <h1 className="mb-3" style={{ fontFamily: 'Druha expedice', fontWeight: 'normal' }}>
                            {day.day}
                        </h1>
                        <div>
                            {day.foods.map((food, key) => (
                                <div key={key} className="mb-5">
                                    <h3 className="mb-3" style={{ fontFamily: 'Druha expedice', fontWeight: 'normal' }}>
                                        {food.name}
                                    </h3>
                                    {food.menu.map((item, i) => (
                                        <div className="d-flex flex-row mb-2">
                                            <span
                                                style={{
                                                    marginRight: 10,
                                                    display: 'inline-block',
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: '100%',
                                                    borderStyle: 'solid',
                                                    borderWidth: 20,
                                                    borderColor: [
                                                        '#F3F3F3',
                                                        '#FFC107',
                                                        '#28A745',
                                                        '#1886FC',
                                                        '#FA6816',
                                                    ][i],
                                                }}
                                            />
                                            <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>{item}</span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </>

            <>
                {events.map((event, key) => (
                    <div className="page bg-white" key={key}>
                        <div className="text-center mb-2">
                            <img alt={'header'} src={`/media/header.svg`} />
                        </div>
                        <div className="d-flex flex-row">
                            <div className="flex-grow-1">
                                <h1 style={{ fontFamily: 'Druha expedice', fontWeight: 'normal' }}>{event.title}</h1>
                                <h2 style={{ fontFamily: 'Druha expedice', fontWeight: 'normal' }}>
                                    {capitalize(moment(event.from).twix(event.to).format(TWIX_FORMAT_DAYTIME))} -{' '}
                                    {event.room}
                                </h2>
                            </div>
                            <div className="" style={{ height: 70, width: 70 }}>
                                <QRCode
                                    style={{ height: 70, width: 70 }}
                                    value={`https://is.brana.azduha.cz/auth/events/detail/${event.id}`}
                                />
                            </div>
                        </div>
                        <div>{event.annotation}</div>
                        <div className="mt-3" style={{ columns: 2, columnGap: 3 }}>
                            {incremental(event.maxAttendees || 50).map((id) => (
                                <div
                                    className="rounded"
                                    style={{
                                        padding: '1px 0',
                                    }}
                                >
                                    <span style={{ display: 'inline-block', color: '#dddddd', padding: '0 8px' }}>
                                        {id + 1}
                                    </span>
                                    <span>
                                        {(() => {
                                            const att = event.attendees.filter((att) => att.answer === 'yes')[id]
                                                ?.member;
                                            if (!att) {
                                                return '';
                                            }
                                            return `${att.firstname} "${att.nickname}" ${att.lastname}`;
                                        })()}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </>

            <>
                {Object.entries(rooms).map(([room, events], key) => (
                    <div className="page bg-white" key={key}>
                        <div className="text-center mb-5">
                            <img alt={'header'} src={`/media/header.svg`} />
                        </div>
                        <h1 className="mb-5" style={{ fontFamily: 'Druha expedice', fontWeight: 'normal' }}>
                            Místnost {room}
                        </h1>
                        {events
                            .sort((a, b) => moment(a.from).unix() - moment(b.from).unix())
                            .map((event, key) => (
                                <div className="mb-5">
                                    <h3>{event.title}</h3>
                                    <div>
                                        {capitalize(moment(event.from).twix(event.to).format(TWIX_FORMAT_DAYTIME))}
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
            </>

            <>
                {accomodation
                    .reduce(
                        (prev, curr) => [...prev, ...curr.rooms],
                        [] as { name: string; id: number; description?: string }[],
                    )
                    .filter((room) => room.id > 1)
                    .map((room, key) => (
                        <div className="page bg-white" key={key}>
                            <div className="text-center mb-5">
                                <img alt={'header'} src={`/media/header.svg`} />
                            </div>
                            <h1 className="mb-3" style={{ fontFamily: 'Druha expedice', fontWeight: 'normal' }}>
                                {room.name}
                            </h1>
                            <div style={{ columns: 2 }}>
                                {members
                                    .filter((member) =>
                                        member.attributes.some((att) => att.type === 1000 && att.value === room.id),
                                    )
                                    .map((member) => (
                                        <div className="mb-2">{`${member.firstname} "${member.nickname}" ${member.lastname} (${member.organization})`}</div>
                                    ))}
                            </div>
                        </div>
                    ))}
            </>
        </>
    );
}
