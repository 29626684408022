import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import removeAccents from 'remove-accents';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Member } from '../../../../api/entities/Member';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { ProfilePreview } from '../../../utils/ProfilePreview';
import { Spinner } from '../../../utils/Spinner';
import { SearchInput } from '../../../utils/inputs/SearchInput';

interface IBufetAdminPage {
    appState: AppState;
}

export function BufetAdminPage(props: IBufetAdminPage) {
    const [member, setMember] = useState<number | null>(null);

    const {
        data: bufetItems,
        loading: loadingBufetItems,
        error: errorBufetItems,
    } = useQuery(gql`
        {
            auth {
                as(memberId:${props.appState.activeMember.id}) {
                bufetItems {
                    id
                    title
                    price
                    category
                }
                }
            }
        }
    `);

    const [fetchMember, { data: memberData, loading: memberLoading, error: memberError, refetch }] = useLazyQuery(
        gql`
        query GetMemberData ($id: Int!){
            auth {
                as(memberId:${props.appState.activeMember.id}) {
                    memberById(id:$id) {
                        firstname
                        lastname
                        image
                        nickname
                        purchases {
                            id
                            title
                            price
                            time
                        }
                    }
                }
            }
        }
    `,
    );

    const [buyItem, { loading: buyLoading, error: buyError }] = useMutation(gql`
        mutation BuyItem($member: Int!, $item: Int!) {
            auth {
                as(memberId:${props.appState.activeMember.id}) {
                    buyItem(memberId: $member, itemId: $item)
                }
            }
        }
    `);

    const [removePurchase, { loading: removeLoading, error: removeError }] = useMutation(gql`
        mutation RemovePurchase($id: Int!) {
            auth {
                as(memberId:${props.appState.activeMember.id}) {
                    removePurchase(boughtItem: $id)
                }
            }
        }
    `);

    const selectMember = (id: number) => {
        setMember(id);
        fetchMember({ variables: { id } });
    };

    const total = memberData
        ? memberData.auth.as.memberById.purchases.reduce((prev: number, curr: any) => prev + curr.price, 0)
        : 0;

    return (
        <>
            <div className="col-12">
                <h1>Bufet</h1>

                <SearchInput<Member>
                    type="text"
                    label={'Kdo'}
                    appState={props.appState}
                    query={Api.queries.member.search}
                    resultResolver={(data) => data?.auth?.as?.memberById}
                    searchResolver={(data) => data?.auth?.as?.members}
                    itemDisplayJSX={(member) => (
                        <>
                            {member.firstname} "{member.nickname}" {member.lastname}
                        </>
                    )}
                    itemDisplayText={(member) => `${member.firstname} "${member.nickname}" ${member.lastname}`}
                    onChange={(value) => value && selectMember(value)}
                    className="mb-3"
                    clearAfterInsert
                />

                {member && <ProfilePreview appState={props.appState} id={member} />}

                {loadingBufetItems && <Spinner />}
                {errorBufetItems && <ErrorDisplay error={errorBufetItems} />}
                {bufetItems &&
                    Object.entries(
                        bufetItems.auth.as.bufetItems.reduce(
                            (prev: any, item: any) => ({
                                ...prev,
                                [item.category]: prev[item.category] ? [...prev[item.category], item] : [item],
                            }),
                            {},
                        ),
                    ).map(([category, items]) => (
                        <div className="mt-5">
                            <h3>{category}</h3>
                            {(items as any).map((item: any) => (
                                <ConfirmButton
                                    disabled={!member}
                                    onConfirm={async () => {
                                        await buyItem({
                                            variables: {
                                                member,
                                                item: item.id,
                                            },
                                        });
                                        refetch && refetch();
                                    }}
                                    color={'primary'}
                                    className="me-3 mb-3"
                                    label={
                                        <div>
                                            <h4>{item.title}</h4>
                                            <div>{item.price} Kč</div>
                                        </div>
                                    }
                                    style={{ width: 200 }}
                                />
                            ))}
                        </div>
                    ))}

                {(memberLoading || buyLoading || removeLoading) && <Spinner />}
                {memberError && <ErrorDisplay error={memberError} />}
                {buyError && <ErrorDisplay error={buyError} />}
                {removeError && <ErrorDisplay error={removeError} />}

                {memberData && (
                    <div className="mt-5">
                        <hr className="my-5" />
                        <h2>
                            {memberData.auth.as.memberById.firstname} "{memberData.auth.as.memberById.nickname}"{' '}
                            {memberData.auth.as.memberById.lastname}
                        </h2>
                        <h6>
                            Celkem{' '}
                            {memberData.auth.as.memberById.purchases.reduce(
                                (prev: number, curr: any) => prev + curr.price,
                                0,
                            )}{' '}
                            Kč
                        </h6>
                        <div className="block mb-4 d-inline-block">
                            <QRCode
                                value={`SPD*1.0*ACC:CZ8720100000002802493811*AM:${total}*CC:CZK*MSG:BRANA - ${removeAccents(
                                    props.appState.activeMember.firstname,
                                ).toUpperCase()} ${removeAccents(props.appState.activeMember.lastname).toUpperCase()}`}
                            />
                        </div>
                        <div className="block">
                            <table className="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th>Položka</th>
                                        <th>Cena</th>
                                        <th>Datum</th>
                                        <th style={{ minWidth: 180, width: 180 }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {memberData.auth.as.memberById.purchases
                                        .sort((a: any, b: any) => moment(b.time).unix() - moment(a.time).unix())
                                        .map((purchase: any, key: any) => (
                                            <tr key={key}>
                                                <td style={{ verticalAlign: 'middle' }}>{purchase.title}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{purchase.price} Kč</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    {moment(purchase.time).calendar()}
                                                </td>
                                                <td>
                                                    <ConfirmButton
                                                        onConfirm={async () => {
                                                            await removePurchase({
                                                                variables: {
                                                                    id: purchase.id,
                                                                },
                                                            });
                                                            refetch && refetch();
                                                        }}
                                                        label={'Odstranit'}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
