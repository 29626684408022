import { gql, useQuery } from '@apollo/client';
import { forTime } from 'waitasecond';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Member } from '../../../../api/entities/Member';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { accomodation } from './accomodation';

interface IAccomodationPageProps {
    appState: AppState;
}

export function AccomodationPage(props: IAccomodationPageProps) {
    const attrId = 1000;

    const { loading, error, data, refetch } = useQuery(
        gql`
            query GetAttributes($memberId: Int!) {
                auth {
                    as(memberId: $memberId) {
                        memberById(id: $memberId) {
                            attributes {
                                type
                                value
                            }
                        }
                        members(pageInput: { skip: 0, take: 10000 }) {
                            items {
                                firstname
                                nickname
                                lastname
                                organization
                                attributes {
                                    type
                                    value
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const member = data.auth.as.memberById as Member;
    const members = data.auth.as.members.items as Member[];

    const selectedRoom = member.attributes.find((a) => a.type === attrId)?.value || 0;
    const setRoom = async (value: number) => {
        await Api.client.mutate({
            mutation: gql`
                mutation UpdateFood($memberId: Int!, $type: Int!, $value: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            memberSetAttribute(memberId: $memberId, type: $type, value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            variables: {
                memberId: props.appState.activeMember.id,
                type: attrId,
                value,
            },
            errorPolicy: 'all',
        });
        await forTime(200);
        refetch();
    };

    return (
        <>
            <div className="col-12">
                <h1>Ubytování</h1>
                <h6>Je důležité mít na Bráně kde složit hlavu. Nezapomeň si přihlásit ubytování.</h6>

                {accomodation.map((building, i) => (
                    <>
                        <h3 className="mt-5">{building.name}</h3>
                        <div>{building.description}</div>
                        <div className="block p-0">
                            <table className="table table-striped mb-0">
                                <tbody>
                                    {building.rooms.map((room) => (
                                        <tr>
                                            <td width="60">
                                                <input
                                                    className="form-check-input m-2 shadow"
                                                    type="radio"
                                                    checked={selectedRoom === room.id}
                                                    onChange={(event) => event.target.checked && setRoom(room.id)}
                                                    style={{ cursor: 'pointer' }}
                                                    disabled={room.id === 1}
                                                />
                                            </td>
                                            <td className="pt-4" style={{ maxWidth: 200, width: 200 }}>
                                                <h3>{room.name}</h3>
                                            </td>
                                            <td className="pt-4" style={{ maxWidth: 300, width: 300 }}>
                                                {room.description}
                                            </td>
                                            <td className="py-4">
                                                <div>
                                                    {(() => {
                                                        if (room.id < 1) return '';

                                                        const registered = members.filter((m) =>
                                                            m.attributes.some(
                                                                (a) => a.type === attrId && a.value === room.id,
                                                            ),
                                                        );
                                                        if (registered.length === 0) {
                                                            return (
                                                                <div style={{ fontStyle: 'italic', opacity: 0.5 }}>
                                                                    Prozatím tu nikdo není
                                                                </div>
                                                            );
                                                        }

                                                        return (
                                                            <>
                                                                {registered.map((m) => (
                                                                    <div>
                                                                        {m.firstname} "{m.nickname}" {m.lastname} (
                                                                        {m.organization})
                                                                    </div>
                                                                ))}
                                                            </>
                                                        );
                                                    })()}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
}
