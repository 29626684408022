import { AppState } from '../../AppState';
import { Event } from '../../api/entities/Event';
import { Translate } from '../../utils/Translate';
import { ListEndText } from './ListEndText';
import { MemberHead } from './MemberHead';

interface IEventAttendeesProps {
    appState: AppState;
    event: Event;
}

export function EventAttendees(props: IEventAttendeesProps) {
    const { appState, event } = props;

    const attending = event.attendees.filter((attendance) => attendance.answer === 'yes');

    return (
        <div className="py-3">
            {attending.length > 0 ? (
                attending.map((attendee) => (
                    <div className="pb-2">
                        <MemberHead member={attendee.member} appState={appState} />
                        <span className="ps-2">
                            {attendee.member.firstname} "{attendee.member.nickname}" {attendee.member.lastname}
                        </span>
                    </div>
                ))
            ) : (
                <ListEndText left>{Translate.message('eventAttendees.none', 'Zatím se nikdo nepřihlásil')}</ListEndText>
            )}
        </div>
    );
}
