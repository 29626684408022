import { useRouteMatch } from 'react-router';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { FormInput } from '../../../utils/inputs/FormInput';

interface IEventsCalendarProps {
    appState: AppState;
}

export function EventsCalendar(props: IEventsCalendarProps) {
    const {
        params: { year, month },
    } = useRouteMatch<{ year: string; month: string }>();
    const yearNum = parseInt(year);
    const monthNum = parseInt(month) - 1;

    if (isNaN(yearNum) || isNaN(monthNum) || yearNum < 1000 || yearNum > 3000 || monthNum < 0 || monthNum > 11)
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    return (
        <div className="col-12">
            <h1>{Translate.message('events.calendar.title', 'Kalendář akcí')}</h1>
            <h6>{Translate.message('events.calendar.subtitle', 'Kalendář všech plánovaných i uplynulých akcí')}</h6>
            <div className="w-100 d-flex flex-column flex-lg-row mt-5">
                <div className="col-12 col-lg-7">
                    <div className="block"></div>
                </div>

                <div className="col-12 col-lg-5 ps-lg-5 mt-lg-4 mt-5">
                    <h3>{Translate.message('events.calendar.options', 'Nastavení')}</h3>
                    <FormInput
                        type="checkbox"
                        disabled={true}
                        value={true}
                        label={Translate.message('events.calendar.events', 'Akce')}
                    />
                    <FormInput
                        type="checkbox"
                        disabled={true}
                        value={false}
                        label={Translate.message('events.calendar.meetings', 'Schůzky')}
                    />
                    <FormInput
                        type="checkbox"
                        disabled={true}
                        value={false}
                        label={Translate.message('events.calendar.camps', 'Tábory')}
                    />
                </div>
            </div>
        </div>
    );
}
