import { DeltaOperation } from 'quill';
import ReactQuill from 'react-quill';

interface IQuillRenderProps {
    content: DeltaOperation;
}

export function QuillRender(props: IQuillRenderProps) {
    return (
        <div className="wysiwyg-content">
            <ReactQuill
                theme="bubble"
                className="bg-transparent"
                defaultValue={props.content as any}
                readOnly={true}
                ref={(obj) => {
                    if (!obj) return;
                    const editor = obj.getEditor().root;
                    if (!editor) return;
                    editor.className = '';
                    const container = editor.parentElement!;
                    if (!container) return;
                    container.className = '';
                    const clipboard = container.querySelector('.ql-clipboard');
                    if (clipboard) container.removeChild(clipboard);
                    const tooltip = container.querySelector('.ql-tooltip');
                    if (tooltip) container.removeChild(tooltip);
                }}
            />
        </div>
    );
}
