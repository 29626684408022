import { gql, useQuery } from '@apollo/client';
import { AppState } from '../../../../AppState';
import { Member } from '../../../../api/entities/Member';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { LiveInput } from '../../../utils/inputs/LiveInput';
import { food } from './food';

interface IFoodPersonalProps {
    appState: AppState;
}

export function FoodPersonal(props: IFoodPersonalProps) {
    const { loading, error, data } = useQuery(
        gql`
            query GetAttributes($memberId: Int!) {
                auth {
                    as(memberId: $memberId) {
                        memberById(id: $memberId) {
                            attributes {
                                type
                                value
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const member = data.auth.as.memberById as Member;

    return (
        <>
            <div className="col-12">
                <h1>Stravování</h1>
                <h6>Už teď se ti sbíhají sliny na jídlo, co tě bude na Bráně čekat? Nezapomeň si ho tady vybrat!</h6>

                <div className="alert alert-warning mt-5" role="alert">
                    Přihlašování jídla bylo ukončeno.
                </div>

                {food.map((day) => (
                    <div className="mt-5">
                        <h2>{day.day}</h2>
                        {day.foods.map((food) => {
                            const attribute = member.attributes.find(({ type }) => type === food.id);
                            let value = undefined;
                            if (attribute) {
                                value = attribute.value;
                            }

                            return (
                                <div className="mt-4 mb-5">
                                    <h3>{food.name}</h3>
                                    <LiveInput
                                        disabled={true}
                                        initialValue={value}
                                        mutation={(value, client) =>
                                            client.mutate({
                                                mutation: gql`
                                                    mutation UpdateFood($memberId: Int!, $type: Int!, $value: Int!) {
                                                        auth {
                                                            as(memberId: $memberId) {
                                                                memberSetAttribute(
                                                                    memberId: $memberId
                                                                    type: $type
                                                                    value: $value
                                                                ) {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                `,
                                                variables: {
                                                    memberId: props.appState.activeMember.id,
                                                    type: food.id,
                                                    value,
                                                },
                                                errorPolicy: 'all',
                                            })
                                        }
                                        type="radio"
                                        options={food.menu.map((title, value) => ({ title, value }))}
                                        className="w-100"
                                    />
                                </div>
                            );
                        })}
                    </div>
                ))}

                <div>
                    <em>
                        V případě specifických požadavků, nebo alergií nás, prosím, kontaktujte emailem na{' '}
                        <a href="mailto:brana@azduha.cz">brana@azduha.cz</a>.
                    </em>
                </div>
            </div>
        </>
    );
}
