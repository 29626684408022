import { Api } from '../../../../api/Api';
import { LiveInput } from '../../../utils/inputs/LiveInput';
import { IProfileEditorProps } from './ProfileEditor';

export function LectorInfoEditor(props: IProfileEditorProps) {
    const { appState, member } = props;

    return (
        <div>
            <LiveInput
                type="checkbox"
                initialValue={member.lector}
                label={'Chci lektorovat'}
                mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'lector', 'Boolean')}
            />
            <div className="mt-5">
                <div style={{ marginBottom: -12 }}>
                    Napiš pár řádků o sobě (co děláš, čím se zabýváš, jak dlouho se oboru věnuješ, jakou máš praxi,
                    atd.). Anotace lektora se objeví u všech tvých programů. Nepiš o programu, ale obecně o sobě, ať
                    účastnící ví s kým budou mít tu čest.
                </div>
                <LiveInput
                    type="textarea"
                    initialValue={member.motd}
                    label={'Medailonek o mně'}
                    mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'motd', 'String')}
                    className="w-100"
                />
            </div>
            <div className="mt-5">
                <div style={{ marginBottom: -12 }}>
                    Napiš nám prosím pár údajů o sobě jako o lektorovi. Na kterých akcích jsi již lektoroval, jaké
                    programy. Máš nějaké doporučení či reference? Tyto informace jsou jen pro potřebu organizátorů,
                    nebudou se nikde zobrazovat.
                </div>
                <LiveInput
                    type="textarea"
                    initialValue={member.lectorNote}
                    label={'Informace pro organizátory'}
                    mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'lectorNote', 'String')}
                    className="w-100"
                />
            </div>
        </div>
    );
}
