import { Translate } from '../../../../utils/Translate';
import { filterPreset } from '../../../utils/dataDisplayComponents/pagedView/Page';

export const eventFilterPresets: filterPreset[] = [
    {
        title: Translate.message('events.presets.private', 'Neveřejné programy'),
        value: {
            public: '2',
        },
    },
    {
        title: Translate.message('events.presets.public', 'Veřejné programy'),
        value: {
            public: '1',
        },
    },
];
