export enum Rights {
    // Admin
    ADMIN = 'admin', // @translation rights.admin: "Have all the rights"

    // Members
    MEMBER_DETAIL = 'member.detail', // @translation rights.member.detail: "View all details of any member"
    MEMBER_MODIFY = 'member.modify', // @translation rights.member.modify: "Modify any member"
    MEMBER_CHANGE_MEMBERSHIP = 'member.change-membership', // @translation rights.member.changeMembership: "Modify membership of anybody"

    // Roles
    ROLE_ASSIGN = 'role.assign', // @translation rights.role.assign: "Assign roles"
    ROLE_EDIT = 'role.edit', // @translation rights.role.edit: "Modify rights for different roles"

    // Organization roles
    ORGANIZATION_ROLE_ASSIGN = 'organization-role.assign', // @translation rights.organizationRole.assign: "Assign organization roles"
    ORGANIZATION_ROLE_EDIT = 'organization-role.edit', // @translation rights.organizationRole.edit: "Modify rights for different organization roles"

    // Events
    EVENT_CREATE = 'event.create', // @translation rights.event.create: "Create an event"
    EVENT_MODIFY = 'event.modify', // @translation rights.event.modify: "Modify any event"
    EVENT_ATTEND_ANYBODY = 'event.attend-anybody', // @translation rights.event.attendAnybody: "Set anybody's attendance (attend / unattend)"

    // Forum
    FORUM_CREATE_THREAD = 'forum.create-thread', // @translation rights.forum.createThread: "Create a forum thread"
    FORUM_POST = 'forum.post', // @translation rights.forum.post: "Post to any visible forum thread"
    FORUM_ARCHIVE = 'forum.archive', // @translation rights.forum.archive: "Archive a forum thread"

    // Articles
    ARTICLE_CREATE = 'article.create', // @translation rights.article.create: "Create a article"
    ARTICLE_MODIFY = 'article.modify', // @translation rights.article.modify: "Modify (and remove) any article"
    ARTICLE_VIEW_ARCHIVE = 'article.view-archive', // @translation rights.article.viewArchive: "View archived articles"
    ARTICLE_SEND = 'article.send', // @translation rights.article.send: "Send a article via email (and other social networks)"

    // Inventory
    INVENTORY_MODIFY = 'inventory.modify', // @translation rights.inventory.modify: "Modify events in the inventory system"
    INVENTORY_RENT = 'inventory.rent', // @translation rights.inventory.rent: "Rent an item from the inventory"

    // Summer camps
    CAMP_CREATE = 'camp.create', // @translation rights.camp.create: "Create a summer camp"
}
