import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { Route, Switch } from 'react-router-dom';
import removeAccents from 'remove-accents';
import { AppState } from '../../../../AppState';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';
import { BufetAdminPage } from './BufetAdmin';

interface IBufetPageProps {
    appState: AppState;
}

export function BufetPage(props: IBufetPageProps) {
    const { data, loading, error } = useQuery(
        gql`
        {
            auth {
                as(memberId:${props.appState.activeMember.id}) {
                    me {
                        purchases {
                            id
                            title
                            price
                            time
                        }
                    }
                }
            }
        }
    `,
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const total = data ? data.auth.as.me.purchases.reduce((prev: number, curr: any) => prev + curr.price, 0) : 0;

    return (
        <Switch>
            <Route path={Routes.bufet(props.appState.memberId)} exact>
                {data && (
                    <div className="col-12">
                        <h1>Všechny platby</h1>
                        <h6>Tady můžeš najít všechny svoje platby v bufetu a v čajovně</h6>
                        <div className="row">
                            <div className="col-12 col-xl-7">
                                <div className="block">
                                    <table className="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>Položka</th>
                                                <th>Cena</th>
                                                <th>Datum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.auth.as.me.purchases
                                                .sort((a: any, b: any) => moment(b.time).unix() - moment(a.time).unix())
                                                .map((purchase: any, key: any) => (
                                                    <tr key={key}>
                                                        <td style={{ verticalAlign: 'middle' }}>{purchase.title}</td>
                                                        <td style={{ verticalAlign: 'middle' }}>{purchase.price} Kč</td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            {moment(purchase.time).calendar()}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Celkem</th>
                                                <th>{total} Kč</th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5">
                                <div className="block">
                                    Prosíme tě o <strong>zaplacení částky co nejdříve po konci Duhové Brány</strong> na
                                    náš účet, nebo osobně na informacích.
                                    <br />
                                    Prosím neplať svůj dluh během Brány, placená částka se nebude aktualizovat v
                                    systému.
                                    <div className="row mt-5">
                                        <h3>QR Platba</h3>
                                        <div className="col-12 col-md-6 my-3">
                                            <QRCode
                                                value={`SPD*1.0*ACC:CZ8720100000002802493811*AM:${total}*CC:CZK*MSG:BRANA - ${removeAccents(
                                                    props.appState.activeMember.firstname,
                                                ).toUpperCase()} ${removeAccents(
                                                    props.appState.activeMember.lastname,
                                                ).toUpperCase()}`}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            Číslo účtu: 2802493811/2010
                                            <br />
                                            Částka: {total} Kč
                                            <br />
                                            <em>Toto není účet pro platby Duhové Brány!</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Route>
            <Route path={Routes.bufetAdmin(props.appState.memberId)}>
                <BufetAdminPage appState={props.appState} />
            </Route>
        </Switch>
    );
}
