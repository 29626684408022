import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Article } from '../../../../api/entities/Article';
import { ErrorDisplay } from '../../ErrorDisplay';
import { EventHeading } from '../../EventHeading';
import { ListEndText } from '../../ListEndText';
import { QuillRender } from '../../QuillRender';
import { Spinner } from '../../Spinner';
import { IViewProps } from '../IViewProps';

interface IArticlesListViewProps extends IViewProps<Article> {
    filter: { [key: string]: any };
    limit?: number;
    error?: string;
}

export function ArticlesListView(props: IArticlesListViewProps) {
    const { loading, error, data } = useQuery(props.query, {
        variables: {
            pageInput: {
                skip: 0,
                take: props.limit || 100,
            },
            filter: props.filter,
            sortOrder: [
                {
                    column: 'id',
                    direction: 'DESC',
                },
            ],
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const resolved = props.dataResolver(data);

    return (
        <>
            {resolved.total === 0 && <ListEndText>{props.error}</ListEndText>}
            {resolved.items.map((article, i) => {
                return (
                    <div className="block" key={i}>
                        {article.relatedEvent && (
                            <>
                                <EventHeading event={article.relatedEvent} appState={props.appState} />
                                <hr className="my-4" />
                            </>
                        )}
                        <h3>{article.title}</h3>
                        <QuillRender content={JSON.parse(article.content)} />
                    </div>
                );
            })}
        </>
    );
}
