import { useMutation } from '@apollo/client';
import moment from 'moment';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Api } from '../../../../api/Api';
import { Article } from '../../../../api/entities/Article';
import { Event } from '../../../../api/entities/Event';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Form } from '../../../utils/inputs/Form';
import { FormInput } from '../../../utils/inputs/FormInput';
import { date, integer, nonempty, required } from '../../../utils/inputs/inputValidators/validators';
import { SearchInput } from '../../../utils/inputs/SearchInput';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';

interface IArticlesEditorProps {
    appState: AppState;
    article?: Article;
}

export function ArticlesEditor(props: IArticlesEditorProps) {
    const [create, createStatus] = useMutation(Api.mutations.article.create);
    const [update, updateStatus] = useMutation(Api.mutations.article.update);
    const [del, deleteStatus] = useMutation(Api.mutations.article.delete);

    const loading = createStatus.loading || updateStatus.loading || deleteStatus.loading;
    const error = createStatus.error || updateStatus.error || deleteStatus.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;
    if (createStatus.data || updateStatus.data || deleteStatus.data)
        return <Redirect to={Routes.articlesList(props.appState.memberId)} />;

    return (
        <div className="col-12">
            {props.article ? (
                <>
                    <h1>{Translate.message('articles.edit.title', 'Upravit lísteček')}</h1>
                    <h6>{Translate.message('articles.edit.subtitle', 'Uprav existuící lísteček')}</h6>
                </>
            ) : (
                <>
                    <h1>{Translate.message('articles.new.title', 'Nový lísteček')}</h1>
                    <h6>
                        {Translate.message(
                            'articles.new.subtitle',
                            'Vytvoř nový lísteček a doplň k němu základní údaje',
                        )}
                    </h6>
                </>
            )}

            <Form
                defaultValues={
                    props.article
                        ? {
                              ...props.article,
                              content: JSON.parse(props.article.content),
                              visibleFrom: moment(props.article.visibleFrom).format('YYYY-MM-DDTHH:mm'),
                              visibleTo: moment(props.article.visibleTo).format('YYYY-MM-DDTHH:mm'),
                              relatedEvent: props.article.relatedEvent ? props.article.relatedEvent.id : null,
                          }
                        : {
                              visibleFrom: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
                          }
                }
                validators={(data) => ({
                    title: [required, nonempty],
                    visibleFrom: [required, date],
                    visibleTo: [required, date],
                    content: [required],
                    relatedEvent: [integer],
                })}
                onSubmit={(data) => {
                    const value = { ...data, content: JSON.stringify(data.content.ops) };
                    if (props.article) {
                        update({
                            variables: {
                                memberId: props.appState.activeMember.id,
                                articleId: props.article.id,
                                value,
                            },
                        });
                    } else {
                        create({
                            variables: {
                                memberId: props.appState.activeMember.id,
                                value,
                            },
                        });
                    }
                }}
            >
                {(inputProps, state, buttonProps) => (
                    <>
                        <div className="mt-5">
                            <FormInput
                                {...inputProps('title', 'd-inline-block')}
                                type="text"
                                label={Translate.message('articles.fields.title', 'Nadpis lístečku')}
                            />
                            <div className="d-flex flex-column flex-md-row align-items-start">
                                <FormInput
                                    {...inputProps('visibleFrom')}
                                    type="datetime-local"
                                    label={Translate.message('articles.fields.visibleFrom', 'Viditelný od')}
                                />
                                <FormInput
                                    {...inputProps('visibleTo')}
                                    type="datetime-local"
                                    label={Translate.message('articles.fields.visibleTo', 'Viditelný do')}
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                            <SearchInput<Event>
                                {...inputProps('relatedEvent', 'd-inline-block')}
                                appState={props.appState}
                                label={Translate.message('articles.fields.relatedEvent', 'Související akce')}
                                type="text"
                                query={Api.queries.event.search}
                                resultResolver={(data) => data?.auth?.as?.eventById}
                                searchResolver={(data) => data?.auth?.as?.events}
                                itemDisplayJSX={(event) => (
                                    <>
                                        {event.title}{' '}
                                        <span style={{ opacity: 0.5 }}>
                                            (
                                            {moment(event.from).twix(event.to, true).format({
                                                monthFormat: 'M.',
                                                showDayOfWeek: false,
                                                dayFormat: 'D.',
                                                implicitYear: true,
                                            })}
                                            )
                                        </span>
                                    </>
                                )}
                                itemDisplayText={(event) => event.title}
                                emptyTitle={Translate.message('articles.eventSearch.emptyTitle', 'Poslední akce')}
                            />
                        </div>
                        <div className="mt-5">
                            <FormInput
                                {...inputProps('content')}
                                type="editor"
                                label={Translate.message('articles.fields.content', 'Obsah')}
                                className="w-100"
                                appState={props.appState}
                            />
                        </div>
                        <div className="mt-5">
                            <button {...buttonProps}>
                                {props.article
                                    ? Translate.message('articles.edit.save', 'Uložit')
                                    : Translate.message('articles.new.create', 'Vytvořit')}
                            </button>
                            {props.article && (
                                <ConfirmButton
                                    className="ms-3"
                                    label={Translate.message('articles.edit.delete', 'Nevratně smazat')}
                                    onConfirm={() =>
                                        del({
                                            variables: {
                                                memberId: props.appState.activeMember.id,
                                                articleId: props.article!.id,
                                            },
                                        })
                                    }
                                />
                            )}
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
}
