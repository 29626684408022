import * as React from 'react';
import { AppState } from '../../../../AppState';
import { StepContact } from './steps/StepContact';
import { StepIntro } from './steps/StepIntro';
import { StepLector } from './steps/StepLector';
import { StepMedical } from './steps/StepMedical';
import { StepNames } from './steps/StepNames';
import { StepOrganization } from './steps/StepOrganization';
import { StepOutro } from './steps/StepOutro';
import { StepSubmit } from './steps/StepSubmit';

interface IApplyProps {
    appState: AppState;
}

export interface IStepProps {
    appState: AppState;
    step: number;
    setStep: (value: number) => void;
    values: { [key: string]: any };
    setValues: (values: { [key: string]: any }) => void;
    next: (values: { [key: string]: any }) => void;
    prev: () => void;
    memberId: null | number;
    setMemberId: (id: number | null) => void;
}

const steps: React.FC<IStepProps>[] = [
    StepIntro,
    StepNames,
    StepOrganization,
    StepContact,
    StepLector,
    StepMedical,
    /* Member gets created here */
    StepSubmit,
    /* Membership request gets created here */
    StepOutro,
];

export function Apply(props: IApplyProps) {
    const [step, setStep] = React.useState(0);
    const [values, setValues] = React.useState<{ [key: string]: any }>({});
    const [memberId, setMemberId] = React.useState<null | number>(null);

    const stepProps: IStepProps = {
        appState: props.appState,
        step,
        setStep,
        values,
        memberId,
        setMemberId,
        setValues: (newValues) => setValues({ ...values, ...newValues }),
        next: (newValues) => {
            setValues({ ...values, ...newValues });
            setStep(step + 1);
        },
        prev: () => setStep(step - 1),
    };

    const Element = steps[step];

    return (
        <div className="wrapper center-screen center-screen-wide spot-rh">
            <main>
                <img className="mb-5 brand" src="/media/logo-dark.png" alt="Logo" />
                <div className="block text-start p-5">
                    <Element {...stepProps} />
                </div>
            </main>
        </div>
    );
}
