import { IconMail, IconPhone, IconSettings } from '@tabler/icons';
import { AppState } from '../../../../AppState';

interface IContactPageProps {
    appState: AppState;
}

export function ContactPage(props: IContactPageProps) {
    return (
        <>
            <div className="col-12">
                <h1>Kontakty</h1>
                <h6>Nevíš si s něčím rady? Chceš se na něco zeptat? Jsi na správném místě!</h6>
                <div className="mt-5 d-flex flex-col align-items-center">
                    <div className="me-3">
                        <IconMail />
                    </div>
                    <div>
                        Chcete se na cokoliv zeptat? Napište nám email na{' '}
                        <a href="mailto:brana@azduha.cz">brana@azduha.cz</a>
                    </div>
                </div>
                <div className="mt-3 d-flex flex-col align-items-center">
                    <div className="me-3">
                        <IconSettings />
                    </div>
                    <div>
                        Nefunguje vám něco se systémem? Ozvěte se Sonymu na{' '}
                        <a href="mailto:sony@azduha.cz">sony@azduha.cz</a>
                    </div>
                </div>
                <div className="mt-3 d-flex flex-col align-items-center">
                    <div className="me-3">
                        <IconPhone />
                    </div>
                    <div>
                        Preferujete přímý lidský kontakt? Zavolejte Herimu na číslo{' '}
                        <a href="tel:+420731333195">+420 731 333 195</a>
                    </div>
                </div>
            </div>
        </>
    );
}
