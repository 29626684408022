import { useMutation } from '@apollo/client';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { AppState } from '../../../../../AppState';
import { Api } from '../../../../../api/Api';
import { Event } from '../../../../../api/entities/Event';
import { Translate } from '../../../../../utils/Translate';
import { ConfirmButton } from '../../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Spinner } from '../../../../utils/Spinner';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { date, nonempty } from '../../../../utils/inputs/inputValidators/validators';
import { Routes } from '../../../Routes';

export interface IRegistrationEditorProps {
    appState: AppState;
    event: Event;
    onSubmit: (value: { [key: string]: any }) => void;
}

export function RegistrationEditor(props: IRegistrationEditorProps) {
    const { event, appState, onSubmit } = props;

    const [del, { loading, error, data }] = useMutation(Api.mutations.event.delete, {
        variables: {
            memberId: appState.activeMember.id,
            eventId: event.id,
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    if (data) return <Redirect to={Routes.eventsList(appState.memberId)} />;

    console.log(event);

    return (
        <>
            <Form
                onSubmit={(value) => {
                    const result = {
                        from: moment(value.from).toISOString(),
                        to: moment(value.to).toISOString(),
                        public: value.public,
                        registrationOpen: value.registrationOpen,
                        room: value.room,
                    };
                    onSubmit(result);
                }}
                validators={(state) => ({
                    from: [date],
                    to: [date],
                    public: [],
                    registrationOpen: [],
                    room: [nonempty],
                })}
                defaultValues={{
                    from: event.from ? moment(event.from).format('yyyy-MM-DDTHH:mm') : null,
                    to: event.to ? moment(event.to).format('yyyy-MM-DDTHH:mm') : null,
                    public: event.public,
                    registrationOpen: event.registrationOpen,
                    room: event.room,
                }}
            >
                {(inputProps, state, buttonProps) => (
                    <>
                        <div className="d-flex align-items-start">
                            <FormInput
                                {...inputProps('from')}
                                type="datetime-local"
                                label={Translate.message('events.fields.from', 'Začátek')}
                            />
                            <FormInput
                                {...inputProps('to')}
                                type="datetime-local"
                                label={Translate.message('events.fields.to', 'Konec')}
                            />
                        </div>
                        <div className="mt-5">
                            <FormInput
                                {...inputProps('public')}
                                type="checkbox"
                                label={Translate.message('events.fields.public', 'Program je veřejný')}
                                className="d-block"
                            />
                            <FormInput
                                {...inputProps('registrationOpen')}
                                type="checkbox"
                                label={Translate.message('events.fields.registrationOpen', 'Registrace je otevřená')}
                                className="d-block"
                            />
                        </div>
                        <div className="mt-3">
                            <FormInput
                                {...inputProps('room')}
                                type="text"
                                label={Translate.message('events.fields.room', 'Název místnosti')}
                            />
                        </div>
                        <div className="mt-5">
                            <button {...buttonProps}>{Translate.message('events.edit.save', 'Uložit')}</button>
                        </div>
                    </>
                )}
            </Form>
            <div className="mt-5">
                <h3>{Translate.message('events.editor.remove.title', 'Odstranit akci')}</h3>
                <p className="text-danger">
                    {Translate.message(
                        'events.editor.remove.desc',
                        'Kliknutím na tlačítko dojde k odstranění této akce. Tato operace je nevratná, prosím postupujte opatrně!',
                    )}
                </p>
                <ConfirmButton
                    label={Translate.message('events.editor.remove.button', 'Nenávratně odstranit akci')}
                    onConfirm={() => del()}
                />
            </div>
        </>
    );
}
