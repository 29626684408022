import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../AppState';
import { Rights } from '../../api/Rights';
import { Event } from '../../api/entities/Event';
import { Translate } from '../../utils/Translate';
import { hasRights } from '../../utils/hasRights';
import { memberList } from '../../utils/memberList';
import { TWIX_FORMAT } from '../../utils/twixFormat';
import { Routes } from '../routes/Routes';
import { EventAttendButtom } from './EventAttendButton';
import { EventTask, EventTaskState } from './eventTasks/EventTask';
import { tasks } from './eventTasks/tasks';

interface IEventHeadingProps {
    appState: AppState;
    event: Event;
    details?: true;
    my?: true;
    minimal?: true;
    noRegister?: true;
}

export function EventHeading(props: IEventHeadingProps) {
    const { event, details, appState, minimal, noRegister } = props;

    const attending = event.attendees.filter((relation) => relation.answer === 'yes');

    return (
        <div className="event">
            <div className="event-body">
                <h3 className="event-title">{event.title}</h3>
                <p>
                    {event.from && event.to ? moment(event.from).twix(event.to).format(TWIX_FORMAT) : 'Neuvedeno'}
                    {!details && (
                        <span className="d-inline-block">
                            <Link to={Routes.eventsDetail(appState.memberId, event.id)}>
                                &nbsp;{'-'}&nbsp;
                                {Translate.message('eventsListView.readMore', 'Více informací')}
                            </Link>
                            {!minimal &&
                                (hasRights(appState, Rights.EVENT_MODIFY) ||
                                    (event.leaders &&
                                        event.leaders.some((leader) => leader.id === appState.activeMember.id))) && (
                                    <>
                                        {' | '}
                                        <Link to={Routes.eventsEdit(appState.memberId, event.id)}>
                                            {Translate.message('eventsListView.edit', 'Upravit')}
                                        </Link>
                                    </>
                                )}
                        </span>
                    )}
                </p>
                {details && (
                    <>
                        <p>
                            {event.annotation}
                            <br />
                            <Link to={Routes.eventsDetail(appState.memberId, event.id)}>
                                {Translate.message('eventsListView.readMore', 'Více informací')}
                            </Link>
                            {!minimal &&
                                (hasRights(appState, Rights.EVENT_MODIFY) ||
                                    (event.leaders &&
                                        event.leaders.some((leader) => leader.id === appState.activeMember.id))) && (
                                    <>
                                        {' | '}
                                        <Link to={Routes.eventsEdit(appState.memberId, event.id)}>
                                            {Translate.message('eventsListView.edit', 'Upravit')}
                                        </Link>
                                    </>
                                )}
                        </p>
                        {!minimal && !noRegister && !props.my && (
                            <p>
                                {props.event.leaders.some((leader) => leader.id === props.appState.activeMember.id) ? (
                                    <Link to={Routes.eventsDetail(appState.memberId, event.id)}>
                                        <button className="btn bg-warning flex-shrink-0">
                                            Tento program lektoruješ
                                        </button>
                                    </Link>
                                ) : (
                                    <EventAttendButtom appState={props.appState} event={props.event} />
                                )}
                                {attending.filter((att) => att.member.id !== props.appState.activeMember.id).length >
                                    0 && (
                                    <span className="ms-3 opacity-50">
                                        {Translate.message('eventsListView.attendees', 'Jede taky {{list}}', {
                                            list: memberList(
                                                attending
                                                    .filter((att) => att.member.id !== props.appState.activeMember.id)
                                                    .map((att) => att.member),
                                            ),
                                        })}
                                    </span>
                                )}
                            </p>
                        )}
                    </>
                )}
                {props.my &&
                    tasks
                        .filter((task) => task.validator && !task.validator(appState, event))
                        .map((task, i) => {
                            const beforeEvent = task.due <= 0;
                            const taskDueDate = moment(beforeEvent ? event.from : event.to).add(task.due, 'weeks');
                            const isPast = moment(taskDueDate).isBefore();
                            const isDue = moment(taskDueDate).subtract(1, 'week').isBefore();

                            return (
                                <EventTask
                                    appState={appState}
                                    icon={task.icon}
                                    link={task.link && task.link(appState, event)}
                                    state={
                                        isPast
                                            ? EventTaskState.MISSED
                                            : isDue
                                            ? EventTaskState.DUE
                                            : EventTaskState.UPCOMING
                                    }
                                    title={task.title}
                                    key={i}
                                />
                            );
                        })}
            </div>
            {!details && !minimal && !noRegister && event.registrationOpen && !props.my && (
                <EventAttendButtom appState={props.appState} event={props.event} />
            )}
        </div>
    );
}
