import { useQuery } from '@apollo/client';
import { IconCaretDown, IconCaretUp, IconChevronLeft, IconChevronRight, IconFilter } from '@tabler/icons';
import * as React from 'react';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import { Translate } from '../../../../utils/Translate';
import { classNames } from '../../../../utils/classNames';
import { incremental } from '../../../../utils/incremental';
import { ErrorDisplay } from '../../ErrorDisplay';
import { Spinner } from '../../Spinner';
import { FormInput } from '../../inputs/FormInput';
import { IViewProps } from '../IViewProps';

export type columnDefinition = {
    title?: string;
    getter: (row: { [key: string]: string | number | boolean }) => string | number | boolean | JSX.Element;
    width: number;
    sortBy?: string;
};

export type filter<T> = {
    id: string;
    title: string;
    toggle: (value: T, onChange: (value: T) => void) => JSX.Element;
    createFilter: (value: T) => { [key: string]: any };
};

export type filterPreset = {
    title: string;
    value: { [key: string]: any };
};

export interface IPageProps<T> extends IViewProps<T> {
    presets?: filterPreset[];
    filters?: filter<any>[];
    defaultSortOrder: {
        column: string;
        direction: 'ASC' | 'DESC';
    };
    columns: columnDefinition[];
    searchFilter?: string;
    defaultPreset?: { [key: string]: any };
    actions?: {
        icon: JSX.Element;
        onClick?: () => void;
        linkTo?: string;
    }[];
}

const PAGE_SIZE = 30;

export function Page<T>(props: IPageProps<T>) {
    const initFilter = (preset: {}) => {
        let filters = {
            ...(props.filters || []).reduce((prev, cur) => ({ ...prev, [cur.id]: null }), {}),
            ...preset,
        };
        return filters;
    };

    const location = window.location;
    const query = new URLSearchParams(location.search).get('filter');
    const queryProps = query ? JSON.parse(query) : null;

    const [filter, setFilterInternal] = useState<{ [key: string]: any }>(
        initFilter(queryProps || props.defaultPreset || {}),
    );

    console.log(filter);

    const [search, setSearchInternal] = useState('');
    const [sort, setSort] = useState<{
        column: string;
        direction: 'ASC' | 'DESC';
    }>({
        column: props.defaultSortOrder.column,
        direction: props.defaultSortOrder.direction,
    });
    const {
        params: { pageNum },
        url,
    } = useRouteMatch<{ pageNum: string }>();

    const page = parseInt(pageNum);

    let queryFilter = {};
    if (props.filters && props.filters.length > 0) {
        queryFilter = Object.entries(filter).reduce(
            (prev, [key, value]) => ({ ...prev, ...props.filters!.find((f) => f.id === key)!.createFilter(value) }),
            queryFilter,
        );
    }
    if (props.searchFilter && search.length > 0) {
        queryFilter = {
            ...queryFilter,
            [props.searchFilter]: search,
        };
    }

    const { loading, error, data } = useQuery(props.query, {
        variables: {
            filter: queryFilter,
            pageInput: {
                take: PAGE_SIZE,
                skip: PAGE_SIZE * page,
            },
            sortOrder: [sort],
        },
    });

    const hasFilters = Object.values(queryFilter).filter((value) => value !== null && value !== undefined).length;

    // const history = useHistory();
    const setFilter = (filters: { [key: string]: any }) => {
        //history.push(`${url}?filter=${encodeURIComponent(JSON.stringify(filters))}`);
        setFilterInternal(filters);
    };

    const setSearch = (search: string) => {
        setSearchInternal(search);
    };

    return (
        <>
            <div className="result-controls">
                {props.actions &&
                    props.actions.map((action, i) =>
                        action.linkTo ? (
                            <Link
                                key={i}
                                onClick={() => action.onClick && action.onClick()}
                                to={action.linkTo}
                                className="btn btn-white btn-icon"
                            >
                                {action.icon}
                            </Link>
                        ) : (
                            <span
                                className="btn btn-white btn-icon"
                                key={i}
                                onClick={() => action.onClick && action.onClick()}
                            >
                                {action.icon}
                            </span>
                        ),
                    )}
                {props.filters && (
                    <Dropdown className="d-inline-block mx-3 align-middle">
                        <Dropdown.Toggle
                            as={
                                React.forwardRef((props, ref) => (
                                    <span {...props} className={(props as any).className + ' btn btn-white btn-icon'}>
                                        <IconFilter />
                                        {hasFilters ? <span className="badge bg-danger">{hasFilters}</span> : <></>}
                                    </span>
                                )) as any
                            }
                        />
                        <Dropdown.Menu variant="light" style={{ width: 300, maxWidth: '90vw' }}>
                            <Dropdown.Header>
                                {Translate.message('pagedView.presets', 'Uložené filtry')}
                            </Dropdown.Header>
                            <Dropdown.Item
                                onClick={() => {
                                    setFilter(initFilter({}));
                                    setSearch('');
                                }}
                            >
                                {Translate.message('pagedView.clearPresets', 'Vymazat filtr')}
                            </Dropdown.Item>
                            {props.presets &&
                                props.presets.map((preset, i) => (
                                    <span key={i}>
                                        <Dropdown.Item
                                            onClick={() => {
                                                setFilter(initFilter(preset.value));
                                            }}
                                        >
                                            {preset.title}
                                        </Dropdown.Item>
                                    </span>
                                ))}
                            <Dropdown.Divider />
                            <Dropdown.Header>
                                {Translate.message('pagedView.customFilters', 'Vlastní filtr')}
                            </Dropdown.Header>
                            {props.filters.map((f, i) => (
                                <span key={i}>
                                    <Dropdown.ItemText>
                                        {f.toggle(filter[f.id], (value) => setFilter({ ...filter, [f.id]: value }))}
                                    </Dropdown.ItemText>
                                </span>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {props.searchFilter && (
                    <FormInput
                        type="text"
                        label={Translate.message('pagedView.search', 'Hledat')}
                        onChange={(value) => {
                            setSearch(value);
                        }}
                        value={search}
                        className="search align-middle"
                    />
                )}
            </div>
            {(() => {
                if (loading) return <Spinner />;
                if (error) return <ErrorDisplay error={error} />;

                const pagedData = props.dataResolver(data);

                const splitPath = url.split('/');
                const unpagedPath = splitPath.splice(0, splitPath.length - 1).join('/');
                const maxPages = Math.ceil(pagedData.total / PAGE_SIZE);

                const sizeSum = props.columns.reduce((prev, curr) => prev + curr.width, 0);

                return (
                    <>
                        <div className="block">
                            <table className="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        {props.columns.map((col, i) => (
                                            <th
                                                scope="col"
                                                style={{
                                                    width: `${(col.width / sizeSum) * 100}%`,
                                                    maxWidth: col.width > 0 ? (col.width / sizeSum) * 600 : undefined,
                                                }}
                                                key={i}
                                                title={
                                                    col.title && col.title.length > 0
                                                        ? Translate.message(col.title)
                                                        : undefined
                                                }
                                            >
                                                <span
                                                    className={col.sortBy ? 'link' : ''}
                                                    onClick={() => {
                                                        if (col.sortBy) {
                                                            setSort({
                                                                column: col.sortBy,
                                                                direction:
                                                                    sort.column === col.sortBy
                                                                        ? sort.direction === 'ASC'
                                                                            ? 'DESC'
                                                                            : 'ASC'
                                                                        : 'ASC',
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {col.title && col.title.length > 0 && Translate.message(col.title)}
                                                    {sort.column === col.sortBy &&
                                                        (sort.direction === 'DESC' ? (
                                                            <IconCaretDown size={15} className="ms-2" />
                                                        ) : (
                                                            <IconCaretUp size={15} className="ms-2" />
                                                        ))}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {pagedData.items.map((row, i) => (
                                        <tr key={i}>
                                            {props.columns.map((col, i) => {
                                                const value = col.getter(row as any);
                                                return (
                                                    <td
                                                        key={i}
                                                        title={
                                                            ['string', 'number'].includes(typeof value)
                                                                ? (col.getter(row as any) as string)
                                                                : undefined
                                                        }
                                                        style={{
                                                            maxWidth: col.width > 0 ? undefined : 'unset',
                                                        }}
                                                    >
                                                        {col.getter(row as any)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <ul className="pagination">
                                <li className={classNames('page-item', page === 0 && 'disabled')}>
                                    <Link to={`${unpagedPath}/${page - 1}`} className="page-link">
                                        <IconChevronLeft size={16} />
                                    </Link>
                                </li>
                                {incremental(Math.ceil(pagedData.total / PAGE_SIZE)).map((index) => (
                                    <li key={index} className={classNames('page-item', index === page && 'active')}>
                                        <Link to={`${unpagedPath}/${index}`} className="page-link">
                                            {index + 1}
                                        </Link>
                                    </li>
                                ))}
                                <li className={classNames('page-item', page === maxPages - 1 && 'disabled')}>
                                    <Link to={`${unpagedPath}/${page + 1}`} className="page-link">
                                        <IconChevronRight size={16} />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </>
                );
            })()}
        </>
    );
}
