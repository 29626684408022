import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Api } from '../../../api/Api';
import { AppState } from '../../../AppState';
import { classNames } from '../../../utils/classNames';
import { Translate } from '../../../utils/Translate';
import { Routes } from '../Routes';

interface ILoginProps {
    appState: AppState;
}

interface ILoginState {
    status: 'READY' | 'LOGGING' | 'OK' | 'ERROR';
    message: string;
    email: string;
    password: string;
}

class Login extends React.Component<RouteComponentProps<{}> & ILoginProps, ILoginState> {
    state: ILoginState = {
        status: 'READY',
        message: '',
        email: '',
        password: '',
    };

    login() {
        this.setState({
            status: 'LOGGING',
            message: '',
        });

        Api.client
            .mutate({
                mutation: Api.mutations.login,
                variables: {
                    email: this.state.email,
                    password: this.state.password,
                },
            })
            .catch((error) => {
                this.setState({
                    status: 'ERROR',
                    message: Translate.message('error.networkError'),
                });
            })
            .then(async (result) => {
                if (!result) return;
                if (result.errors && result.errors.length > 0) {
                    const error = Translate.message(
                        `error.${result.errors[0].extensions?.messageId || 'unknownError'}`,
                        result.errors[0].message,
                        result.errors[0].extensions?.context,
                    );
                    this.setState({
                        status: 'ERROR',
                        message: error,
                    });
                } else {
                    this.setState(
                        {
                            status: 'OK',
                            message: '',
                        },
                        async () => {
                            await Api.client.clearStore();
                            await this.props.appState.checkAuthorization();
                        },
                    );
                }
            });
    }

    render() {
        return (
            <div className="wrapper center-screen spot-rh">
                <main>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();

                            this.login();
                        }}
                    >
                        <img className="mb-5 brand" src={`${process.env.PUBLIC_URL}/media/logo-dark.png`} alt="Logo" />

                        <div className="form-floating">
                            <input
                                type="email"
                                className={classNames('form-control', this.state.status === 'ERROR' && 'is-invalid')}
                                id="floatingInput"
                                placeholder={Translate.message('login.email', 'Emailová adresa')}
                                value={this.state.email}
                                onChange={(event) => this.setState({ email: event.target.value })}
                            />
                            <label htmlFor="floatingInput">{Translate.message('login.email', 'Emailová adresa')}</label>
                        </div>
                        <div className="mt-3 form-floating">
                            <input
                                type="password"
                                className={classNames('form-control', this.state.status === 'ERROR' && 'is-invalid')}
                                id="floatingPassword"
                                placeholder={Translate.message('login.password', 'Heslo')}
                                value={this.state.password}
                                onChange={(event) => this.setState({ password: event.target.value })}
                            />
                            <label htmlFor="floatingPassword">{Translate.message('login.password', 'Heslo')}</label>
                            {this.state.message.length > 0 && (
                                <div className="invalid-feedback">{this.state.message}</div>
                            )}
                        </div>
                        <button
                            className="mt-4 w-100 btn btn-lg btn-primary text-white"
                            disabled={!(this.state.status === 'READY' || this.state.status === 'ERROR')}
                            type="submit"
                            onClick={() => this.login()}
                        >
                            {(this.state.status === 'READY' || this.state.status === 'ERROR') &&
                                Translate.message('login.submit', 'Přihlásit se')}
                            {this.state.status === 'LOGGING' && (
                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                    <span className="visually-hidden">
                                        {Translate.message('login.loading', 'Přihlašování...')}
                                    </span>
                                </div>
                            )}
                        </button>
                        <p className="mt-4 text-muted fw-lighter text-start">
                            <Link to={Routes.passwordReset()}>
                                {Translate.message('login.passwordReset', 'Zapomněli jste heslo?')}
                            </Link>
                            <br />
                            <Link to={Routes.register()}>
                                {Translate.message('login.register', 'Ještě nemáte účet?')}
                            </Link>
                            <br />
                            <Link to={Routes.resend()}>
                                {Translate.message('login.resend', 'Nepřišel vám ověřovací email?')}
                            </Link>
                        </p>
                    </form>
                </main>
            </div>
        );
    }
}

export default withRouter(Login);
