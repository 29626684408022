import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { classNames } from '../../../../utils/classNames';
import { Routes } from '../../../routes/Routes';
import { ErrorDisplay } from '../../ErrorDisplay';
import { EventHeading } from '../../EventHeading';
import { ListEndText } from '../../ListEndText';
import { Spinner } from '../../Spinner';
import { IViewProps } from '../IViewProps';

interface IEventsListViewProps extends IViewProps<Event> {
    filter: { [key: string]: any };
    limit?: number;
    details?: true;
    error?: string;
    sortByNew?: boolean;
}

export function EventsListView(props: IEventsListViewProps) {
    const { loading, error, data } = useQuery(props.query, {
        variables: {
            pageInput: {
                skip: 0,
                take: props.limit || 100,
            },
            filter: props.filter,
            sortOrder: [
                props.sortByNew
                    ? {
                          column: 'id',
                          direction: 'DESC',
                      }
                    : {
                          column: 'from',
                          direction: 'ASC',
                      },
            ],
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const resolved = props.dataResolver(data);

    return (
        <>
            {resolved.total === 0 && <ListEndText>{props.error}</ListEndText>}
            {resolved.items.map((event, i) => (
                <div className={classNames('block', !props.details && 'tight')}>
                    <EventHeading appState={props.appState} event={event} key={i} details={props.details} />
                </div>
            ))}
            {resolved.total - resolved.items.length > 0 && (
                <ListEndText>
                    <Translate.Component
                        name="eventsListView.moreEvents"
                        alt="A to není všechno! {{numEvents?plural|Naplánovanou|Naplánované|Naplánovaných}} máme ještě <<link&{{numEvents?plural|další|další|dalších}} {{numEvents}} {{numEvents?plural|akci|akce|akcí}}>>!"
                        context={{
                            numEvents: resolved.total - resolved.items.length,
                        }}
                        wrappers={{
                            link: (children, key) => (
                                <Link to={Routes.upcomingEvents(props.appState.memberId)} key={key}>
                                    {children}
                                </Link>
                            ),
                        }}
                    />
                </ListEndText>
            )}
        </>
    );
}
