import { Redirect, Route, Switch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Routes } from '../../Routes';
import { FoodAdmin } from './FoodAdmin';
import { FoodPersonal } from './FoodPersonal';

interface IFoodPageProps {
    appState: AppState;
}

export function FoodPage(props: IFoodPageProps) {
    return (
        <Switch>
            <Route path={Routes.food(props.appState.memberId)} exact>
                <Redirect to={Routes.foodPersonal(props.appState.memberId)} />
            </Route>
            <Route path={Routes.foodPersonal(props.appState.memberId)}>
                <FoodPersonal {...props} />
            </Route>
            <Route path={Routes.foodAdmin(props.appState.memberId)}>
                <FoodAdmin {...props} />
            </Route>
            <Route>
                <Redirect to={Routes.food(props.appState.memberId)} />
            </Route>
        </Switch>
    );
}
