import { ApolloQueryResult, gql, QueryOptions } from '@apollo/client';
import {
    IconBed,
    IconCalendarEvent,
    IconCoffee,
    IconHome,
    IconMenu2,
    IconPhone,
    IconPin,
    IconPlus,
    IconToolsKitchen2,
    IconUsers,
} from '@tabler/icons';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Api } from '../api/Api';
import { Rights } from '../api/Rights';
import { AppState } from '../AppState';
import { classNames } from '../utils/classNames';
import { hasRights } from '../utils/hasRights';
import { logout } from '../utils/logout';
import { Translate } from '../utils/Translate';
import { Routes } from './routes/Routes';
import { SidebarLink } from './utils/SidebarLink';

interface ISidebarProps {
    appState: AppState;
}

interface ISidebarState {
    requests: number;
    tasks: number;
    open: boolean;
}

export class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
    state: ISidebarState = {
        requests: 0,
        tasks: 0,
        open: false,
    };

    private intervals: { [key: string]: NodeJS.Timeout } = {};

    locationDidChange() {
        this.setState({
            open: false,
        });
    }

    scheduleQuery(
        query: QueryOptions,
        evaluator: (result: ApolloQueryResult<any>) => number,
        key: keyof ISidebarState,
    ) {
        if (this.intervals[key]) return;

        const callback = () =>
            Api.client.query(query).then((result) => {
                if (!result.data) return;
                const value = evaluator(result);
                this.setState({ [key]: value } as any);
            });

        const timeout = setInterval(callback, 10 * 1000);

        this.intervals[key] = timeout;

        callback();
    }

    componentDidMount() {
        if (hasRights(this.props.appState, Rights.MEMBER_CHANGE_MEMBERSHIP)) {
            this.scheduleQuery(
                {
                    query: gql`
                        query q($memberId: Int!) {
                            auth {
                                as(memberId: $memberId) {
                                    membershipRequests(pageInput: { skip: 0, take: 0 }, filter: { status: pending }) {
                                        total
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        memberId: this.props.appState.activeMember.id,
                    },
                },
                (result) => result.data.auth.as.membershipRequests.total as number,
                'requests',
            );
        }

        if (hasRights(this.props.appState, Rights.EVENT_MODIFY)) {
            this.scheduleQuery(
                {
                    query: gql`
                        query q($memberId: Int!) {
                            auth {
                                as(memberId: $memberId) {
                                    events(pageInput: { skip: 0, take: 0 }, filter: { public: false }) {
                                        total
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        memberId: this.props.appState.activeMember.id,
                    },
                },
                (result) => result.data.auth.as.events.total as number,
                'tasks',
            );
        }
    }

    componentWillUnmount() {
        Object.entries(this.intervals).forEach(([key, interval]) => clearInterval(interval));
        this.intervals = {};
    }

    render() {
        const appState = this.props.appState;
        const backlink = window.location.pathname.replace(Routes.authAs(this.props.appState.memberId), '');

        return (
            <nav className={classNames('nav-sidebar', this.state.open && 'open')}>
                <IconMenu2
                    className="toggle link"
                    onClick={() => {
                        if (!this.state.open) window.scrollTo(0, 0);
                        this.setState({ open: !this.state.open });
                    }}
                />
                <Link to={Routes.home(appState.memberId)} className="brand-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/media/logo.png`} alt="Logo" className="brand" />
                </Link>
                <ul className="nav nav-pills flex-column mb-auto">
                    <Dropdown>
                        <Dropdown.Toggle
                            as={
                                React.forwardRef((props, ref) => (
                                    <span {...props} role="button" className="dropdown-toggle nav-link" tabIndex={0}>
                                        <img
                                            src={appState.activeMember.image}
                                            alt=""
                                            className="profile-picture"
                                            width="28"
                                            height="28"
                                            style={{ marginLeft: -4 }}
                                        />
                                        <span>
                                            {appState.activeMember.firstname} "{appState.activeMember.nickname}"{' '}
                                            {appState.activeMember.lastname}
                                        </span>
                                    </span>
                                )) as any
                            }
                            className="text-start w-100 text-white"
                        ></Dropdown.Toggle>
                        <Dropdown.Menu variant="light" style={{ maxWidth: '100%' }}>
                            <Dropdown.Header>
                                {Translate.message('sidebar.memberSelect', 'Za koho chcete pracovat?')}
                            </Dropdown.Header>
                            {appState.members.map((member, id) => (
                                <Link
                                    key={id}
                                    to={Routes.authAs(id) + backlink}
                                    className="dropdown-item"
                                    onClick={() => this.locationDidChange()}
                                >
                                    <img src={member.image} alt="" className="profile-picture" width="28" height="28" />
                                    <span
                                        className="pe-1 align-middle"
                                        style={{
                                            maxWidth: 'calc(100% - 28px - 1em)',
                                            display: 'inline-block',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <span>
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </span>
                                    </span>
                                </Link>
                            ))}
                            <Link
                                to={Routes.apply()}
                                className="dropdown-item"
                                onClick={() => this.locationDidChange()}
                            >
                                <IconPlus style={{ marginLeft: 4, marginRight: 'calc(0.5em + 8px)' }} size={20} />
                                <span
                                    className="pe-1 align-middle"
                                    style={{
                                        maxWidth: 'calc(100% - 28px - 1em)',
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {Translate.message('sidebar.register', 'Nová přihláška')}
                                </span>
                            </Link>

                            <Dropdown.Divider />

                            <Link
                                className="dropdown-item"
                                to={Routes.profile(appState.memberId)}
                                onClick={() => this.locationDidChange()}
                            >
                                {Translate.message('sidebar.profile', 'Profil')}
                            </Link>

                            {/* <Dropdown.Divider />

                            <Link className="dropdown-item" to={Routes.settings(appState.memberId)}>
                                {Translate.message('sidebar.settings', 'Nastavení')}
                            </Link> */}

                            <Link
                                to={Routes.login()}
                                onClick={() => logout(this.props.appState)}
                                className="dropdown-item"
                            >
                                {Translate.message('sidebar.logout', 'Odhlásit se')}
                            </Link>
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr />
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.home(appState.memberId)}
                        title={Translate.message('sidebar.home', 'Domů')}
                        icon={IconHome}
                    />
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.events(appState.memberId)}
                        title={Translate.message('sidebar.events.general', 'Events')}
                        icon={IconCalendarEvent}
                        badge={this.state.tasks}
                    >
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.registeredEvents(appState.memberId)}
                            title={Translate.message('sidebar.events.registered', 'Přihlášené programy')}
                        />
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.upcomingEvents(appState.memberId)}
                            title={Translate.message('sidebar.events.upcoming', 'Plánované')}
                        />
                        {appState.memberDetails && appState.memberDetails.leadEvents.total > 0 && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.eventsMy(appState.memberId)}
                                    title={Translate.message('sidebar.events.my', 'Moje akce')}
                                />
                            </>
                        )}
                        {hasRights(appState, Rights.EVENT_CREATE) && (
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.eventsNew(appState.memberId)}
                                title={Translate.message('sidebar.events.new', 'Nová akce')}
                            />
                        )}
                        {hasRights(appState, Rights.EVENT_MODIFY) && (
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.eventsList(appState.memberId)}
                                title={Translate.message('sidebar.events.admin', 'Administrace')}
                                fuzzy
                            />
                        )}
                        {hasRights(appState, Rights.EVENT_MODIFY) && (
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.eventsPrivate(appState.memberId)}
                                title={Translate.message('sidebar.events.private', 'Na schválení')}
                                badge={this.state.tasks}
                                fuzzy
                            />
                        )}
                    </SidebarLink>
                    {hasRights(appState, Rights.ARTICLE_VIEW_ARCHIVE) && (
                        <>
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.articles(appState.memberId)}
                                title={Translate.message('sidebar.articles.general', 'Lístečky')}
                                icon={IconPin}
                            >
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.articlesList(appState.memberId)}
                                    title={Translate.message('sidebar.articles.admin', 'Administrace')}
                                    fuzzy
                                />
                                {hasRights(appState, Rights.ARTICLE_CREATE) && (
                                    <>
                                        <SidebarLink
                                            onClick={() => this.locationDidChange()}
                                            route={Routes.articlesNew(appState.memberId)}
                                            title={Translate.message('sidebar.articles.new', 'Nový lísteček')}
                                        />
                                    </>
                                )}
                            </SidebarLink>
                        </>
                    )}
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.members(appState.memberId)}
                        title={Translate.message('sidebar.members.general', 'Členové')}
                        icon={IconUsers}
                        badge={this.state.requests}
                    >
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.counselors(appState.memberId)}
                            title={Translate.message('sidebar.members.counselors', 'Vedoucí')}
                            fuzzy
                        />
                        {hasRights(appState, Rights.MEMBER_DETAIL) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.membersList(appState.memberId)}
                                    title={Translate.message('sidebar.members.all', 'Všichni')}
                                    fuzzy
                                />
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.membersNew(appState.memberId)}
                                    title={Translate.message('sidebar.members.new', 'Nový člen')}
                                />
                            </>
                        )}
                        {hasRights(appState, Rights.MEMBER_CHANGE_MEMBERSHIP) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.membersRequests(appState.memberId)}
                                    title={Translate.message('sidebar.members.requests', 'Žádosti')}
                                    fuzzy
                                    badge={this.state.requests}
                                />
                            </>
                        )}
                    </SidebarLink>
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.accomodation(appState.memberId)}
                        icon={IconBed}
                        title={'Ubytování'}
                    />
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.food(appState.memberId)}
                        icon={IconToolsKitchen2}
                        title={'Strava'}
                        fuzzy
                    >
                        {hasRights(appState, Rights.ADMIN) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.foodAdmin(appState.memberId)}
                                    title={'Administrace'}
                                    fuzzy
                                />
                            </>
                        )}
                    </SidebarLink>
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.bufet(appState.memberId)}
                        icon={IconCoffee}
                        title={'Bufet'}
                        fuzzy
                    >
                        {hasRights(appState, Rights.ADMIN) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.bufetAdmin(appState.memberId)}
                                    title={'Administrace'}
                                    fuzzy
                                />
                            </>
                        )}
                    </SidebarLink>

                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.contact(appState.memberId)}
                        icon={IconPhone}
                        title={'Kontakty'}
                    />
                    {
                        /* TODO: modify roles */
                        // hasRights(appState, Rights.ADMIN) && (
                        //     <SidebarLink
                        //         route={Routes.admin(appState.memberId)}
                        //         title={Translate.message('sidebar.admin.general', 'Administrace')}
                        //         icon={IconBriefcase}
                        //     >
                        //         <SidebarLink
                        //             route={Routes.adminRoles(appState.memberId)}
                        //             title={Translate.message('sidebar.admin.roles', 'Oprávnění')}
                        //         />
                        //         <SidebarLink
                        //             route={Routes.adminOrganizationRoles(appState.memberId)}
                        //             title={Translate.message('sidebar.admin.organizationRoles', 'Role v organizaci')}
                        //         />
                        //     </SidebarLink>
                        // )
                    }
                </ul>
            </nav>
        );
    }
}
