import { AppState } from '../../../../../AppState';
import { Api } from '../../../../../api/Api';
import { Rights } from '../../../../../api/Rights';
import { Event } from '../../../../../api/entities/Event';
import { Member } from '../../../../../api/entities/Member';
import { Translate } from '../../../../../utils/Translate';
import { hasRights } from '../../../../../utils/hasRights';
import { ProfilePreview } from '../../../../utils/ProfilePreview';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { SearchInput } from '../../../../utils/inputs/SearchInput';
import { integer, nonempty, required } from '../../../../utils/inputs/inputValidators/validators';

interface IBasicInfoEditorProps {
    appState: AppState;
    event?: Event;
    onSubmit: (value: { [key: string]: any }) => void;
}

export function BasicInfoEditor(props: IBasicInfoEditorProps) {
    const { event, onSubmit } = props;

    let defaultValues: { [key: string]: any } = {
        title: null,
        annotation: null,
        outcomes: null,
        maxAttendees: null,
        minAttendees: null,
        type: 'discussion',
        requirements: null,
        length: '90 minut',
        price: '0',
        tools: null,
        notes: null,
        leaders: [props.appState.activeMember.id],
    };
    if (event) {
        defaultValues = {
            title: event.title,
            annotation: event.annotation,
            outcomes: event.outcomes,
            leaders: event.leaders.map((leader) => leader.id),
            maxAttendees: event.maxAttendees,
            minAttendees: event.minAttendees,
            type: event.type || 'discussion',
            requirements: event.requirements,
            length: event.length,
            price: event.price + '' || '0',
            tools: event.tools,
            notes: event.notes,
        };
    }

    const disabled = event && event.public && !hasRights(props.appState, Rights.EVENT_MODIFY);

    return (
        <Form
            defaultValues={defaultValues}
            validators={(data) => ({
                title: [required, nonempty],
                annotation: [required, nonempty],
                outcomes: [required, nonempty],
                leaders: [required, nonempty],
                maxAttendees: [integer],
                minAttendees: [integer],
                type: [nonempty],
                requirements: [nonempty],
                length: [required, nonempty],
                price: [],
                tools: [],
                notes: [],
            })}
            onSubmit={(data) => {
                onSubmit({
                    ...data,
                    price: parseInt(data.price) || 0,
                    maxAttendees: parseInt(data.maxAttendees) || null,
                    minAttendees: parseInt(data.minAttendees) || null,
                });
            }}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <div className="mb-5">
                        <FormInput
                            {...inputProps('title', 'd-inline-block')}
                            type="text"
                            label={Translate.message('events.fields.title', 'Název programu')}
                            disabled={disabled}
                        />
                    </div>
                    <div className="mb-5">
                        <h3>{Translate.message('eventEditor.publicInfo', 'Informace pro účastníky')}</h3>
                        <FormInput
                            {...inputProps('type')}
                            type="select"
                            options={[
                                { title: 'Diskuze', value: 'discussion' },
                                { title: 'Exkurze', value: 'excursion' },
                                { title: 'Pohyb', value: 'movement' },
                                { title: 'Seminář', value: 'seminar' },
                                { title: 'Workshop', value: 'workshop' },
                            ]}
                            label={Translate.message('events.fields.type', 'Typ programu')}
                            className="w-100 mt-4"
                            disabled={disabled}
                        />

                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Napiš pár řádků o programu. Něco, co účastníky natáhne a motivuje k přihlášení na tvůj
                                program. Toto je hlavní popis, který se s tvým programem bude zveřejňovat.
                            </div>
                            <FormInput
                                {...inputProps('annotation')}
                                type="textarea"
                                label={Translate.message('events.fields.annotation', 'Anotace programu')}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Co je přínosem tvého programu? Třeba dvě věty o tom, co si účastníci z programu odnesou.
                            </div>
                            <FormInput
                                {...inputProps('outcomes')}
                                type="textarea"
                                label={Translate.message('events.fields.outcomes', 'Přínos programu')}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Budou účastníci za tvůj program něco doplácet? Pokud ano, měl by k tomu být nějaký důvod
                                - typicky cena materiálu (prosíme o popsání v Poznámkách pro organizátory)
                            </div>
                            <FormInput
                                {...inputProps('price')}
                                type="number"
                                label={Translate.message('events.fields.price', 'Cena pro účastníky')}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Potřebují účastníci na tvůj program něco nestandardního? Potřebuješ povinně tužku a
                                papír? Nebo třeba kruh na plavání? Napiš to sem.
                            </div>
                            <FormInput
                                {...inputProps('tools')}
                                type="textarea"
                                label={Translate.message(
                                    'events.fields.tools',
                                    'Pomůcky, které si mají účastníci přinést',
                                )}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="mb-5">
                        <h3>{Translate.message('eventEditor.privateInfo', 'Informace pro organizátory')}</h3>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Standardní programy jsou dlouhé 90, nebo 180 minut. Napiš sem jednu z těchto hodnot, v
                                případě potřeby napiš jinou hodnotu.
                            </div>
                            <FormInput
                                {...inputProps('length')}
                                type="text"
                                label={Translate.message('events.fields.length', 'Délka programu')}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Potřebuješ na program něco dalšího? Počítač? Projektor? Tabuli? Napiš to sem.
                            </div>
                            <FormInput
                                {...inputProps('requirements')}
                                type="textarea"
                                label={Translate.message('events.fields.requirements', 'Požadavky na organizátory')}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Pro kolik maximálně účastníků můžeš program zorganizovat? Jaký je minimální počet, pro
                                který má tvůj program smysl?
                            </div>
                            <div className="columns">
                                <div className="col-12 col-md-6 mt-4 d-inline-block align-top">
                                    <FormInput
                                        {...inputProps('minAttendees')}
                                        type="number"
                                        label={Translate.message(
                                            'events.fields.minAttendees',
                                            'Minimální počet účastníků',
                                        )}
                                        className="input-fill"
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-4 d-inline-block align-top">
                                    <FormInput
                                        {...inputProps('maxAttendees')}
                                        type="number"
                                        label={Translate.message(
                                            'events.fields.maxAttendees',
                                            'Maximální počet účastníků',
                                        )}
                                        className="input-fill"
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div style={{ marginBottom: -12 }}>
                                Nějaké další poznámky a informace pro nás? Například které dny plánuješ dorazit, abychom
                                tvůj program nedali někdy, kdy na Bráně nebudeš.
                            </div>
                            <FormInput
                                {...inputProps('notes')}
                                type="textarea"
                                label={Translate.message('events.fields.notes', 'Poznámky pro organizátory')}
                                className="w-100 mt-4"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="mb-5">
                        <h3>{Translate.message('events.fields.leaders', 'Vedoucí akce')}</h3>
                        <div className="mt-3">
                            <div style={{ marginTop: '-8px', marginBottom: '8px' }}>
                                Pokud vedeš program s dalším člověkem, můžeš ho tady přidat.
                            </div>
                            <SearchInput<Member>
                                type="text"
                                label={Translate.message('events.fields.leaders', 'Vedoucí akce')}
                                appState={props.appState}
                                query={Api.queries.member.search}
                                resultResolver={(data) => data?.auth?.as?.memberById}
                                searchResolver={(data) => data?.auth?.as?.members}
                                itemDisplayJSX={(member) => (
                                    <>
                                        {member.firstname} "{member.nickname}" {member.lastname}
                                    </>
                                )}
                                itemDisplayText={(member) =>
                                    `${member.firstname} "${member.nickname}" ${member.lastname}`
                                }
                                onChange={(value) =>
                                    value &&
                                    inputProps('leaders').onChange!([...((state.leaders?.value as any) || []), value])
                                }
                                className="mb-3"
                                error={inputProps('leaders').error}
                                clearAfterInsert
                                disabled={disabled}
                            />
                            <div>
                                {state.leaders?.value &&
                                    (state.leaders.value as any as number[]).map((id, i) => (
                                        <ProfilePreview
                                            key={i}
                                            appState={props.appState}
                                            id={id}
                                            className="link cross-on-hover"
                                            onClick={() => {
                                                if (!disabled) {
                                                    inputProps('leaders').onChange!(
                                                        (state.leaders?.value as any as number[]).filter(
                                                            (item) => item !== id,
                                                        ),
                                                    );
                                                }
                                            }}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                    <button {...buttonProps}>
                        {props.event
                            ? Translate.message('events.edit.save', 'Uložit')
                            : Translate.message('events.new.create', 'Vytvořit')}
                    </button>
                </>
            )}
        </Form>
    );
}
