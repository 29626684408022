import { ApolloProvider } from '@apollo/client';
import 'moment/locale/cs'; // Required for moment.js to accept cs as a language
import BlotFormatter from 'quill-blot-formatter';
import ImageUploader from 'quill-image-uploader';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Quill } from 'react-quill';
import { BrowserRouter as Router } from 'react-router-dom';
import { Api } from './api/Api';
import App from './components/App';
import './styles/_index.scss';
import { default as cs_CZ_rules } from './translations/cs-CZ-rules';
import cs_CZ from './translations/cs-CZ.json';
import { default as en_US_rules } from './translations/en-US-rules';
import en_US from './translations/en-US.json';
import { Translate } from './utils/Translate';

const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];
const isDev = LOCAL_DOMAINS.includes(window.location.hostname);

// Disable warnings and errors in production application
if (!isDev) {
    console.warn = console.error = () => {};
}

// Initialize CommonJS libraries
require('twix');
require('quill');

// Quill modules
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/blotFormatter', BlotFormatter);

const apiUrl = isDev ? 'http://localhost:4000/graphql' : 'https://api.brana.azduha.cz/graphql';

Api.setupApi(apiUrl);

Translate.importLanguage(cs_CZ, cs_CZ_rules, 'cs-CZ');
Translate.importLanguage(en_US, en_US_rules, 'en-US');
Translate.selectLanguage('cs-CZ');

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={Api.client}>
            <Router>
                <App />
            </Router>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
