type menu = {
    id: number;
    name: string;
    menu: string[];
};

export const food: {
    day: string;
    foods: menu[];
}[] = [
    {
        day: 'Pátek',
        foods: [
            {
                id: 1,
                name: 'Večeře',
                menu: ['Nechci páteční večeři', 'Tortilla s kuřecím masem a zeleninou', 'Tortilla s tofu a zeleninou'],
            },
        ],
    },
    {
        day: 'Sobota',
        foods: [
            {
                id: 2,
                name: 'Snídaně',
                menu: [
                    'Nechci sobotní snídani',
                    'Švédské stoly (šunka, sýr, párek, vejce, zelenina, máslo, pečivo, cereálie, ...)',
                ],
            },
            {
                id: 3,
                name: 'Oběd',
                menu: [
                    'Nechci sobotní oběd',
                    'Kuře na paprice s těstovinami, hráškový krém',
                    'Kuře na paprice s houskovým knedlíkem, hráškový krém',
                    'Vege na paprice s těstovinami, hráškový krém',
                    'Vege na paprice s houskovým knedlíkem, hráškový krém',
                ],
            },
            {
                id: 4,
                name: 'Večeře',
                menu: ['Nechci sobotní večeři', 'Hovězí nudličky Teriyaki s rýží', 'Tofu nudličky Teriyaki s rýží'],
            },
        ],
    },
    {
        day: 'Neděle',
        foods: [
            {
                id: 5,
                name: 'Snídaně',
                menu: [
                    'Nechci nedělní snídani',
                    'Švédské stoly (šunka, sýr, párek, vejce, zelenina, máslo, pečivo, cereálie, ...)',
                ],
            },
            {
                id: 6,
                name: 'Oběd',
                menu: [
                    'Nechci nedělní oběd',
                    'Řízek s pečivem na cestu',
                    'Smažený sýr s pečivem na cestu',
                    'Smažený květák s pečivem na cestu',
                ],
            },
        ],
    },
];
