import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Api } from './api/Api';
import { Member } from './api/entities/Member';

export class AppState {
    public constructor() {
        makeObservable(this);

        (window as any).appState = this;
    }

    @observable
    public authorized: boolean = false;

    @observable
    public members: Member[] = [];
    @observable
    public memberId: number = 0;
    @observable
    public memberDetails: Member | null = null;
    @observable
    public rights: string[] | null = [];

    @observable
    public loading: boolean = false;

    @computed
    public get activeMember() {
        return this.members[this.memberId];
    }

    @action
    public async checkAuthorization() {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const result = await Api.client.query({
                query: Api.queries.memberList,
            });
            if (!result.errors || result.errors.length === 0) {
                this.members = result.data.auth.myMembers;
                this.authorized = true;
            } else {
                this.members = [];
                this.authorized = false;
            }
        } catch {
            this.members = [];
            this.authorized = false;
        }
        runInAction(() => {
            this.loading = false;
        });
    }

    public hasRight(right: string): boolean {
        return !!this.rights && (this.rights.includes(right) || this.rights.includes('admin'));
    }
}
