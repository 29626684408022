import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AppState } from '../../../../AppState';
import { capitalize } from '../../../../utils/capitalize';
import { TWIX_FORMAT_DAYTIME } from '../../../../utils/twixFormat';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { QuillRender } from '../../../utils/QuillRender';
import { Spinner } from '../../../utils/Spinner';

interface ISlideshowPageProps {
    appState: AppState;
}

export function SlideshowPage(props: ISlideshowPageProps) {
    const [item, setItem] = useState<any>(null);

    const { error, data } = useQuery(
        gql`
            {
                auth {
                    as(memberId: ${props.appState.activeMember.id}) {
                        events(pageInput: { skip: 0, take: 10000 }) {
                            items {
                                title
                                from
                                to
                                annotation
                                type
                            }
                        }
                        articles(pageInput:{ skip:0, take: 10000 }, filter: {from: "3000-01-01"}) {
                            items {
                                title
                                content
                            }
                        }
                    }
                }
            }
        `,
        { pollInterval: 10000 },
    );

    useEffect(() => {
        const update = (data: any, item: any) => {
            console.log('Update!');

            if (data) {
                let newItem;
                if (!item || item.__typename === 'Event') {
                    newItem =
                        data.auth.as.articles.items[Math.floor(Math.random() * data.auth.as.articles.items.length)];
                } else {
                    newItem = data.auth.as.events.items[Math.floor(Math.random() * data.auth.as.events.items.length)];
                }

                if (newItem) {
                    setItem(newItem);
                }
            }
        };

        const interval = setTimeout(() => update(data, item), 10 * 1000);
        return () => clearTimeout(interval);
    }, [data, item]);

    if (error) return <ErrorDisplay error={error} />;

    if (item) {
        if (item.__typename === 'Event') {
            return (
                <div
                    style={{ backgroundColor: '#FA6816', color: '#ffffff', position: 'fixed', inset: '0' }}
                    className="d-flex flex-column"
                >
                    <div className="d-flex flex-row">
                        <div className="my-auto" style={{ fontFamily: 'Druha expedice', padding: 100 }}>
                            <img width={300} alt={item.type} src={`/media/icons/${item.type}.svg`} />
                        </div>
                        <div className="my-auto">
                            <div style={{ fontSize: 95, fontFamily: 'Druha expedice' }}>
                                {capitalize(moment(item.from).twix(item.to).format(TWIX_FORMAT_DAYTIME))}
                            </div>
                        </div>
                    </div>
                    <div className="mt-auto" style={{ fontFamily: 'Druha expedice', padding: 100, paddingTop: 0 }}>
                        <div style={{ fontSize: 65, marginBottom: 100 }}>{item.title}</div>
                        <div style={{ fontSize: 32 }}>{item.annotation}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{ backgroundColor: '#ffffff', color: '#FA6816', position: 'fixed', inset: '0' }}
                    className="row"
                >
                    <div className="col-4 my-auto row">
                        <img className="col-7 mx-auto" alt="icon" src="/media/icons/stargate.svg" />
                    </div>
                    <div className="col-8 my-auto" style={{ fontFamily: 'Druha expedice', paddingRight: 100 }}>
                        <div style={{ fontSize: 72 }}>{item.title}</div>
                        <div style={{ fontSize: 32 }}>
                            <QuillRender content={JSON.parse(item.content)} />
                        </div>
                    </div>
                </div>
            );
        }
    }

    return <Spinner />;
}
