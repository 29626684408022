export class Routes {
    // TODO: translations

    // Routes
    public static root = () => '';
    public static auth = () => `${this.root()}/auth`;

    public static login = () => `${this.root()}/login`;
    public static register = () => `${this.root()}/register`;
    public static resend = () => `${this.root()}/resend`;
    public static registerConfirm = () => `${this.root()}/register-confirm`;
    public static passwordReset = () => `${this.root()}/password-reset`;
    public static resetConfirm = () => `${this.root()}/reset-confirm`;
    public static shadowRegister = () => `${this.root()}/shadow-register`;

    public static authAs = (memberId?: number) => `${this.auth()}/${memberId === undefined ? ':memberId' : memberId}`;
    public static home = (memberId?: number) => `${this.authAs(memberId)}`;

    public static members = (memberId?: number) => `${this.authAs(memberId)}/members`;
    public static membersNew = (memberId?: number) => `${this.members(memberId)}/new`;
    public static membersDetail = (memberId?: number, detailId?: number) =>
        `${this.members(memberId)}/detail/${detailId === undefined ? ':detailId' : detailId}`;
    public static membersEdit = (memberId?: number, detailId?: number) =>
        `${this.membersDetail(memberId, detailId)}/edit`;
    public static counselors = (memberId?: number) => `${this.members(memberId)}/public`;
    public static activeMembers = (memberId?: number) => `${this.members(memberId)}/active`;
    public static membersList = (memberId?: number) => `${this.members(memberId)}/all`;
    public static membersRequests = (memberId?: number) => `${this.members(memberId)}/requests`;

    public static events = (memberId?: number) => `${this.authAs(memberId)}/events`;
    public static eventsNew = (memberId?: number) => `${this.events(memberId)}/new`;
    public static eventsDetail = (memberId?: number, detailId?: number) =>
        `${this.events(memberId)}/detail/${detailId === undefined ? ':detailId' : detailId}`;
    public static eventsEdit = (memberId?: number, detailId?: number) =>
        `${this.eventsDetail(memberId, detailId)}/edit`;
    public static eventsList = (memberId?: number) => `${this.events(memberId)}/admin`;
    public static eventsPrivate = (memberId?: number) => `${this.events(memberId)}/private`;
    public static eventsMy = (memberId?: number) => `${this.events(memberId)}/my`;
    public static upcomingEvents = (memberId?: number) => `${this.events(memberId)}/all`;
    public static registeredEvents = (memberId?: number) => `${this.events(memberId)}/registered`;
    public static eventsCalendar = (memberId?: number, year?: number, month?: number) =>
        `${this.events(memberId)}/calendar/${year === undefined ? ':year' : year}/${
            month === undefined ? ':month' : month
        }`;

    public static articles = (memberId?: number) => `${this.authAs(memberId)}/articles`;
    public static articlesList = (memberId?: number) => `${this.articles(memberId)}/all`;
    public static articlesNew = (memberId?: number) => `${this.articles(memberId)}/new`;
    public static articlesDetail = (memberId?: number, detailId?: number) =>
        `${this.articles(memberId)}/detail/${detailId === undefined ? ':detailId' : detailId}`;
    public static articlesEdit = (memberId?: number, detailId?: number) =>
        `${this.articlesDetail(memberId, detailId)}/edit`;

    public static camps = (memberId?: number) => `${this.authAs(memberId)}/camps`;

    public static finances = (memberId?: number) => `${this.authAs(memberId)}/finances`;

    public static profile = (memberId?: number) => `${this.authAs(memberId)}/profile`;
    public static apply = () => `${this.auth()}/application`;

    public static admin = (memberId?: number) => `${this.authAs(memberId)}/admin`;
    public static adminRoles = (memberId?: number) => `${this.admin(memberId)}/roles`;
    public static adminOrganizationRoles = (memberId?: number) => `${this.admin(memberId)}/organization-roles`;

    // Others
    public static backlinkArg = () => `backlink`;
    public static authBacklink = (from: string, to: string) => `${from}?${this.backlinkArg()}=${to}`;

    public static contact = (memberId?: number) => `${this.authAs(memberId)}/contact`;
    public static food = (memberId?: number) => `${this.authAs(memberId)}/food`;
    public static foodPersonal = (memberId?: number) => `${this.food(memberId)}/my`;
    public static foodAdmin = (memberId?: number) => `${this.food(memberId)}/admin`;
    public static accomodation = (memberId?: number) => `${this.authAs(memberId)}/accomodation`;

    public static bufet = (memberId?: number) => `${this.authAs(memberId)}/bufet`;
    public static bufetAdmin = (memberId?: number) => `${this.bufet(memberId)}/admin`;

    public static slideshow = (memberId?: number) => `${this.authAs(memberId)}/slideshow`;
    public static print = (memberId?: number) => `${this.authAs(memberId)}/print`;
}
