import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Rights } from '../../../../api/Rights';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { capitalize } from '../../../../utils/capitalize';
import { classNames } from '../../../../utils/classNames';
import { hasRights } from '../../../../utils/hasRights';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventAttendButtom } from '../../../utils/EventAttendButton';
import { EventAttendees } from '../../../utils/EventAttendees';
import { Spinner } from '../../../utils/Spinner';
import { ArticlesListView } from '../../../utils/dataDisplayComponents/articles/ArticlesListView';
import { Routes } from '../../Routes';

interface IEventsDetailProps {
    appState: AppState;
}

export function EventsDetail(props: IEventsDetailProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const { loading, error, data } = useQuery(Api.queries.event.detailPublic(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const event = data.auth.as.eventById as Event;

    const attending = event.attendees.filter((attendance) => attendance.answer === 'yes');

    return (
        <div className="col-12">
            <div className="image-header">
                <h1>{event.title}</h1>
                <h6>
                    {
                        {
                            discussion: 'Diskuze',
                            excursion: 'Exkurze',
                            movement: 'Pohyb',
                            seminar: 'Seminář',
                            workshop: 'Workshop',
                        }[event.type]
                    }{' '}
                    | {event.from && event.to ? moment(event.from).twix(event.to).format(TWIX_FORMAT) : 'Neuvedeno'}
                    {event.room && event.room.length > 0 && ' | Místnost ' + event.room}
                </h6>
                {hasRights(props.appState, Rights.EVENT_MODIFY) && (
                    <Link to={Routes.eventsEdit(props.appState.memberId, event.id)} className="btn btn-primary mt-2">
                        {Translate.message('eventsDetail.edit', 'Upravit')}
                    </Link>
                )}
                <div className="mt-5">
                    <h2>Anotace</h2>
                    <div>{event.annotation}</div>
                </div>
                <div className="mt-5">
                    <h2>Co si odnesete</h2>
                    <div>{event.outcomes}</div>
                </div>
                {((event.tools && event.tools.length > 0) || (event.price !== null && event.price > 0)) && (
                    <div className="mt-5">
                        <h2>Co potřebujete</h2>
                        {event.price !== null && event.price > 0 && (
                            <div>
                                <strong>Doplatek {event.price} Kč</strong>
                            </div>
                        )}
                        <div>{event.tools}</div>
                    </div>
                )}
                <div className="mt-5">
                    <h2>
                        {Translate.message('eventsDetail.leaders2', '{{numLectors?plural|Lektor|Lektoři|Lektoři}}', {
                            numLectors: event.leaders.length,
                        })}
                    </h2>
                    {event.leaders.map((leader) => (
                        <Link
                            to={Routes.membersDetail(props.appState.memberId, leader.id)}
                            style={{ color: 'inherit', cursor: 'pointer' }}
                        >
                            <div className="d-flex flex-row align-items-start my-4">
                                <img
                                    className={classNames('profile-picture', 'me-4', 'mt-0')}
                                    style={{ width: 80, height: 80, objectFit: 'cover', objectPosition: 'center' }}
                                    src={leader.image}
                                    alt={Translate.message('eventsListView.imageAlt', 'Profilový obrázek {{name}}', {
                                        name: leader.nickname,
                                    })}
                                />
                                <div>
                                    <h3>
                                        {leader.firstname} "{leader.nickname}" {leader.lastname}
                                    </h3>
                                    <div>{leader.motd}</div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                <div>
                    <h2 className="section-heading">{Translate.message('eventsDetail.attendance', 'Přihlašování')}</h2>
                    {capitalize(
                        event.maxAttendees
                            ? Translate.message(
                                  'eventsDetail.capacityLimited',
                                  '{{remaining?plural|zbývá už jen|zbývají už jen|zbývá už jen}} {{remaining}} {{remaining?plural|místo|místa|míst}}',
                                  {
                                      used: attending.length,
                                      remaining: event.maxAttendees - attending.length,
                                      max: event.maxAttendees,
                                  },
                              )
                            : Translate.message(
                                  'eventsDetail.capacityUnlimited',
                                  '{{used}} {{used?plural|člověk|lidi|lidí}} už je přihlášených',
                                  {
                                      used: attending.length,
                                  },
                              ),
                    )}
                    <EventAttendees appState={props.appState} event={event} />
                    <EventAttendButtom appState={props.appState} event={event} />
                </div>
                {event.articles.length > 0 && (
                    <>
                        <h2 className="section-heading">
                            {Translate.message('eventsDetail.articles', 'Lístečky na akci')}
                        </h2>
                        <ArticlesListView
                            query={Api.queries.article.list(props.appState.activeMember.id)}
                            dataResolver={(data) => data.auth.as.articles}
                            appState={props.appState}
                            filter={{
                                from: moment(new Date()).toISOString(),
                                relatedEventId: event.id,
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
