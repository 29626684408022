import { useMutation } from '@apollo/client';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Form } from '../../../utils/inputs/Form';
import { FormInput } from '../../../utils/inputs/FormInput';
import { nonempty, required } from '../../../utils/inputs/inputValidators/validators';
import { IProfileEditorProps } from './ProfileEditor';

export const birchLeafs = [
    '0. úroveň (Žádný lístek)',
    '1. úroveň (Zelený lístek)',
    '2. úroveň (Světle modrý lístek)',
    '3. úroveň (Tmavě modrý lístek)',
    '4. úroveň (Žlutý lístek)',
    '5. úroveň (Červený lístek)',
    '6. úroveň (Karmínovy lístek)',
    '7. úroveň (Bílý lístek)',
    '8. úroveň (Šedý lístek)',
    '9. úroveň (Černý lístek)',
    '10. úroveň (Bronzový lístek)',
    '11. úroveň (Stříbrný lístek)',
    '12. úroveň (Zlatý lístek)',
];

export function CounselorInfoEditor(props: IProfileEditorProps) {
    const { appState, member, refetch } = props;
    const [add, addStatus] = useMutation(Api.mutations.member.counselorInfo.add, {
        onCompleted: () => refetch(),
    });
    const [remove, removeStatus] = useMutation(Api.mutations.member.counselorInfo.remove, {
        onCompleted: () => refetch(),
    });

    const loading = addStatus.loading || removeStatus.loading;
    const error = addStatus.error || removeStatus.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <>
            <h3 className="section-heading mt-5">Další informace</h3>
            {member.counselorInfo.map((info) => (
                <div className="d-flex flex-column flex-md-row w-100 mb-3">
                    <FormInput
                        type="text"
                        label={Translate.message('member.fields.counselorInfo.title', 'Položka')}
                        value={info.title}
                        disabled={true}
                        className="me-md-3 mb-3"
                    />
                    <FormInput
                        type="text"
                        label={Translate.message('member.fields.counselorInfo.content', 'Obsah')}
                        options={birchLeafs.map((title, value) => ({ title, value }))}
                        value={info.content}
                        disabled={true}
                        className="flex-grow-1 me-md-3 mb-3"
                    />
                    <ConfirmButton
                        label={Translate.message('general.remove', 'Odebrat')}
                        onConfirm={() =>
                            remove({
                                variables: {
                                    asId: appState.activeMember.id,
                                    infoId: info.id,
                                },
                            })
                        }
                        className="mb-3 flex-shrink-0"
                    />
                </div>
            ))}
            <Form
                onSubmit={(value) => {
                    add({
                        variables: {
                            asId: appState.activeMember.id,
                            memberId: member.id,
                            value: {
                                title: value.title,
                                content: value.content,
                            },
                        },
                    });
                }}
                validators={(state) => ({
                    title: [nonempty, required],
                    content: [nonempty, required],
                })}
                defaultValues={{}}
            >
                {(inputProps, state, buttonProps) => (
                    <div className="d-flex flex-column flex-md-row w-100 mb-3 align-items-start mt-5">
                        <FormInput
                            {...inputProps('title')}
                            type="text"
                            label={Translate.message('member.fields.counselorInfo.title', 'Položka')}
                            className="me-md-3 mb-3"
                        />
                        <FormInput
                            {...inputProps('content')}
                            type="text"
                            label={Translate.message('member.fields.counselorInfo.content', 'Obsah')}
                            className="flex-grow-1 me-md-3 mb-3"
                        />
                        <button {...buttonProps} className="btn btn-primary mb-3 flex-shrink-0">
                            {Translate.message('general.add', 'Přidat')}
                        </button>
                    </div>
                )}
            </Form>
        </>
    );
}
