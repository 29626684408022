import { useMutation } from '@apollo/client';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons';
import { useState } from 'react';
import { AppState } from '../../AppState';
import { Api } from '../../api/Api';
import { Event } from '../../api/entities/Event';
import { Translate } from '../../utils/Translate';
import { gqlErrorToString } from '../../utils/gqlErrorToString';
import { ConfirmButton } from './ConfirmButton';

interface IEventAttendButtomProps {
    appState: AppState;
    event: Event;
}

export function EventAttendButtom(props: IEventAttendButtomProps): JSX.Element {
    const [attendCall, { loading, error }] = useMutation(Api.mutations.event.attend);
    const [attOverride, setAttOverride] = useState<boolean | null>(null);

    const attend = (answer: boolean) => {
        attendCall({
            variables: {
                memberId: props.appState.activeMember.id,
                eventId: props.event.id,
                answer: answer ? 'yes' : 'no',
            },
        });
        setAttOverride(answer);
    };

    const { event, appState } = props;

    const matching = event.attendees.filter((relation) => relation.member.id === appState.activeMember.id);
    const attendance = attOverride === null ? (matching.length > 0 ? matching[0].answer === 'yes' : null) : attOverride;

    const attending = event.attendees.filter((relation) => relation.answer === 'yes');

    if (!event.registrationOpen)
        return (
            <button className="btn bg-light text-dark flex-shrink-0" disabled>
                {Translate.message('eventAttendButtom.notOpen', 'Přihlašování není otevřené')}
            </button>
        );

    if (loading)
        return (
            <button className="btn bg-primary flex-shrink-0" disabled>
                <span className="spinner-border spinner-border mx-auto" />
            </button>
        );
    if (error)
        return (
            <button className="btn bg-warning flex-shrink-0" disabled>
                <IconAlertTriangle /> {gqlErrorToString(error)}
            </button>
        );

    return attendance ? (
        <span className="d-flex flex-row">
            <button
                className="btn bg-success flex-shrink-0"
                style={{ cursor: 'default', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            >
                <IconCheck style={{ marginLeft: -6, marginRight: 6 }} />
                {Translate.message(
                    'eventsListView.attending',
                    '{{gender?gender|Jsi přihlášený|Jsi přihlášená|Jedeš na akci}}',
                    { gender: appState.activeMember.gender },
                )}
            </button>
            <ConfirmButton
                color="dark"
                label={<IconX />}
                onConfirm={() => attend(false)}
                className="flex-shrink-0"
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
        </span>
    ) : !event.maxAttendees || attending.length < event.maxAttendees ? (
        <ConfirmButton
            color="primary"
            label={Translate.message('eventAttendButtom.attend', 'Přihlásit se')}
            onConfirm={() => attend(true)}
            className="flex-shrink-0"
        />
    ) : (
        <button className="btn bg-light text-dark flex-shrink-0" disabled>
            {Translate.message('eventAttendButtom.full', 'Akce je plná')}
        </button>
    );
}
