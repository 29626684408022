import { useQuery } from '@apollo/client';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { Sidebar } from '../../Sidebar';
import { ErrorBoundary } from '../../utils/ErrorBoundary';
import { ErrorDisplay } from '../../utils/ErrorDisplay';
import { Loading } from '../../utils/Loading';
import { RedirectStoreBacklink } from '../../utils/RedirectStoreBaclink';
import { Routes } from '../Routes';
import { HomePage } from './HomePage';
import { MembershipRequest } from './MembershipRequest';
import { ArticlesPage } from './articles/ArticlesPage';
import { EventsPage } from './events/EventsPage';
import { MembersPage } from './members/MembersPage';
import { AccomodationPage } from './other/AccomodationPage';
import { BufetPage } from './other/BufetPage';
import { ContactPage } from './other/Contact';
import { FoodPage } from './other/FoodPage';
import { PrintPage } from './other/PrintPage';
import { SlideshowPage } from './other/SlideshowPage';
import { ProfilePage } from './profile/ProfilePage';

interface IAuthAsProps {
    appState: AppState;
}

export function AuthAs(props: IAuthAsProps) {
    let { params } = useRouteMatch<{ memberId: string }>();
    const appState = props.appState;

    const memberId = parseInt(params.memberId);
    const validMemberId = !(isNaN(memberId) || !appState.members || !appState.members[memberId]);

    const { loading, error, data } = useQuery(
        Api.queries.authAsDetails(validMemberId ? appState.members[memberId].id : 0),
    );

    if (!validMemberId) {
        return <RedirectStoreBacklink to={Routes.auth()} prefix={Routes.authAs(memberId)} />;
    }

    if (loading) return <Loading />;
    if (error) {
        console.error(error);
        return <ErrorDisplay error={Translate.message('error.unknownError')} />;
    }

    appState.memberDetails = data.auth.as.memberById;
    appState.rights = data.auth.as.rights;
    appState.memberId = memberId;

    if (!appState.memberDetails!.validMembership) {
        return (
            <div className="wrapper center-screen center-screen-wide spot-rh">
                <main>
                    <img className="mb-5 brand" src="/media/logo-dark.png" alt="Logo" />
                    <div className="block text-start p-5">
                        <MembershipRequest appState={appState} />
                    </div>
                </main>
            </div>
        );
    }

    return (
        <Switch>
            <Route path={Routes.slideshow()}>
                <SlideshowPage appState={appState} />
            </Route>
            <Route path={Routes.print()}>
                <PrintPage appState={appState} />
            </Route>
            <Route>
                <div className="wrapper main-page">
                    <Sidebar {...{ appState }} />
                    <main className="spot-lh d-flex flex-column flex-lg-row">
                        <ErrorBoundary>
                            <Switch>
                                <Route path={Routes.events()}>
                                    <EventsPage appState={appState} />
                                </Route>
                                <Route path={Routes.articles()}>
                                    <ArticlesPage appState={appState} />
                                </Route>
                                <Route path={Routes.members()}>
                                    <MembersPage appState={appState} />
                                </Route>
                                <Route path={Routes.admin()}>
                                    <></>
                                </Route>

                                <Route path={Routes.profile()}>
                                    <ProfilePage appState={appState} />
                                </Route>

                                <Route path={Routes.contact()}>
                                    <ContactPage appState={appState} />
                                </Route>

                                <Route path={Routes.food()}>
                                    <FoodPage appState={appState} />
                                </Route>

                                <Route path={Routes.accomodation()}>
                                    <AccomodationPage appState={appState} />
                                </Route>

                                <Route path={Routes.bufet()}>
                                    <BufetPage appState={appState} />
                                </Route>

                                <Route path={Routes.home()} exact={true}>
                                    <HomePage appState={appState} />
                                </Route>

                                <Route>
                                    <Redirect to={Routes.home(memberId)} />
                                </Route>
                            </Switch>
                        </ErrorBoundary>
                    </main>
                </div>
            </Route>
        </Switch>
    );
}
