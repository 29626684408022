import { useQuery } from '@apollo/client';
import moment from 'moment';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { capitalize } from '../../../../utils/capitalize';
import { classNames } from '../../../../utils/classNames';
import { TWIX_FORMAT_DAYTIME } from '../../../../utils/twixFormat';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventHeading } from '../../../utils/EventHeading';
import { ListEndText } from '../../../utils/ListEndText';
import { Spinner } from '../../../utils/Spinner';

interface IEventsUpcomingProps {
    appState: AppState;
}

export function EventsUpcoming(props: IEventsUpcomingProps) {
    const { loading, error, data } = useQuery(Api.queries.event.list(props.appState.activeMember.id), {
        variables: {
            pageInput: {
                skip: 0,
                take: 1000,
            },
            filter: {
                public: true,
            },
            sortOrder: [
                {
                    column: 'from',
                    direction: 'ASC',
                },
            ],
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const events = data.auth.as.events.items as Event[];

    return (
        <div className="col-12">
            <h1>{Translate.message('events.upcoming.title', 'Plánované akce')}</h1>
            <h6>
                {Translate.message(
                    'events.upcoming.subtitle',
                    'Tady můžeš najít všechny důležité informace o plánovaných akcích',
                )}
            </h6>
            <div className="row">
                <div className="col-lg-7 col-12">
                    {events
                        .sort((a, b) => {
                            const from =
                                moment(a.from || '3000-01-01 00:00').unix() -
                                moment(b.from || '3000-01-01 00:00').unix();

                            const to =
                                moment(a.to || '3000-01-01 00:00').unix() - moment(b.to || '3000-01-01 00:00').unix();

                            if (from === 0) {
                                return to;
                            }
                            return from;
                        })
                        .map((event, i, all) => (
                            <>
                                {(i === 0 || all[i - 1].from !== event.from || all[i - 1].to !== event.to) && (
                                    <h3 className="mt-5">
                                        {event.from && event.to
                                            ? capitalize(
                                                  moment(event.from)
                                                      .twix(event.to)
                                                      .format(TWIX_FORMAT_DAYTIME)
                                                      .replaceAll(',', '')
                                                      .replaceAll('  ', ' '),
                                              )
                                            : 'Neuvedeno'}
                                    </h3>
                                )}
                                <div key={i} className={classNames('block')}>
                                    <EventHeading appState={props.appState} event={event} key={i} details={true} />
                                </div>
                            </>
                        ))}

                    <ListEndText>
                        {Translate.message(
                            'events.lidtEnd',
                            'A to je zatím všechno... ale časem sem doplníme další akce!',
                        )}
                    </ListEndText>
                </div>
                <div className="col-lg-5 col-12 ps-lg-5 pt-4">
                    <h3>{Translate.message('home.moreUpcoming')}</h3>
                    {events
                        .sort((a, b) => b.id - a.id)
                        .slice(0, 10)
                        .map((event, i) => (
                            <div key={i} className={classNames('block', 'p-4')}>
                                <EventHeading appState={props.appState} event={event} key={i} minimal />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
