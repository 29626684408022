export const accomodation: {
    name: string;
    description?: string;
    rooms: {
        name: string;
        description?: string;
        id: number;
    }[];
}[] = [
    {
        name: '',
        rooms: [
            {
                name: 'Nechci ubytování',
                id: 0,
            },
        ],
    },
    {
        name: 'VIP ubytování ve vlastním pokoji',
        rooms: [
            {
                name: 'Vlastní pokoj',
                description: 'Doplatek ~620 Kč/noc',
                id: 1,
            },
        ],
    },
    {
        name: 'Gymnázium',
        description: 'Neumannova 1693/2',
        rooms: [
            {
                name: 'Třída Bizon',
                description: 'Kapacita 20 osob',
                id: 2,
            },
            {
                name: 'Třída Bob',
                description: 'Kapacita 20 osob',
                id: 4,
            },
            {
                name: 'Třída Ondra',
                description: 'Kapacita 20 osob',
                id: 5,
            },
            {
                name: 'Třída Margot',
                description: 'Kapacita 20 osob',
                id: 6,
            },
            {
                name: 'Třída Saďour',
                description: 'Kapacita 20 osob',
                id: 7,
            },
            {
                name: 'Třída Lucie',
                description: 'Kapacita 20 osob',
                id: 8,
            },
            {
                name: 'Třída Vendy',
                description: 'Prosíme nechat pro Duhu AZ',
                id: 3,
            },
        ],
    },
];
