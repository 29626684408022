import { useQuery } from '@apollo/client';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { classNames } from '../../../../utils/classNames';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventHeading } from '../../../utils/EventHeading';
import { ListEndText } from '../../../utils/ListEndText';
import { Spinner } from '../../../utils/Spinner';
import { Timetable } from '../../../utils/Timetable';

interface IEventsRegisteredProps {
    appState: AppState;
}

export function EventsRegistered(props: IEventsRegisteredProps) {
    const { loading, error, data } = useQuery(Api.queries.event.list(props.appState.activeMember.id), {
        variables: {
            pageInput: {
                skip: 0,
                take: 100,
            },
            filter: {
                attendeeId: props.appState.activeMember.id,
                public: true,
            },
            sortOrder: [
                {
                    column: 'from',
                    direction: 'ASC',
                },
            ],
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const events = data.auth.as.events.items as Event[];

    console.log(events, props.appState);

    return (
        <div className="col-12">
            <h1>{Translate.message('events.registered.title', 'Přihlášené akce')}</h1>
            <h6>{Translate.message('events.registered.subtitle', 'Tak tohle tě všechno čeká! Těšíš se?')}</h6>
            <div className="row">
                <div className="col-lg-7 col-12">
                    {events.map((event, i) => (
                        <div key={i} className={classNames('block')}>
                            <EventHeading appState={props.appState} event={event} key={i} details={true} />
                        </div>
                    ))}

                    <ListEndText>
                        {Translate.message(
                            'home.noEventsThisWeek',
                            'Tento týden nás nečekají žádné akce. Koukněte se ale na další akce, které se plánují!',
                        )}
                    </ListEndText>
                </div>
                <div className="col-lg-5 col-12 ps-lg-5">
                    <div className={classNames('block')}>
                        <Timetable appState={props.appState} days={3} date={new Date(2023, 8, 22)} />
                    </div>
                </div>
            </div>
        </div>
    );
}
