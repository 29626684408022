import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { Role } from '../../../../api/entities/Role';
import { capitalize } from '../../../../utils/capitalize';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { FormInput } from '../../../utils/inputs/FormInput';
import { Spinner } from '../../../utils/Spinner';
import { IProfileEditorProps } from './ProfileEditor';

export function RoleEditor(props: IProfileEditorProps) {
    const { appState, member, refetch } = props;
    const queryStatus = useQuery(Api.queries.roles(props.appState.activeMember.id));
    const [add, addStatus] = useMutation(Api.mutations.roles.add, {
        onCompleted: () => refetch(),
    });
    const [remove, removeStatus] = useMutation(Api.mutations.roles.remove, {
        onCompleted: () => refetch(),
    });

    const loading = queryStatus.loading || addStatus.loading || removeStatus.loading;
    const error = queryStatus.error || addStatus.error || removeStatus.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const roles = queryStatus.data.auth.as.roles.items as Role[];

    return (
        <>
            {roles.map((role, i) => (
                <div key={i}>
                    <FormInput
                        type="checkbox"
                        label={
                            <div className="ps-1">
                                <h3>{role.title}</h3>
                                {Translate.message('roleEditor.rights', 'Oprávnění:')}
                                <ul>
                                    {role.rights.map((right, i) => (
                                        <li key={i}>
                                            <em>{right}</em> -{' '}
                                            {Translate.message(
                                                `rights.${right
                                                    .split('-')
                                                    .map((word, i) => (i === 0 ? word : capitalize(word)))
                                                    .join('')}`,
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                        value={member.roles.some((r) => r.id === role.id)}
                        onChange={(value) => {
                            const options = {
                                variables: {
                                    asId: appState.activeMember.id,
                                    memberId: member.id,
                                    roleId: role.id,
                                },
                            };
                            value ? add(options) : remove(options);
                        }}
                    />
                </div>
            ))}
        </>
    );
}
